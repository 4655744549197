import { Component, OnInit } from '@angular/core';
import {BackendService} from "../../util/backend.service";
import {AuthService} from "../../auth/auth.service";
import {Employee} from "../../employee/employee";
import {TaskDuration} from "../task.duration";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {

  employees!: Employee[];
  employeeId!: string;
  tasks!: TaskDuration[];
  toDate = new Date();
  fromDate = new Date();
  EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
  constructor(private backendService: BackendService,
              public authService: AuthService) { }

  async ngOnInit() {
    this.employeeId = this.authService.loggedOnUser.id;
    let allEmployees = await this.backendService.get<Employee[]>('employee');
    if(this.authService.hasAuthority('Manager')) {
      this.employees = allEmployees;
    } else {
      this.employees = allEmployees.filter(e => e.id != this.employeeId);
    }
    await this.search();
  }

   async search() {

    await this.backendService.postSilently<TaskDuration[]>(`timesheet/searchTasks`, {
      startDate: this.fromDate.getTime(),
      endDate: this.toDate.getTime(),
      employeeId: this.employeeId
    }).then((tasks: TaskDuration[]) => {
      this.tasks = tasks;
    });
  }

  async exportToExcel() {
    let searchParams = {
      startDate: this.fromDate.getTime(),
      endDate: this.toDate.getTime(),
      employeeId: this.employeeId
    }
    let filename = 'timesheet_' + formatDate(searchParams.startDate, 'dd_MM_yyyy', 'en') + '.xlsx';
    await this.backendService.downloadFilePostExportWithFileName('timesheet/export', searchParams, filename,
      "excel");
  }

}
