export const currencies = [
  {
    symbol: 'M',
    code: 'LSL'
  },
  {
    symbol: 'R',
    code: 'ZAR'
  },
  {
    symbol: '$',
    code: 'USD'
  },
];