import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {BackendService} from '../../util/backend.service';
import {ModalService} from '../../util/modal.service';
import {Tender} from '../tender';
import {take} from 'rxjs/operators';
import {TenderFormComponent} from '../tender-form/tender-form.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {DocumentUploadModalComponent} from '../../document/document-upload-modal.component';
import { saveAs } from 'file-saver';
import {DocumentsComponent} from '../../document/documents.component';
import {CommentComponent} from '../../comment/comment.component';

@Component({
  selector: 'app-tender-view',
  templateUrl: './tender-view.component.html'
})
export class TenderViewComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
              private modalService: BsModalService,
              private backendService: BackendService) {
  }

  tender = {} as Tender;
  @ViewChild('tenderDocuments') tenderDocuments!: DocumentsComponent;
  @ViewChild('tenderComments') tenderComments!: CommentComponent;

  async ngOnInit() {
    const tenderId = this.activatedRoute.snapshot.params.id;
    await this.load(tenderId);
  }

  private async load(tenderId: string) {
    this.tender = await this.backendService.get('tender/' + tenderId);
  }

  async edit(tender: Tender){
    const initialState = { title: 'Edit Tender', tender};
    const modalRef = this.modalService.show(TenderFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load(tender.id);
      });
  }

  uploadQuote() {
    const initialState = {
      path: 'tender/uploadQuotation',    ownerId: this.tender.id, type: 'Tender Quotation'};
    const modalRef = this.modalService.show(DocumentUploadModalComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load(this.tender.id);
        this.tenderDocuments.loadDocuments();
      });
  }

  statusClass(status: string) {
    switch (status) {
      case 'New':
        return 'badge badge-info';
      case 'In Progress':
        return 'badge badge-warning';
      case 'Won':
        return 'badge badge-success';
      case 'Lost':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }

  async won() {
    this.tender = await this.backendService.get('tender/outcome/Won/' + this.tender.id);
  }

  async lost() {
    this.tender = await this.backendService.get('tender/outcome/Lost/' + this.tender.id);
  }
}
