<div *ngFor="let auditLog of auditLogs"
     class="card border-dark mt-3">
  <div class="card-header">
    <b>Changed on: {{auditLog.dateTimeInserted | date: 'dd MMM yyyy HH:mm'}}</b>
  </div>
  <div class="card-body">
    <blockquote class="blockquote mb-0">
      <pre>{{auditLog.change}}</pre>
      <footer class="blockquote-footer">
        Changed by
        <cite title="Source Title">{{auditLog.user}}</cite><br/>
      </footer>
    </blockquote>
  </div>
</div>