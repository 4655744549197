import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Room} from '../room';

@Component({
  selector: 'app-room-form',
  templateUrl: './room-form.component.html'
})
export class RoomFormComponent implements OnInit {
  public room!: Room;
  public title!: string;

  constructor(private bsModalRef: BsModalRef,
              private backendService: BackendService) {
  }

  ngOnInit(): void {
  }

  save() {
    if (this.room.id) {
      this.backendService.putSilently('room', this.room).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.backendService.postSilently('room', this.room).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  close(){
    this.bsModalRef.hide();
  }
}
