<section class="container-fluid content-area">

  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fas fa-book"></i> Suppliers
        </div>
        <div class="card-body">
          <div class="row">
            <td class="col-sm-12">

              <button type="button" class="btn btn-primary btn-sm mb-1" (click)="add()"
                      *ngIf="authService.hasAuthority('Supplier Administrator')">
                Add
              </button>

              <table class="table table-striped table-bordered" style="margin-top: 10px">
                <thead>
                <tr>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Contact</th>
                  <th>Product </th>
                  <th>PO Accepted</th>
                  <th>Emails Accepted</th>
                  <th>Created By</th>
                  <th>Modified By</th>
                  <th>Approved By</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody *ngIf="suppliers">
                <tr *ngFor="let supplier of suppliers">
                  <td>{{supplier.code}}</td>
                  <td>
                    <div class="clearfix">
                      <div class="float-left">
                        <strong>{{supplier.name}}</strong>
                      </div>
                      <div class="float-right">
                        <small class="text-muted">{{supplier.telephone}}</small>
                      </div>
                    </div>
                    <div class="text-sm-left">{{supplier.description}}</div>
                  </td>
                  <td>
                    <div class="clearfix">
                      <div class="float-left">
                        <strong>{{supplier.contactPerson}}</strong>
                      </div>
                      <div class="float-right">
                        <small class="text-muted">{{supplier.email}}</small>
                      </div>
                    </div>
                    <div class="text-sm-left">{{supplier.address}}</div>
                  </td>
                  <td>{{supplier.productType}}</td>
                  <td>{{supplier.acceptsPurchaseOrders}}</td>
                  <td>{{supplier.acceptsEmails}}</td>
                  <td>{{supplier.creator}} on {{supplier.dateTimeInserted | date : 'dd MMM yyyy HH:mm'}}</td>
                  <td>{{supplier.editor}} on {{supplier.dateTimeEdited | date : 'dd MMM yyyy HH:mm'}}</td>
                  <td>{{supplier.statusBy}} on {{supplier.dateTimeStatus | date : 'dd MMM yyyy HH:mm'}}</td>
                  <td>
                    <span [class]="statusClass(supplier.status)">{{supplier.status}}</span>
                    <p>{{supplier.statusComment}}</p>
                  </td>
                  <td>
                    <button type="button" class="btn btn-link" title="Edit" (click)="edit(supplier)">
                      <span class="fa fa-edit" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link text-success" title="Approve"
                            *ngIf="authService.hasAuthority('Supplier Manager') && supplier.status !== 'Approved'"
                            (click)="approve(supplier)">
                      <span class="fa fa-thumbs-up" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link text-danger" title="Decline"
                            *ngIf="authService.hasAuthority('Supplier Manager') && supplier.status !== 'Declined'"
                            (click)="decline(supplier)">
                      <span class="fa fa-thumbs-down" aria-hidden="true"></span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
