<section class="container-fluid content-area">

  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fas fa-book"></i> Type of work
        </div>
        <div class="card-body">
          <div class="row">
            <td class="col-sm-12">

              <button type="button" class="btn btn-primary btn-sm mb-1" (click)="add()"
                      *ngIf="authService.hasAuthority('Stock Administrator')">
                Add
              </button>

              <table class="table table-striped table-bordered" style="margin-top: 10px">
                <thead>
                <tr>
                  <th>Color</th>
                  <th>Code</th>
                  <th>Client</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Created By</th>
                  <th>Modified By</th>
                  <th>Approved By</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody *ngIf="projects">
                <tr *ngFor="let project of projects">
                  <td style="background-color:{{project.color}}">{{project.color}}</td>
                  <td>{{project.code}}</td>
                  <td>{{project.clientName}}</td>
                  <td>
                    <div class="clearfix">
                      <div class="float-left">
                        <strong>{{project.name}}</strong>
                      </div>
                    </div>
                  </td>
                  <td>{{project.description}}</td>
                  <td>{{project.creator}} on {{project.dateTimeInserted | date : 'dd MMM yyyy HH:mm'}}</td>
                  <td>{{project.editor}} on {{project.dateTimeEdited | date : 'dd MMM yyyy HH:mm'}}</td>
                  <td>{{project.statusBy}} on {{project.dateTimeStatus | date : 'dd MMM yyyy HH:mm'}}</td>
                  <td>
                    <span [class]="statusClass(project.status)">{{project.status}}</span>
                    <p>{{project.statusComment}}</p>
                  </td>
                  <td>
                    <button type="button" class="btn btn-link" title="Edit" (click)="edit(project)">
                      <span class="fa fa-edit" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link text-success" title="Approve"
                            *ngIf="authService.hasAuthority('Stock Manager') && project.status !== 'Approved'"
                            (click)="approve(project)">
                      <span class="fa fa-thumbs-up" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link text-danger" title="Decline"
                            *ngIf="authService.hasAuthority('Stock Manager') && project.status !== 'Declined'"
                            (click)="decline(project)">
                      <span class="fa fa-thumbs-down" aria-hidden="true"></span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
