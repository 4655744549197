<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body bg-light">

  <form #itemForm="ngForm" (ngSubmit)="save()">
    <div class="form-group row">
      <div class="col-4">
        <label>Code</label>
      </div>
      <div class="col-6">
        <input name="code"
               [disabled]="purchaseOrder.status === 'Invoiced'"
               [(ngModel)]="purchaseOrderItem.code" stretch="true" required>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">
        <label>Description</label>
      </div>
      <div class="col-6">
        <input name="description"
               [disabled]="purchaseOrder.status === 'Invoiced'"
               [(ngModel)]="purchaseOrderItem.description" stretch="true" required>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">
        <label>Quantity</label>
      </div>
      <div class="col-6">
        <input name="quantity"
               [disabled]="purchaseOrder.status === 'Invoiced'"
               [(ngModel)]="purchaseOrderItem.quantity" type="number" stretch="true" required>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">
        <label>Unit Price</label>
      </div>
      <div class="col-6">
        <input name="unitPrice"
               [disabled]="purchaseOrder.status === 'Invoiced'"
               [(ngModel)]="purchaseOrderItem.unitPrice" type="number" stretch="true" required>
      </div>
    </div>
    <div class="form-group row"
         *ngIf="purchaseOrder.status === 'Invoiced' && authService.hasAuthority('Purchase Order Manager')">
      <div class="col-4">
        <label>Invoiced Amount</label>
      </div>
      <div class="col-6">
        <input name="actualPrice"
               [(ngModel)]="purchaseOrderItem.actualPrice" type="number" stretch="true" required>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!itemForm.valid">
        Save
      </button>

      <button type="button" (click)="close()" class="btn btn-secondary left mr-1">
        Cancel
      </button>
    </div>
  </form>
</div>