<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body  bg-light">

  <form #siteForm="ngForm" (ngSubmit)="save()">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Number</label>
      </div>
      <div class="form-group col-sm-6">
        <input name="code" [(ngModel)]="employee.number" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Name</label>
      </div>
      <div class="form-group col-sm-6">
        <input name="name" [(ngModel)]="employee.name" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Firstname</label>
      </div>
      <div class="form-group col-sm-6">
        <input name="firstname" [(ngModel)]="employee.firstname" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Lastname</label>
      </div>
      <div class="form-group col-sm-6">
        <input name="lastname" [(ngModel)]="employee.lastname" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Birthdate</label>
      </div>
      <div class="form-group col-sm-6">
        <div class="input-group">
          <input class="form-control"
                 [(ngModel)]="employeeBithdate" bsDatepicker
                 type="text"
                 name="bsDatepicker"
                 #dp="bsDatepicker"
                 [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"/>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Email</label>
      </div>
      <div class="form-group col-sm-6">
        <input type="email" name="flourArea" [(ngModel)]="employee.email"
                        stretch="true">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Cell</label>
      </div>
      <div class="form-group col-sm-6">
        <input type="number" name="feederLength" [(ngModel)]="employee.cellnumber"
                        stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Authorities</label>
      </div>
      <div class="form-group col-sm-6">
        <div *ngFor="let authority of authorities">
          <label>
            <input type="checkbox"
                   [name]="authority"
                   [value]="authority"  [checked]="isA(authority)"
                   (change)="changeAuthority($event,authority)" required/> {{authority}}
          </label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!siteForm.valid" >
        Save
      </button>

      <button type="button" (click)="close()" class="btn btn-secondary left mr-1">
        Cancel
      </button>
    </div>
  </form>
</div>