<section class="container-fluid content-area">

  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fas fa-users"></i> Users
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">

              <button type="button" class="btn btn-primary btn-sm mb-1" (click)="add()">
                Add
              </button>

              <table class="table table-striped table-bordered" style="margin-top: 10px">
                <thead>
                <tr>
                  <th>Number</th>
                  <th>Name</th>
                  <th>Birthdate</th>
                  <th>Email</th>
                  <th>Cell</th>
                  <th>Authorities</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody *ngIf="employees">
                <tr *ngFor="let employee of employees">
                  <td>{{employee.number}}</td>
                  <td>{{employee.name}}</td>
                  <td>{{employee.birthdate | date:'dd MMM yyyy'}}</td>
                  <td>{{employee.email}}</td>
                  <td>{{employee.cellnumber}}</td>
                  <td>{{employee.authorities}}</td>
                  <td>
                    <button type="button" class="btn btn-link" title="End User Session" (click)="edit(employee)">
                      <span class="fa fa-edit" aria-hidden="true"></span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
