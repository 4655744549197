import {ChangeDetectorRef, Injectable, Type} from '@angular/core';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { MessageModalComponent } from './message-modal/message-modal.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {combineLatest, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {CommentModalComponent} from './comment-modal/comment-modal.component';

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(private modalService: BsModalService) {}


  async confirm(message: string, callback: (confirmed: boolean) => any){
    const initialState = {
      message,
      confirmed: false
    };
    const messageModal = this.modalService.show(ConfirmModalComponent, {initialState});
    this.modalService.onHide
      .pipe(take(1))
      .subscribe(() => {
        if (messageModal.content) {
          callback(messageModal.content.confirmed);
        } else {
          callback(false);
        }
      });
  }

  async comment(title: string, message: string, commentRequired: boolean, callback: (comment: string, commented: boolean) => any){
    const initialState = {
      title,
      promtMessage: message,
      commentRequired,
      commented: false,
      comment: null
    };
    const messageModal = this.modalService.show(CommentModalComponent, {initialState});
    this.modalService.onHide
      .pipe(take(1))
      .subscribe(() => {
        if (messageModal.content) {
          callback(messageModal.content.comment, messageModal.content.commented);
        } else {
          callback('', false);
        }
      });
  }

  errorMessage(message: string) {
    const initialState = {
      errorMessages: [message],
    };
    const messageModal = this.modalService.show(MessageModalComponent, {initialState});
  }

  error(e: Error) {
    const initialState = {
      error: e,
    };
    const messageModal = this.modalService.show(MessageModalComponent, {initialState});
  }

  info(message: string) {
    const initialState = {
      infoMessage: message,
    };
    const messageModal = this.modalService.show(MessageModalComponent, {initialState});
  }

  success(message: string) {
    const initialState = {
      successMessage: message,
    };
    const messageModal = this.modalService.show(MessageModalComponent, {initialState});
  }
}
