<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body bg-light">

  <form #siteForm="ngForm" (ngSubmit)="save()">
    <div class="form-group row">
      <div class="col-3">
        <label>Code</label>
      </div>
      <div class="col-6">
        <input class="form-control" name="code"
               [(ngModel)]="supplier.code" [disabled]="supplier.id"
               stretch="true"
               pattern="[a-zA-Z0-9]*"
               minlength="2"
               maxlength="4"
               required>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-3">
        <label>Name</label>
      </div>
      <div class="col-6">
        <input  class="form-control" name="name" [(ngModel)]="supplier.name" type="tel" stretch="true" required>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-3">
        <label>Address</label>
      </div>
      <div class="col-6">
        <input  class="form-control" name="address" [(ngModel)]="supplier.address" stretch="true" required>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-3">
        <label>Contact Person</label>
      </div>
      <div class="col-6">
        <input  class="form-control" name="contactPerson" [(ngModel)]="supplier.contactPerson" stretch="true" required>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-3">
        <label>Email</label>
      </div>
      <div class="col-6">
        <input  class="form-control" name="email" type="email" [(ngModel)]="supplier.email" stretch="true" required>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-3">
        <label>Telephone</label>
      </div>
      <div class="col-6">
        <input  class="form-control" name="telephone" type="tel" [(ngModel)]="supplier.telephone" stretch="true" required>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-3">
        <label>Product Type</label>
      </div>
      <div class="col-6">
        <select class="form-control" name="productType" [(ngModel)]="supplier.productType" required>
          <option value="Electronic">Electronic</option>
          <option value="Fuel">Fuel</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-3">
        <label>Accepts PO</label>
      </div>
      <div class="col-6">
        <input type="checkbox" class="form-control" name="acceptsPurchaseOrder"
               [checked]="supplier.acceptsPurchaseOrders"
              [(ngModel)]="supplier.acceptsPurchaseOrders" >

      </div>
    </div>
    <div class="form-group row">
      <div class="col-3">
        <label>Accepts Emails</label>
      </div>
      <div class="col-6">
        <input type="checkbox" class="form-control" name="acceptsPurchaseOrder"
               [checked]="supplier.acceptsEmails"
              [(ngModel)]="supplier.acceptsEmails" >

      </div>
    </div>
    <div class="form-group row">
      <div class="col-3">
        <label>Description</label>
      </div>
      <div class="col-9">
        <textarea  class="form-control" name="description" [(ngModel)]="supplier.description" required></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!siteForm.valid" >
        Save
      </button>

      <button type="button" (click)="close()" class="btn btn-secondary left mr-1">
        Cancel
      </button>
    </div>
  </form>
</div>