import {Component, OnInit} from '@angular/core';
import {Employee} from "../../employee/employee";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {BackendService} from "../../util/backend.service";
import {AuthService} from "../../auth/auth.service";
import {Order} from "../order";

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss']
})
export class OrderFormComponent implements OnInit {

  public order!: Order;
  public title!: string;
  employees!: Employee[];
  dateIssued = new Date();
  dateReceived = new Date();
  departments = [
    'Administration',
    'Workshop',
    'Software',
    'Communications',
    'Sales'];

  constructor(private bsModalRef: BsModalRef,
              private modalService: BsModalService,
              private backendService: BackendService,
              public  authService: AuthService) {
  }

  async ngOnInit() {
    this.departments.sort();
    this.employees = await this.backendService.get('employee');
    if (this.order.dateIssued) {
      this.dateIssued = new Date(this.order.dateIssued);
    }
    if (this.order.dateReceived) {
      this.dateReceived = new Date(this.order.dateReceived);
    }
  }

  save() {
    this.order.dateIssued = this.dateIssued.getTime();
    this.order.dateReceived = this.dateReceived.getTime();
    if (this.order.id) {
      this.backendService.putSilently('order', this.order).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.backendService.postSilently('order', this.order).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  close() {
    this.bsModalRef.hide();
  }
}
