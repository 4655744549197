import {Component} from '@angular/core';

@Component({
  templateUrl: './privacy.component.html',
})
export class PrivacyComponent {

  constructor() {
  }

}
