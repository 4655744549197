import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BsModalService} from "ngx-bootstrap/modal";
import {ModalService} from "../../util/modal.service";
import {AuthService} from "../../auth/auth.service";
import {BackendService} from "../../util/backend.service";
import {DocumentsComponent} from "../../document/documents.component";
import {AuditComponent} from "../../audit/audit.component";
import {take} from "rxjs/operators";
import {DocumentUploadModalComponent} from "../../document/document-upload-modal.component";
import {OrderFormComponent} from "../order-form/order-form.component";
import {Order} from "../order";

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
              private modalService: BsModalService,
              private cooModalService: ModalService,
              public authService: AuthService,
              private backendService: BackendService) {
  }

  order = {} as Order;
  @ViewChild('orderDocuments') orderDocuments!: DocumentsComponent;
  @ViewChild('orderAuditLogs') orderAuditLogs!: AuditComponent;

  async ngOnInit() {
    const orderId = this.activatedRoute.snapshot.params.id;
    await this.load(orderId);
  }

  private async load(orderId: string) {
    this.order = await this.backendService.get('order/' + orderId);
    await this.orderAuditLogs.loadAudits();
    await this.orderDocuments.loadDocuments();
  }

  async edit(order: Order) {
    const initialState = {title: 'Edit  Order', order: order};
    const modalRef = this.modalService.show(OrderFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load(order.id);
        this.orderAuditLogs.loadAudits();
      });
  }

  upload(documentType: string) {
    const initialState = {
      path: 'order/upload', ownerId: this.order.id, type: documentType
    };
    const modalRef = this.modalService.show(DocumentUploadModalComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load(this.order.id);
        this.orderDocuments.loadDocuments();
        this.orderAuditLogs.loadAudits();
      });
  }

  statusClass(status: string) {
    switch (status) {
      case 'New':
        return 'badge badge-info';
      case 'In Progress':
        return 'badge badge-warning';
      case 'Approved':
      case 'Invoice Paid':
        return 'badge badge-success';
      case 'Cancelled':
      case 'Invoice Confirmed':
      case 'Invoiced':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }

  async submitForApproval(order: Order) {
    await this.cooModalService
      .confirm(
        `Confirm that you are submitting ${order.poNumber} order for approval.`, 
        (confirmed: boolean) => {
          if (confirmed) {
            order.status = 'Awaiting Accounts Approval';
            this.backendService.postSilently('order/status', order)
              .then((res: any) => {
                  this.load(this.order.id);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
  }

  async approve(order: Order, approvalStatus = 'Approved') {
    await this.cooModalService
      .comment('Approval Order', `Confirm that you are approving ${order.poNumber} order.`, false,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            order.statusComment = comment;
            order.status = approvalStatus;
            this.backendService.postSilently('order/status', order)
              .then((res: any) => {
                  this.load(this.order.id);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
  }

  async decline(order: Order) {
    await this.cooModalService
      .comment('Decline  Order', `Confirm that you are declining ${order.poNumber} order and add reason.`, true,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            order.statusComment = comment;
            order.status = 'Declined';
            this.backendService.postSilently('order/status', order)
              .then((res: any) => {
                  this.load(this.order.id);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
  }

  async cancel(order:Order) {
    await this.cooModalService
      .comment('Cancel Order', `Confirm that you are cancelling ${order.poNumber} order and add reason.`, true,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            order.statusComment = comment;
            order.status = 'Cancelled';
            this.backendService.postSilently('order/status', order)
              .then((res: any) => {
                  this.load(this.order.id);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
  }

  async confirmComplete(order: Order) {
    await this.cooModalService
      .comment('Confirm Order Complete', `Confirm that ${order.poNumber} order is completed.`, false,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            order.statusComment = comment;
            order.status = 'Complete';
            this.backendService.postSilently('order/status', order)
              .then((res: any) => {
                  this.load(this.order.id);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
  }

  async confirmPayment(order: Order) {
    await this.cooModalService
      .confirm(`Confirm that ${order.poNumber} order is paid.`,
        (confirmed: boolean) => {
          if (confirmed) {
            order.paid = true;
            this.backendService.put('order', order)
              .then((res: any) => {
                  this.load(this.order.id);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
  }

  isAccountantOrManager() {
    return this.authService.hasAuthority('Accountant') || this.authService.hasAuthority('Manager') ;
  }

  canBeApprovedByManager(order: Order) {
    return order.status === 'Awaiting Manager Approval';
  }
  
  canBeApprovedByAccounts(order: Order) {
    return order.status === 'Awaiting Approval' || order.status === 'Declined';
  }
}
