import {Component, Input, OnInit} from '@angular/core';
import {BackendService} from '../util/backend.service';
import {Comment} from './comment';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html'
})
export class CommentComponent implements OnInit {

  @Input() entityId!: string;
  comments!: Comment[];
  comment!: string;

  constructor(private backendService: BackendService) {
  }

  async ngOnInit() {
    await this.loadComments();
  }

  async loadComments() {
    this.comments = await this.backendService.get('comment/' + this.entityId);
  }
  async saveComment(){
    const comment = {entityId: this.entityId, comment: this.comment} as Comment;
    await this.backendService.post('comment', comment);
    this.loadComments();
  }
}
