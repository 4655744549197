import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {ActivatedRoute, Router} from "@angular/router";
import {Quotation} from "../quotation";
import {QuotationItem} from "../quotation-item";
import {PurchaseOrderItem} from "../../purchase-order/purchase-order-item";
import {PurchaseOrderItemFormComponent} from "../../purchase-order/purchase-order-item-form/purchase-order-item-form.component";
import {take} from "rxjs/operators";
import {BsModalService} from "ngx-bootstrap/modal";
import {QuotationItemFormComponent} from "../quotation-item-form/quotation-item-form.component";

@Component({
  selector: 'app-quotation-form',
  templateUrl: './quotation-form.component.html'
})
export class QuotationFormComponent implements OnInit {
  quotation = {items: []} as unknown as Quotation;
  errorMessage!: string;
  quotationDate = new Date();
  title!: string;

  constructor(private backendService: BackendService,
              private activatedRoute: ActivatedRoute,
              private modalService: BsModalService,
              private router: Router) {
  }

  async ngOnInit() {
    const quotationId = this.activatedRoute.snapshot.params.id;
    this.title = 'Create Quotation';
    if (quotationId) {
      this.quotation = await this.backendService.get(`quotation/${quotationId}`);
      this.title = `Edit Quotation ${this.quotation.number}`;
      this.quotationDate = new Date(this.quotation.date);
    }
  }

  private calculateTotals() {
    this.quotation.totalExcludingVat = 0;
    if(this.quotation.items) {
      this.quotation.items.forEach( item =>  {
        this.quotation.totalExcludingVat += item.unitPrice * item.quantity;
      });
      this.quotation.totalVat = 0.15 * this.quotation.totalExcludingVat; 
      this.quotation.totalIncludingVat = this.quotation.totalVat + this.quotation.totalExcludingVat; 
    }
  }
  
  save() {
    this.quotation.date = this.quotationDate.getTime();
    if(this.quotation.id) {
      this.backendService.put('quotation', this.quotation).then(successResult => {
        this.router.navigate(['quotations']);
      }, error => {
        this.errorMessage = error;
      });      
    }else {
      this.backendService.post('quotation', this.quotation).then(successResult => {
        this.router.navigate(['quotations']);
      }, error => {
        this.errorMessage = error;
      });
    }
  }

  statusClass(status: string) {
    switch (status) {
      case 'Dispatched':
        return 'badge badge-warning';
      case 'Delivered':
        return 'badge badge-success';
      case 'Accepted':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }

  async addItem() {
    const initialState = {
      title: 'Add Item',
      item: {} as QuotationItem,
      saveItem: false,
      quotation: this.quotation
    };
    this.displayItemForm(initialState);
  }

  private displayItemForm(initialState: { saveItem: boolean; item: QuotationItem; title: string; quotation: Quotation }) {
    const modalRef = this.modalService.show(QuotationItemFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(async () => {
        if (modalRef.content.saveItem) {
          if (this.quotation.id) {
            this.quotation = await this.backendService.get(`quotation/${this.quotation.id}`);
          } else {
            this.quotation.items.push(initialState.item);
            this.calculateTotals();
          }
        }
      });
  }

  async remove(item: QuotationItem) {
    if(this.quotation.id){
     this.quotation = await this.backendService.deleteSilently(`quotationItem/${item.id}`); 
    } else {
      this.quotation.items = this.quotation.items.filter(s => s !== item);
      this.calculateTotals();
    }
  }
  
  belowThreshold(markup: number) {
    if (markup < 0.25) {
      return 'text-danger';
    }
    return  'text-success';
  }

  edit(item: QuotationItem) {
    const initialState = {
      title: 'Edit Item',
      item: item,
      saveItem: false,
      quotation: this.quotation
    };
    this.displayItemForm(initialState);
    
  }
}
