import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {Quotation} from "../quotation";

@Component({
  selector: 'app-quotation-list',
  templateUrl: './quotation-list.component.html'
})
export class QuotationListComponent implements OnInit {

  quotations!: Quotation[];
  toDate = new Date();
  fromDate = new Date();
  status = '';
  constructor(private backendService: BackendService) { }

  async ngOnInit() {
    const today = new Date();
    const yearAgo = new Date();
    yearAgo.setDate(today.getDate() - 365);
    this.fromDate = yearAgo;
    this.toDate = today;
    await this.load();
  }

  async load() {
    this.quotations = await this.backendService.postSilently<Quotation[]>('quotation/search/',
      {
        startDate: this.fromDate.getTime(),
        endDate: this.toDate.getTime(),
        status: this.status
      });
  }

  statusClass(status: string) {
    switch (status) {
      case 'Draft':
      case 'Awaiting Approval':
      case 'Approved':
        return 'badge badge-warning';
      case 'Accepted':
        return 'badge badge-success';
      case 'Declined':
      case 'Expired':
      case 'Rejected':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }

  async generate(id: string) {
    await this.backendService.get(`quotation/generate/${id}`);
    await this.load();
  }

  async download(quotation: Quotation) {
    await this.backendService.downloadAndSave(`document/${quotation.reportId}`, 
      `Quotation_${quotation.number}.pdf` );
  }
}
