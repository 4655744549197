import {Component, OnInit} from '@angular/core';
import {Vehicle} from '../vehicle';
import {BackendService} from '../../util/backend.service';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html'
})
export class VehicleFormComponent implements OnInit {
  public vehicle!: Vehicle;
  vehicleDiscExpiryDate: any;
  vehicleLastServiceDate: any;
  public title!: string;
  defaultDate = new Date();
  authorities = ['User Administrator',
    'Technician', 'Fleet Administrator',
    'Tender Administrator', 'Tender Manager',
    'Waybill Administrator', 'Waybill Manager',
    'Purchase Order Administrator', 'Purchase Order Manager',
    'Stock Administrator', 'Stock Manager',
    'Supplier Administrator', 'Supplier Manager']

  constructor(private bsModalRef: BsModalRef,
              private backendService: BackendService) {
  }

  ngOnInit(): void {
    this.defaultDate.setFullYear(this.defaultDate.getFullYear() - 1);
    if (this.vehicle.discExpiryDate) {
      this.vehicleDiscExpiryDate = new Date(this.vehicle.discExpiryDate);
      this.vehicleLastServiceDate = new Date(this.vehicle.lastServiceDate);
    } else {
      this.vehicleLastServiceDate = this.defaultDate;
      this.vehicleDiscExpiryDate = new Date();
    }
  }

  save() {
    this.vehicle.discExpiryDate = this.vehicleDiscExpiryDate.getTime();
    this.vehicle.lastServiceDate = this.vehicleLastServiceDate.getTime();
    if (this.vehicle.id) {
      this.backendService.putSilently('vehicle', this.vehicle).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.backendService.postSilently('vehicle', this.vehicle).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  close(){
    this.bsModalRef.hide();
  }
}
