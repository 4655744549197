<section class="container-fluid content-area">

  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fas fa-users"></i> Delivery Note
        </div>
        <div class="card-body">
          <div class="row"><div class="col-3 form-group my-auto">
            <label for="fromDate" class="bs-timepicker-label">From:</label>
            <div class="input-group">
              <input type="text" id="fromDate" class="input-group-text"
                     placeholder="From Date" #fromDateDp="bsDatepicker"
                     [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                     [(ngModel)]="fromDate"
                     [maxDate]="toDate"
                     bsDatepicker>

              <button class="btn btn-outline-primary input-group-append"
                      (click)="fromDateDp.toggle()" [attr.aria-expanded]="fromDateDp.isOpen">
                <span class="fa fa-calendar" aria-hidden="true"></span>
              </button>
            </div>
          </div>
            <div class="col-3 form-group my-auto">
              <label for="toDate" class="bs-timepicker-label">To:</label>
              <div class="input-group">
                <input type="text" id="toDate" class="input-group-text"
                       placeholder="To Date" #toDateDp="bsDatepicker"
                       [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                       [(ngModel)]="toDate"
                       [minDate]="fromDate"
                       bsDatepicker>

                <button class="btn btn-outline-primary input-group-append"
                        (click)="toDateDp.toggle()" [attr.aria-expanded]="toDateDp.isOpen">
                  <span class="fa fa-calendar" aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <div class="2 form-group my-auto">
              <label for="status">Status</label>
              <select class="form-control" name="status" id="status" [(ngModel)]="status">
                <option value="">All</option>
                <option value="Dispatched">Dispatched</option>
                <option value="Accepted">Accepted</option>
                <option value="Delivered">Delivered</option>
              </select>
            </div>

            <div class="col-2 my-auto btn-group">

              <button type="button" class="btn btn-primary btn-sm mr-1" (click)="load()">
                Search
              </button>
              <button type="button" class="btn btn-primary btn-sm" routerLink="/delivery-note/form">
                Dispatch Stock
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table class="table table-striped table-bordered" style="margin-top: 10px">
                <thead>
                <tr>
                  <th>Number</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Technician</th>
                  <th>Call Number</th>
                  <th>Invoice Number</th>
                  <th>Company</th>
                  <th>Contact Person</th>
                  <th>Contact Number</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody *ngIf="deliveryNotes">
                <tr *ngFor="let note of deliveryNotes">
                  <td>{{note.number}}</td>
                  <td>{{note.type}}</td>
                  <td>{{note.date  | date:'dd MMM yyyy'}}</td>
                  <td>{{note.technician}}</td>
                  <td>{{note.callTicketNumber}}</td>
                  <td>{{note.invoiceNumber}}</td>
                  <td>{{note.company}}</td>
                  <td>{{note.contactPerson}}</td>
                  <td>{{note.contactNumber}}</td>
                  <td><span [class]="statusClass(note.status)">{{note.status}}</span></td>
                  <td>
                    <button type="button" class="btn btn-link" title="View" routerLink="/delivery-note/form/{{note.id}}">
                      <span class="fa fa-eye" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link" title="Generate" (click)="generate(note.id)">
                      <span class="fas fa-cogs" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link" title="Download" *ngIf="note.reportId" (click)="download(note)">
                      <span class="fas fa-download" aria-hidden="true"></span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
