import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AuthService} from '../../auth/auth.service';
import {QuotationItem} from "../quotation-item";
import {Quotation} from "../quotation";
import {Supplier} from "../../supplier/supplier";
import {SupplierFormComponent} from "../../supplier/supplier-form/supplier-form.component";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-quotation-item-form',
  templateUrl: './quotation-item-form.component.html'
})
export class QuotationItemFormComponent implements OnInit {
  public item!: QuotationItem;
  public quotation!: Quotation;
  suppliers!: Supplier[];
  public title!: string;
  public saveItem!: boolean;

  constructor(private bsModalRef: BsModalRef,
              private modalService: BsModalService,
              public authService: AuthService,
              private backendService: BackendService) {
  }

  async ngOnInit() {
    this.loadSuppliers();
  }

  async save() {
    this.saveItem = true;
    if (this.quotation.id) {
      this.item.quotationId = this.quotation.id;
      this.quotation = await this.backendService.post('quotationItem', this.item);
    }
    this.bsModalRef.hide();
  }

  close(){
    this.saveItem = false;
    this.bsModalRef.hide();
  }

  async addSupplier(){
    const initialState = { title: 'Add Supplier', supplier: { } as Supplier};
    const modalRef = this.modalService.show(SupplierFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.item.supplierId = initialState.supplier.id;
        this.loadSuppliers();
      });
  }

  private async loadSuppliers() {
    this.suppliers = await this.backendService.get('supplier');
  }
}
