import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Stock} from '../stock';
import {Room} from '../../room/room';
import {Product} from '../product';

@Component({
  selector: 'app-stock-form',
  templateUrl: './stock-form.component.html'
})
export class StockFormComponent implements OnInit {
  public stock!: Stock;
  public title!: string;
  products!: Product[];
  EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
  rooms!: Room[];

  constructor(private bsModalRef: BsModalRef,
              private backendService: BackendService) {
  }

  async ngOnInit(){
    this.products = await this.backendService.get('product');
    this.rooms = await this.backendService.get('room');
  }

  save() {
    if (this.stock.id) {
      this.backendService.putSilently('stockBalance', this.stock).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.backendService.postSilently('stockBalance', this.stock).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  close(){
    this.bsModalRef.hide();
  }
}
