<section class="container-fluid content-area">

  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fas fa-book"></i> Rooms
        </div>
        <div class="card-body">
          <div class="row">
            <td class="col-sm-12">

              <button type="button" class="btn btn-primary btn-sm mb-1" (click)="add()"
                      *ngIf="authService.hasAuthority('Stock Administrator')">
                Add
              </button>

              <table class="table table-striped table-bordered" style="margin-top: 10px">
                <thead>
                <tr>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Created By</th>
                  <th>Modified By</th>
                  <th>Approved By</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody *ngIf="rooms">
                <tr *ngFor="let room of rooms">
                  <td>{{room.code}}</td>
                  <td>
                    <div class="clearfix">
                      <div class="float-left">
                        <strong>{{room.name}}</strong>
                      </div>
                      <div class="float-right">
                        <small class="text-muted">{{room.telephone}}</small>
                      </div>
                    </div>
                  </td>
                  <td>{{room.description}}</td>
                  <td>{{room.creator}} on {{room.dateTimeInserted | date : 'dd MMM yyyy HH:mm'}}</td>
                  <td>{{room.editor}} on {{room.dateTimeEdited | date : 'dd MMM yyyy HH:mm'}}</td>
                  <td>{{room.statusBy}} on {{room.dateTimeStatus | date : 'dd MMM yyyy HH:mm'}}</td>
                  <td>
                    <span [class]="statusClass(room.status)">{{room.status}}</span>
                    <p>{{room.statusComment}}</p>
                  </td>
                  <td>
                    <button type="button" class="btn btn-link" title="Edit" (click)="edit(room)">
                      <span class="fa fa-edit" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link text-success" title="Approve"
                            *ngIf="authService.hasAuthority('Stock Manager') && room.status !== 'Approved'"
                            (click)="approve(room)">
                      <span class="fa fa-thumbs-up" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link text-danger" title="Decline"
                            *ngIf="authService.hasAuthority('Stock Manager') && room.status !== 'Declined'"
                            (click)="decline(room)">
                      <span class="fa fa-thumbs-down" aria-hidden="true"></span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
