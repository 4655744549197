<section class="container content-area">
  <div class="row">
    <div class="col-sm-12">
      <button type="button" class="btn btn-secondary mb-2"
              routerLink="/stock">
        Back
      </button>
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-9">
              <h5 class="card-title">Receive Goods</h5>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 vertical-line-separator">
              <div class="row" *ngIf="!purchaseOrder.id">
                <div class="col-md-9">
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Purchase Order Number" name="poNumber"
                           [(ngModel)]="purchaseOrder.number"
                           aria-label="Purchase Order Number" aria-describedby="Purchase Order Number">
                    <div class="input-group-append">
                      <button type="button" class="btn-sm  btn-primary"
                              (click)="findPO()">
                        Find P0
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="purchaseOrder.id">
            <div class="col-md-6 vertical-line-separator">
              <div class="row">
                <div class="col-md-6"><h6>Number</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.number}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Reason</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.reason}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Supplier</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.supplier}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Supplier Telephone</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.supplierTelephone}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Supplier email</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.supplierEmail}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Supplier Quotation Number</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.supplierQuotationNumber}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Supplier Quotation Date</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.supplierQuotationDate | date : 'dd MMM YYYY'}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Created By</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.creator}}</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6"><h6>Date</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.date | date : 'dd MMM YYYY'}}</p>
                </div>
              </div>
              <div class="row" *ngIf="purchaseOrder.reason === 'Fuel'">
                <div class="col-md-6"><h6>Driver</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.driver}}</p>
                </div>
              </div>
              <div class="row" *ngIf="purchaseOrder.reason === 'Fuel'">
                <div class="col-md-6"><h6>Vehicle Registration Number</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.vehicleRegistrationNumber}}</p>
                </div>
              </div>
              <div class="row" *ngIf="purchaseOrder.reason === 'Fuel'">
                <div class="col-md-6"><h6>Purchase Reason</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.purchaseReason}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Amount</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.amount | currency: purchaseOrder.currencyCode :
                    'symbol-narrow'}}</p>
                </div>
              </div>
              <div class="row" *ngIf="purchaseOrder.actualAmount">
                <div class="col-md-6"><h6>Invoiced Amount</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.actualAmount | currency: purchaseOrder.currencyCode :
                    'symbol-narrow'}}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6"><h6>Customer</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.customer}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Customer Telephone</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.customerTelephone}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Customer email</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{purchaseOrder.customerEmail}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Status</h6></div>
                <div class="col-md-6">
                  <p [class]="statusClass(purchaseOrder.status)">{{purchaseOrder.status}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Status Comment</h6></div>
                <div class="col-md-6">
                  <p>{{purchaseOrder.statusComment}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form #invoiceForm="ngForm" >
        <div class="card-footer" *ngIf="purchaseOrder.id">
          <div class="row mb-2">
            <div class="col-3">
              <input type="text" class="form-control" placeholder="Invoice Number" name="invoiceNumber"
                     [(ngModel)]="stockTransaction.invoiceNumber" required #invoiceNumber="ngModel"
                     aria-label="Invoice Number" aria-describedby="Invoice Number">
              <div *ngIf="invoiceNumber.invalid">
                <div *ngIf="invoiceNumber.errors?.required" class="alert alert-danger">
                  Invoice number is required.
                </div>
              </div>
            </div>
            <div class="col-9">
              <div *ngIf="errorMessage" class="alert alert-danger">
                Error occured while saving stock received. 
                <p>{{errorMessage}}</p>
              </div>
            </div>
          </div>

            <button type="button" class="btn btn-sm btn-primary mb-1" title="Add Item"
                    (click)="add()">
              Add Item
            </button>

          <table class="table table-striped table-bordered" style="margin-top: 10px">
            <thead>
            <tr>
              <th>Code</th>
              <th>Description</th>
              <th>Room</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Amount</th>
            </tr>
            </thead>
            <tbody *ngIf="stockTransaction.items">
            <tr *ngFor="let item of stockTransaction.items">
              <td>{{item.productCode}}</td>
              <td>{{item.product}}</td>
              <td>
                <div class="col-12">
                  <select class="form-control" [name]="'roomId'+item.productCode" 
                          [(ngModel)]="item.roomId" #roomId="ngModel" required>
                    <option *ngFor="let room of rooms" [value]="room.id">{{room.code}} - {{room.name}}</option>
                  </select>
                  <div *ngIf="roomId.invalid">
                    <div *ngIf="roomId.errors?.required" class="alert alert-danger">
                      Room is required.
                    </div>
                  </div>
                </div>                
              </td>
              <td>
                <input type="number" class="form-control" [name]="'quantity-' + item.productCode"
                       #quantity="ngModel" required=""  [(ngModel)]="item.quantity">
                <div *ngIf="quantity.invalid">
                  <div *ngIf="quantity.errors?.required" class="alert alert-danger">
                    Quantity is required.
                  </div>
                </div>
              </td>
              <td>
                <input type="number" class="form-control" name="unitPrice- + {{item.productCode}}" required
                       #unitPrice="ngModel"  [(ngModel)]="item.unitPrice">
                <div *ngIf="unitPrice.invalid">
                  <div *ngIf="unitPrice.errors?.required" class="alert alert-danger">
                    Unit Price is required.
                  </div>
                </div>
              </td>
              <td>{{(item.quantity * item.unitPrice) | currency: stockTransaction.currencyCode : 'symbol-narrow'}}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="card-footer" *ngIf="purchaseOrder.id">
          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn-sm  btn-primary" [disabled]="invoiceForm.invalid"
                      (click)="save()">
                Save Goods Received
              </button>
            </div>
          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
</section>
