<section class="container-fluid content-area">

  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fas fa-clock"></i> Timesheet
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-4 my-auto">
              <button type="button" class="btn btn-primary btn-sm mb-1 mr-3" (click)="add()"
              *ngIf="timesheet.employeeId === authService.loggedOnUser.id">
                Add New Row
              </button>
              <button type="button" class="btn btn-primary btn-sm mb-1" (click)="download()"
              *ngIf="timesheet.employeeId === authService.loggedOnUser.id">
                Download
              </button>
            </div>
            <div class="col-8 d-flex flex-row-reverse">
              <div class="col-5 form-group my-auto">
                <div class="input-group" *ngIf="weekDate">
                  <input type="text" id="weekDate" class="input-group-text"
                         placeholder="Week Date" #weekDateDp="bsDatepicker"
                         [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                         [(ngModel)]="weekDate" (bsValueChange)="onDateChange($event)"
                         bsDatepicker>

                  <button class="btn btn-outline-primary input-group-append"
                          (click)="weekDateDp.toggle()" [attr.aria-expanded]="weekDateDp.isOpen">
                    <span class="fa fa-calendar" aria-hidden="true"></span>
                  </button>
                </div>
              </div>
              <div class="form-group col-3 my-auto">
                <select class="form-control" name="employee" id="employee" (ngModelChange)="onEmployeeChange($event)"
                        #employee="ngModel"  [disabled]="!authService.hasAuthority('Manager')"
                        [(ngModel)]="this.timesheet.employeeId" required>
                  <option *ngFor="let employee of employees" [value]="employee.id">{{employee.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row my-2">
            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col" class="col-2">Type of Work</th>
                <th scope="col" class="col-2">Description</th>
                <th scope="col" class="text-center"
                    *ngFor="let dayTotal of timesheet.dailyTotals">{{dayTotal.taskDate | date : 'EEEEEE, MMM dd'}}</th>
                <th scope="col" class="text-center">Total</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let task of timesheet.tasks">
                <td class="align-middle">
                  <select class="form-control" name="driver" [(ngModel)]="task.projectId" *ngIf="!task.projectId "
                          [disabled]="timesheet.employeeId !== authService.loggedOnUser.id"
                          required>
                    <option *ngFor="let project of approvedProjects" [value]="project.id">{{project.name}}</option>
                  </select>
                  <span *ngIf="task.projectId">{{project(task.projectId).name}}</span>
                </td>
                <td *ngIf="projects" class="small">
                  <span *ngIf="task.projectId">{{project(task.projectId).description}}</span>
                </td>
                <td *ngFor="let day of timesheet.dailyTotals" [class]="isToday(day.taskDate, task.durations)">
                  <input class="form-control" name="taskDuration"
                         [(ngModel)]="duration(day.taskDate, task.durations).durationStr" autocomplete="false"
                         (focusout)="saveDuration(task, duration(day.taskDate, task.durations))"
                         [disabled]="!task.projectId || inFuture(day.taskDate, task.durations)"
                         type="Time" stretch="true" >
                    <div class="col-12 text-sm-center">
                      {{duration(day.taskDate, task.durations).description}}
                      <span 
                        *ngIf="timesheet.employeeId === authService.loggedOnUser.id  && 
                        !inFuture(day.taskDate, task.durations)" 
                            class="fa fa-edit" aria-hidden="true" 
                            (click)="editDuration(task, duration(day.taskDate, task.durations))"></span>
                    </div>
                </td>
                <td>{{task.totalDuration.hours}} : {{task.totalDuration.minutes}}</td>
              </tr>
              </tbody>
              <thead>
              <tr>
                <th scope="col" colspan="2">Total</th>
                <th scope="col" class="text-center"
                    *ngFor="let total of timesheet.dailyTotals">{{total.duration.hours}} : {{total.duration.minutes}}</th>
                <th scope="col" *ngIf="timesheet.total" class="text-center">{{timesheet.total.duration.hours}}:{{timesheet.total.duration.minutes}}</th>
              </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
