import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PurchaseOrder} from '../purchase-order';
import {Supplier} from '../../supplier/supplier';
import {currencies} from '../../../environments/currencies';
import {Employee} from "../../employee/employee";
import {SupplierFormComponent} from "../../supplier/supplier-form/supplier-form.component";
import {take} from "rxjs/operators";
import {Vehicle} from "../../fleet/vehicle";
import {AuthService} from "../../auth/auth.service";

@Component({
  selector: 'app-purchase-order-form',
  templateUrl: './purchase-order-form.component.html'
})
export class PurchaseOrderFormComponent implements OnInit {
  public purchaseOrder!: PurchaseOrder;
  public title!: string;
  suppliers!: Supplier[];
  drivers!: Employee[];
  orderDate = new Date();
  supplierQuotationDate = new Date();
  availableCurrencies = currencies;
  vehicles!: Vehicle[];
  departments = [
    'Administration',
    'Workshop',
    'Bytes',
    'FNB',
    'Webber',
    'Stowe',
    'Communications',
    'Sales'];

  constructor(private bsModalRef: BsModalRef,
              private modalService: BsModalService,
              private backendService: BackendService,
              public  authService: AuthService) {
  }

  async ngOnInit() {
    this.departments.sort();
    await this.loadSuppliers();
    this.drivers = await this.backendService.get('employee');
    this.vehicles = await this.backendService.get('vehicle');
    if (this.purchaseOrder.date) {
      this.orderDate = new Date(this.purchaseOrder.date);
    }
  }

  private async loadSuppliers() {
    this.suppliers = await this.backendService.get('supplier');
  }

  save() {
    this.purchaseOrder.date = this.orderDate.getTime();
    this.purchaseOrder.supplierQuotationDate = this.supplierQuotationDate.getTime();
    if (this.purchaseOrder.id) {
      this.backendService.putSilently('purchaseOrder', this.purchaseOrder).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.backendService.postSilently('purchaseOrder', this.purchaseOrder).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  close() {
    this.bsModalRef.hide();
  }

  async addSupplier() {
    const initialState = {title: 'Add Supplier', supplier: {} as Supplier};
    const modalRef = this.modalService.show(SupplierFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.loadSuppliers();
      });
  }
}
