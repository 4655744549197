import {Component, OnInit, ViewChild} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {ActivatedRoute, Router} from "@angular/router";
import {Quotation} from "../quotation";
import {QuotationItem} from "../quotation-item";
import {PurchaseOrderItem} from "../../purchase-order/purchase-order-item";
import {PurchaseOrderItemFormComponent} from "../../purchase-order/purchase-order-item-form/purchase-order-item-form.component";
import {take} from "rxjs/operators";
import {BsModalService} from "ngx-bootstrap/modal";
import {QuotationItemFormComponent} from "../quotation-item-form/quotation-item-form.component";
import {ModalService} from "../../util/modal.service";
import {DocumentUploadModalComponent} from "../../document/document-upload-modal.component";
import {DocumentsComponent} from "../../document/documents.component";
import {AuditComponent} from "../../audit/audit.component";

@Component({
  selector: 'app-quotation-view',
  templateUrl: './quotation-view.component.html'
})
export class QuotationViewComponent implements OnInit {
  quotation = {items: []} as unknown as Quotation;
  @ViewChild('quotationDocuments') quotationDocuments!: DocumentsComponent;
  @ViewChild('quotationAuditLogs') quotationAuditLogs!: AuditComponent;
  title!: string;

  constructor(private backendService: BackendService,
              private modalService: BsModalService,
              private cooModalService: ModalService,
              private activatedRoute: ActivatedRoute) {
  }

  async ngOnInit() {
    const quotationId = this.activatedRoute.snapshot.params.id;
    this.title = 'Create Quotation';
    if (quotationId) {
      this.quotation = await this.backendService.get(`quotation/${quotationId}`);
      this.title = `Quotation ${this.quotation.number}`;
    }
  }

  statusClass(status: string) {
    switch (status) {
      case 'Draft':
      case 'Awaiting Approval':
      case 'Approved':
        return 'badge badge-warning';
      case 'Accepted':
        return 'badge badge-success';
      case 'Declined':
      case 'Expired':
      case 'Rejected':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }

  async submitForApproval() {
    this.quotation = await this.backendService.get(`quotation/submitForApproval/${this.quotation.id}`);    
  }

  async decline() {

    await this.cooModalService
      .comment('Decline Quotation', 
        `Confirm that you are declining ${this.quotation.number} quotation and add reason.`, true,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            this.quotation.statusComment = comment;
            this.quotation.status = 'Declined';
            this.backendService.postSilently('quotation/status', this.quotation)
              .then(async (res: any) => {
                  this.quotation = await this.backendService.get(`quotation/${this.quotation.id}`);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
    
  }
  
  async approve() {
    await this.cooModalService
      .comment('Approve Quotation', `Confirm that you are approving ${this.quotation.number} quotation.`, false,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            this.quotation.statusComment = comment;
            this.quotation.status = 'Approved';
            this.backendService.postSilently('quotation/status', this.quotation)
              .then(async (res: any) => {
                  this.quotation = await this.backendService.get(`quotation/${this.quotation.id}`);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
    
  }

  accept() {
    this.upload('Quotation Acceptance')
  }

  reject() {
    
  }

  upload(documentType: string) {
    const initialState = {
      path: 'quotation/upload', ownerId: this.quotation.id, type: documentType, number: this.quotation.number
    };
    const modalRef = this.modalService.show(DocumentUploadModalComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(async () => {
        this.quotationDocuments.loadDocuments();
        this.quotation = await this.backendService.postSilently(`quotation/accept/${this.quotation.id}`, {});
      });
  }

  belowThreshold(markup: number) {
    if (markup < 0.25) {
      return 'text-danger';
    }
    return  'text-success';
  }
}
