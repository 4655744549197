import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Waybill} from '../waybill';

@Component({
  selector: 'app-waybill-form',
  templateUrl: './waybill-form.component.html',
  styleUrls: ['./waybill-form.component.css']
})
export class WaybillFormComponent implements OnInit {
  public waybill!: Waybill;
  plannedDispatchDate = new Date();
  plannedDeliveryDate = new Date();
  supplierCollectionDate = new Date();
  public title!: string;

  constructor(private bsModalRef: BsModalRef,
              private backendService: BackendService) {
  }

  ngOnInit(): void {
    if (this.waybill.plannedDispatchDate) {
      this.plannedDispatchDate = new Date(this.waybill.plannedDispatchDate);
    }
    if (this.waybill.plannedDeliveryDate) {
      this.plannedDeliveryDate = new Date(this.waybill.plannedDeliveryDate);
    }
    if (this.waybill.supplierCollectionDate) {
      this.supplierCollectionDate = new Date(this.waybill.supplierCollectionDate);
    }
  }

  save() {
    this.waybill.plannedDispatchDate = this.plannedDispatchDate.getTime();
    this.waybill.plannedDeliveryDate = this.plannedDeliveryDate.getTime();
    if (this.supplierCollectionDate) {
      this.waybill.supplierCollectionDate = this.supplierCollectionDate.getTime();
    }
    if (this.waybill.id) {
      this.backendService.putSilently('waybill', this.waybill).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.backendService.postSilently('waybill', this.waybill).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  close() {
    this.bsModalRef.hide();
  }
}
