<section class="container content-area">
  <div class="row">
    <div class="col-sm-12">
      <button type="button" class="btn btn-secondary mb-2"
              routerLink="/quotations">
        Back
      </button>
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-9">
              <h3 class="card-title">{{title}}</h3>
            </div>
          </div>
        </div>
        <form #quotationForm="ngForm">
          <div class="card-body" *ngIf="quotation">
            <div class="row">
              <div class="form-group col-3">
                <label for="contactPerson">Contact Person</label>
                <input type="text" class="form-control" placeholder="Contact Person" name="contactPerson"
                       id="contactPerson"
                       [(ngModel)]="quotation.contactPerson"
                       aria-label="Contact Person" aria-describedby="Contact Person">
              </div>
              <div class="form-group col-3">
                <label for="contactNumber">Contact Number</label>
                <input type="tel" class="form-control" placeholder="Contact Number" name="contactNumber"
                       id="contactNumber"
                       [(ngModel)]="quotation.contactNumber" required #contactNumber="ngModel"
                       aria-label="Contact Number" aria-describedby="Contact Person">
                <div *ngIf="contactNumber.invalid">
                  <div *ngIf="contactNumber.errors?.required" class="alert alert-danger">
                    Contact number is required.
                  </div>
                </div>
              </div>
              <div class="form-group col-3">
                <label for="contactEmail">Contact Email</label>
                <input type="email" class="form-control" placeholder="Contact Email" name="contactEmail"
                       id="contactEmail"
                       [(ngModel)]="quotation.contactEmail" required #contactEmail="ngModel"
                       aria-label="Contact Email" aria-describedby="Contact Email">
                <div *ngIf="contactEmail.invalid">
                  <div *ngIf="contactEmail.errors?.required" class="alert alert-danger">
                    Contact email is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-3">
                <label for="company">Company</label>
                <input type="text" class="form-control" placeholder="Company" name="company"
                       id="company"
                       [(ngModel)]="quotation.company"
                       aria-label="Company" aria-describedby="Company"/>
              </div>
              <div class="form-group col-3">
                <label for="contactPerson">Company Vat Number</label>
                <input type="text" class="form-control" placeholder="Company Vat Number" name="vatNumber"
                       id="vatNumber"
                       [(ngModel)]="quotation.companyVatNumber"
                       aria-label="Company Vat Number" aria-describedby="Company Vat Number"/>
              </div>
            </div>
            <div class="row">

              <div class="col" *ngIf="quotation.items">
                <h4>Quotation Items</h4>

                <div class="row mb-1">
                  <div class="col-2 my-auto btn-group ">
                    <button type="button" class="btn btn-primary btn-sm"
                            (click)="addItem()">
                      Add Item
                    </button>
                  </div>
                </div>
                <table class="table table-striped table-bordered">
                  <caption>Items to quote</caption>
                  <thead>
                  <tr>
                    <th>Code</th>
                    <th>Supplier</th>
                    <th>Description</th>
                    <th>Unit Cost Price</th>
                    <th>Unit Price</th>
                    <th>Markup</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody *ngIf="quotation.items">
                  <tr *ngFor="let item of quotation.items">
                    <td>{{item.code}}</td>
                    <td>{{item.supplier}}</td>
                    <td>{{item.description}}</td>
                    <td class="text-right">{{item.unitCostPrice | currency : 'LSL '  : 'symbol-narrow'}}</td>
                    <td class="text-right">{{item.unitPrice | currency : 'LSL '  : 'symbol-narrow'}}</td>
                    <td class="text-right {{belowThreshold(item.markup)}}">{{item.markup | percent : '1.0'}}</td>
                    <td class="text-right">{{item.quantity | number}}</td>
                    <td
                      class="text-right">{{(item.unitPrice * item.quantity) | currency : 'LSL '  : 'symbol-narrow'}}</td>
                    <td>
                      <button type="button" class="btn btn-link text-danger" title="Remove" 
                              (click)="remove(item)">
                        <span class="fa fa-minus-circle" aria-hidden="true"></span>
                      </button>
                      <button type="button" class="btn btn-link text-danger" title="Edit" *ngIf="item.id" 
                              (click)="edit(item)">
                        <span class="fa fa-edit" aria-hidden="true"></span>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <td colspan="7" class="text-right">Total excluding VAT</td>
                    <td class="text-right">{{quotation.totalExcludingVat  | currency : 'LSL '  : 'symbol-narrow'}}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="7" class="text-right">VAT</td>
                    <td class="text-right">{{quotation.totalVat  | currency : 'LSL '  : 'symbol-narrow'}}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="7" class="text-right">Total Including VAT</td>
                    <td class="text-right">{{quotation.totalIncludingVat  | currency : 'LSL '  : 'symbol-narrow'}}</td>
                    <td></td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <div class="row">
              <div class="col-9">
                <div *ngIf="errorMessage" class="alert alert-danger">
                  Error occured while saving delivery note.
                  <p>{{errorMessage}}</p>
                </div>
              </div>
              <div class="col-3 m-auto text-center">
                <button type="button" class="btn  btn-primary"
                        [disabled]="quotationForm.invalid || !quotation.items || quotation.items.length <= 0 "
                        (click)="save()">
                  Save Quotation
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
