import {Component, Input, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {take} from 'rxjs/operators';
import {Room} from '../room';
import {RoomFormComponent} from '../room-form/room-form.component';
import {AuthService} from '../../auth/auth.service';
import {ModalService} from '../../util/modal.service';

@Component({
  selector: 'app-room-list',
  templateUrl: './room-list.component.html'
})
export class RoomListComponent implements OnInit {

  rooms!: Room[];
  constructor(private backendService: BackendService,
              public authService: AuthService,
              private cooModalService: ModalService,
              private modalService: BsModalService) { }

  async ngOnInit() {
    await this.load();
  }

  private async load() {
    this.rooms = await this.backendService.get<Room[]>('room/');
  }

  async approve(room: Room){
    await this.cooModalService
      .comment('Approve Room', `Confirm that you are approving ${room.name} as a room.`, false,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            room.statusComment = comment
            room.status = 'Approved';
            this.backendService.postSilently('room/status', room)
              .then((res: any) => {
                this.load();
                },
                (err) => {
                  this.cooModalService.error( err.error);
                });
          }
        });
  }

  async decline(room: Room){
    await this.cooModalService
      .comment('Decline Room', `Confirm that you are declining ${room.name} as a room and add reason.`, true,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            room.statusComment = comment
            room.status = 'Declined';
            this.backendService.postSilently('room/status', room)
              .then((res: any) => {
                  this.load();
                },
                (err) => {
                  this.cooModalService.error( err.error);
                });
          }
        });

  }

  async edit(room: Room){
    const initialState = { title: 'Edit Room', room};
    const modalRef = this.modalService.show(RoomFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load();
      });
  }

  async add(){
    const initialState = { title: 'Add Room', room: { } as Room};
    const modalRef = this.modalService.show(RoomFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load();
      });
  }

  statusClass(status: string) {
    switch (status) {
      case 'New':
        return 'badge badge-info';
      case 'Awaiting Approval':
        return 'badge badge-warning';
      case 'Approved':
        return 'badge badge-success';
      case 'Declined':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }
}
