<section class="container-fluid content-area">
  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Orders
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-3" *ngFor="let summary of orderSummary">
              <a routerLink="/tenders/{{summary.status}}">
                <div [class]="'callout ' + calloutStatus(summary.status) + ' b-t-1 b-r-1 b-b-1 text-center'">
                  <h2><span [class]="statusClass(summary.status)">{{summary.status}}</span></h2>
                  <h5 class="text-muted">{{summary.numberOfRequests}} Requests</h5>
                  <strong class="h4">{{summary.amount | currency: 'M' : 'symbol-narrow'}}</strong>
                </div>
              </a>
            </div>
          </div>
        </div><!--/.row-->
      </div>
    </div>
  </div>
</section>
