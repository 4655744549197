import {HttpClient, HttpErrorResponse, HttpEvent, HttpEventType} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Subscription, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ModalService} from './modal.service';
import {AuthService} from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class UploadService {

  uploadPercentage!: any;
  uploadSubscription!: Subscription;
  busyAfterUpload = false;

  constructor(private http: HttpClient,
              private modalService: ModalService,
              private authService: AuthService) {
  }

  get busyUploading() {
    return this.uploadPercentage !== null;
  }

  upload(path: string, file: File, objectId: string, type: string, comment: string, documentNumber: string)
    : Promise<void> {
    const formData = new FormData();
    formData.append('Document', file);
    formData.append('OwnerId', objectId);
    formData.append('Type', type);
    formData.append('Comment', comment);
    formData.append('Number', documentNumber);

    this.uploadPercentage = 0;

    const requestObservable = this.http.post(
      environment.apiUrl + path,
      formData,
      { reportProgress: true, observe: 'events', headers: {
          Authorization: `Bearer ${this.authService.getToken()}`,
          accept: '*/*'
        } }
    ).pipe(
      tap(event => this.handleEvent(event)),
      catchError(e => this.handleError(e))
    );

    return new Promise<void>((resolve, reject) => {
      this.uploadSubscription = requestObservable.subscribe(
        () => {
        },
        (err: any) => reject(err),
        () => resolve());
    });
  }
  private handleEvent(event: HttpEvent<any>) {
    if (event.type === HttpEventType.UploadProgress) {
      this.uploadPercentage = Math.round(100 * event.loaded / event.total);

      if (this.uploadPercentage === 100) {
        this.uploadPercentage = null;
        this.busyAfterUpload = true;
      }
    } else if (event.type === HttpEventType.Response) {
      this.busyAfterUpload = false;
      this.displaySuccess('Successfully completed');
    }
  }

  private handleError(e: Error) {
    if (e instanceof HttpErrorResponse && e.status === 401) {
      // User has been logged out, refresh to trigger login screen
      window.location.reload();
    } else {
      this.displayError(e);
    }

    this.uploadPercentage = null;
    this.busyAfterUpload = false;
    return throwError(e);
  }

  private displaySuccess(message: string) {
    const messageModal = this.modalService.success(message);
  }

  private displayError(e: Error) {
    this.modalService.error(e);
  }
}
