import { Component, OnInit } from '@angular/core';
import {BackendService} from "../../util/backend.service";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../auth/auth.service";
import {ModalService} from "../../util/modal.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {take} from "rxjs/operators";
import {formatDate} from "@angular/common";
import {Order} from "../order";
import {OrderFormComponent} from "../order-form/order-form.component";


@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {

  orders!: Order[];
  toDate = new Date();
  fromDate = new Date();
  status = '';
  reason = '';

  constructor(private backendService: BackendService,
              private activatedRoute: ActivatedRoute,
              public authService: AuthService,
              private cooModalService: ModalService,
              private modalService: BsModalService) {
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async data => {
      console.log(data);
      if (data['status']) {
        this.status = data['status'];
      }
      await this.load();
    });
  }

  private async load() {
    const today = new Date();
    const yearAgo = new Date();
    yearAgo.setDate(today.getDate() - 30);
    this.fromDate = yearAgo;
    this.toDate = today;
    await this.search();
  }

  async search() {
    this.orders = await this.backendService.postSilently<Order[]>('order/search/',
      {
        startDate: this.fromDate.getTime(),
        endDate: this.toDate.getTime(),
        status: this.status
      });
  }

  download(order: Order) {
    this.backendService.downloadAndSave('order/download/' + order.id, `Order-${order.poNumber}.pdf`);
  }

  async edit(order: Order) {
    const initialState = {title: 'Edit  Order', order: order};
    const modalRef = this.modalService.show(OrderFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.search();
      });
  }

  async add() {
    const initialState = {
      title: 'Add  Order',
      order: {currencyCode: 'LSL'} as Order
    };
    const modalRef = this.modalService.show(OrderFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.search();
      });
  }


  statusClass(status: string) {
    switch (status) {
      case 'New':
        return 'badge badge-info';
      case 'In Progress':
        return 'badge badge-warning';
      case 'Approved':
      case 'Invoice Paid':
        return 'badge badge-success';
      case 'Cancelled':
      case 'Invoice Confirmed':
      case 'Invoiced':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }

  async exportToExcel() {
    let searchParams = {
      startDate: this.fromDate.getTime(),
      endDate: this.toDate.getTime(),
      status: this.status,
      reason: this.reason
    }
    let filename = 'orders_' + formatDate(searchParams.startDate, 'dd_MM_yyyy', 'en') + '.xlsx';
    await this.backendService.downloadFilePostExportWithFileName('order/export', searchParams, filename,
      "excel");
  }

}
