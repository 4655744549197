import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {version} from '../environments/version';
import {BackendService} from './util/backend.service';
import {AuthService} from './auth/auth.service';
import {Router} from '@angular/router';
import {IconSetService} from '@coreui/icons-angular';
import {cilEnvelopeOpen, flagSet, freeSet} from '@coreui/icons';
import {ModalService} from './util/modal.service';
import {ActivatedRoute} from '@angular/router';

interface MenuItem {
  text: string;
  link?: string;
  authority?: string[];
  show?: boolean;
  items?: SubmenuItem[];
}

interface SubmenuItem {
  text: string;
  link: string;
  authority?: string [];
  show?: boolean;
  separator?: boolean;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [IconSetService]
})
export class AppComponent implements OnInit {
  readonly VERSION = version;
  public sidebarMinimized = false;


  menuItems: MenuItem[] = [
    {text: 'Home', link: ''},
    {
      text: 'Service Desk', link: 'service-desk',
      items: [
        {text: 'Dashboard', link: 'service-desk', authority: ['Service Desk Manager', 'Service Desk Agent']},
        {text: 'Calls', link: 'service-desk/call', authority: ['Service Desk Manager', 'Service Desk Agent']},
        {text: 'Configuration', link: '', separator: true, authority: ['Service Desk Manager']},
        {text: 'Locations', link: 'service-desk/location', authority: ['Service Desk Manager']},
        {text: 'Delay Reasons', link: 'service-desk/delay-reason', authority: ['Service Desk Manager']}]
    },
    {
      text: 'Timesheet', link: 'timesheet',
      items: [
        {text: 'Timesheet', link: 'timesheet'},
        {text: 'Time', link: 'timesheet/tasks', authority: ['Manager']},
        {text: 'Analyze', link: '', separator: true, authority: ['Manager']},
        {text: 'Dashboard', link: 'timesheet/dashboard', authority: ['Manager']},
        {text: 'Configuration', link: '', separator: true, authority: ['Manager']},
        {text: 'Type of Work', link: 'projects', authority: ['Manager']}]
    },
    {
      text: 'Cash', link: 'cash/office', authority: ['Cash Administrator', 'Cash Manager']
    },
    {
      text: 'Customers', link: '', authority: ['Sales Person', 'Sales Manager'],
      items: [
        {text: 'Tenders', link: 'tenders', authority: ['Tender Administrator', 'Tender Manager']},
        {text: 'Quotations', link: 'quotations', authority: ['Sales Person', 'Sales Manager']},
        {text: 'Orders', link: 'orders', authority: ['Sales Person', 'Sales Manager']},
        {text: 'Waybills', link: 'waybills', authority: ['Waybill Administrator', 'Waybill Manager']},
      ]
    },
    {
      text: 'Suppliers', link: 'suppliers', authority: ['Supplier Administrator', 'Supplier Manager',
        'Purchase Order Administrator', 'Purchase Order Manager'],

      items: [
        {text: 'Suppliers', link: 'suppliers', authority: ['Supplier Administrator', 'Supplier Manager']},
        {
          text: 'Purchase Orders',
          link: '', separator: true
        },
        {
          text: 'Customer Order',
          link: 'purchase-orders/Customer Order',
          authority: ['Purchase Order Administrator', 'Purchase Order Manager']
        },
        {
          text: 'Fuel',
          link: 'purchase-orders/Fuel/',
          authority: ['Purchase Order Administrator', 'Purchase Order Manager']
        },
        {
          text: 'Inventory',
          link: 'purchase-orders/Inventory',
          authority: ['Purchase Order Administrator', 'Purchase Order Manager']
        }
      ]
    },
    {
      text: 'Stock', link: 'stock', authority: ['Stock Administrator', 'Stock Manager'],
      items: [
        {text: 'Stock', link: 'stock', authority: ['Stock Administrator', 'Stock Manager']},
        {text: 'Delivery Notes', link: 'delivery-note', authority: ['Stock Administrator', 'Stock Manager']},
        {text: 'Rooms', link: 'rooms', authority: ['Stock Administrator', 'Stock Manager']}
      ]
    },
    {text: 'Fleet', link: 'vehicle', authority: ['Fleet Administrator']},
    {text: 'Users', link: 'users', authority: ['User Administrator']},
  ];
  loggedOnUser: any;

  constructor(
    private backend: BackendService,
    private modalService: ModalService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public iconSet: IconSetService
  ) {
    // iconSet singleton
    iconSet.icons = {...freeSet};
    this.filterMenuItems();
  }

  get busy() {
    return this.backend.busy;
  }

  toggleMinimize(e: any) {
    this.sidebarMinimized = e;
  }

  async ngOnInit() {
    this.authService.authSubject.subscribe(state => {
      this.filterMenuItems();
      if (state) {
        this.loggedOnUser = this.authService.loggedOnUser;
        this.router.navigate(['']);
      } else {
        this.loggedOnUser = null;
        console.log(this.router.url);
        if (this.route.snapshot.url.length <= 0 || this.router.url !== 'privacy') {
          this.router.navigate(['login']);
        }
      }
    });
    this.authService.checkIfLoggedOn();
    this.filterMenuItems();
  }

  async logout() {
    this.authService.logout();
  }

  async resetPin() {
    this.modalService.info('Feature not yet implemented.');
  }

  async editProfile() {
    this.modalService.info('Feature not yet implemented.');
  }

  filterMenuItems() {
    // Filter submenus
    for (const menuItem of this.menuItems) {
      if (!menuItem.items) {
        continue;
      }

      for (const submenuItem of menuItem.items) {
        submenuItem.show =
          !submenuItem.authority ||
          this.authService.hasAuthorities(submenuItem.authority);
      }

      menuItem.show = menuItem.items.some((sub) => sub.show);
    }

    // Filter top-level menu
    for (const menuItem of this.menuItems.filter((mi) => !mi.items)) {
      menuItem.show =
        !menuItem.authority ||
        this.authService.hasAuthorities(menuItem.authority);
    }
  }
}
