import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Observable, BehaviorSubject} from 'rxjs';

import {CredentialsDto} from './credentialsDto';
import {AuthResponse} from './auth-response';
import {environment} from '../../environments/environment';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authSubject = new BehaviorSubject(false);
  loggedOnUser = {authorities: [''], accessToken: '', businessId: '', id: ''};

  constructor(private httpClient: HttpClient,
              @Inject(LOCAL_STORAGE) private storage: StorageService) {
  }

  login(user: CredentialsDto): Observable<AuthResponse> {
    return this.httpClient.post(environment.apiUrl + 'login', user).pipe(
      tap(async (res: any) => {

        if (res.user) {
          this.loggedOnUser = res.user;
          await this.storage.set('LOGGED_IN_USER', res.user);
          this.authSubject.next(true);
        }
      })
    );
  }

  async logout() {
    await this.storage.remove('LOGGED_IN_USER');
    this.loggedOnUser = null as any;
    this.authSubject.next(false);
  }

  hasAuthority(authority: string) {
    if (this.loggedOnUser) {
      return this.loggedOnUser && this.loggedOnUser.authorities.indexOf(authority) !== -1;
    }
    return false;
  }

  hasAuthorities(authorities: string[]) {
    for( let i = 0; i < authorities.length; i++) {
      if (this.hasAuthority(authorities[i])){
        return true;
      }
    }
    return false;
  }


  checkIfLoggedOn() {
    const loggedinuser = this.storage.get('LOGGED_IN_USER');
    if (loggedinuser) {
      this.loggedOnUser = loggedinuser;
      this.authSubject.next(true);
    } else {
      this.logout();
    }
  }

  resetPin(credentials: CredentialsDto): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrl + 'resetPassword', credentials);
  }

  getToken() {
    return this.loggedOnUser.accessToken;
  }
}
