<section class="container-fluid content-area">

  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fas fa-book"></i>Stock
        </div>
        <div class="card-body">
          <div class="row">
            <td class="col-sm-12">
              <div class="btn-group" role="group" aria-label="Buttons">
              <button type="button" class="btn btn-primary btn-sm mb-1" (click)="add()"
                      *ngIf="authService.hasAuthority('Stock Administrator')">
                Add
              </button>

              <button type="button" class="btn btn-primary btn-sm mb-1" routerLink="/stock/receive"
                      *ngIf="authService.hasAuthority('Stock Administrator')">
                Receive Stock
              </button>

                <button type="button" class="btn btn-primary btn-sm mb-1" routerLink="/delivery-note/form"
                        *ngIf="authService.hasAuthority('Stock Administrator')">
                  Dispatch Stock
                </button>

              </div>

              <table class="table table-striped table-bordered" style="margin-top: 10px">
                <thead>
                <tr>
                  <th>Room</th>
                  <th>Product Code</th>
                  <th>Product Name</th>
                  <th>Total Cost Price</th>
                  <th>Total Quantity</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody *ngIf="stocks">
                <tr *ngFor="let stock of stocks">
                  <td>{{stock.room}}</td>
                  <td>{{stock.productCode}}</td>
                  <td>{{stock.productName}}</td>
                  <td class="text-right">{{stock.price | currency : 'LSL'  : 'symbol-narrow'}}</td>
                  <td class="text-right">{{stock.quantity | number}}</td>
                  <td>
                    <button type="button" class="btn btn-link" title="View"
                            routerLink="/stock/view/{{stock.id}}">
                      <span class="fa fa-eye" aria-hidden="true"></span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
