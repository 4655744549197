<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body bg-light">

  <form #stockForm="ngForm" (ngSubmit)="save()">
    <div class="form-group row">
      <div class="col-3">
        <label>Product</label>
      </div>
      <div class="col-6">
        <select class="form-control" name="driver" [(ngModel)]="stock.productId" required>
          <option [value]="EMPTY_GUID">Other</option>
          <option *ngFor="let product of products" [value]="product.id">{{product.code}} - {{product.name}}</option>
        </select>
      </div>
    </div>
    <div *ngIf="stock.productId == EMPTY_GUID">
      <div class="form-group row">
        <div class="col-3">
          <label>Product Code</label>
        </div>
        <div class="col-6">
          <input class="form-control" name="productCode"
                 [(ngModel)]="stock.productCode"
                 stretch="true"
                 pattern="[a-zA-Z0-9]*"
                 minlength="2"
                 maxlength="5"
                 required>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-3">
          <label>Product Name</label>
        </div>
        <div class="col-6">
          <input class="form-control" name="productName" [(ngModel)]="stock.productName"
                 type="text" stretch="true" required>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-3">
        <label>Room</label>
      </div>
      <div class="col-6">
        <select class="form-control" name="driver" [(ngModel)]="stock.roomId" required>
          <option [value]="EMPTY_GUID">Other</option>
          <option *ngFor="let room of rooms" [value]="room.id">{{room.code}} - {{room.name}}</option>
        </select>
      </div>
    </div>

    <div *ngIf="stock.roomId == EMPTY_GUID">
      <div class="form-group row">
        <div class="col-3">
          <label>Room Code</label>
        </div>
        <div class="col-6">
          <input class="form-control" name="roomCode"
                 [(ngModel)]="stock.roomCode"
                 stretch="true"
                 pattern="[a-zA-Z0-9]*"
                 minlength="2"
                 maxlength="4"
                 required>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-3">
          <label>Room Name</label>
        </div>
        <div class="col-6">
          <input class="form-control" name="room" [(ngModel)]="stock.room" type="text" stretch="true" required>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-3">
        <label>Unit Cost</label>
      </div>
      <div class="col-6">
        <input class="form-control" name="name" [(ngModel)]="stock.unitPrice"
               type="number" stretch="true" required>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-3">
        <label>Quatity</label>
      </div>
      <div class="col-6">
        <input class="form-control" name="name" [(ngModel)]="stock.quantity"
               type="number" stretch="true" required>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!stockForm.valid">
        Save
      </button>

      <button type="button" (click)="close()" class="btn btn-secondary left mr-1">
        Cancel
      </button>
    </div>
  </form>
</div>