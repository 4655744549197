<form (submit)="saveComment()" #thisForm="ngForm">
  <div class="form-group row">
    <div class="col-md-12">
      <h6 class="required-field">
        Comment
      </h6>
      <textarea class="form-control"
                id="comment"
                name="comment"
                placeholder="add comment here..."
                [(ngModel)]="comment"
                required></textarea>
    </div>
  </div>

  <button type="submit"
          class="btn btn-info mt-1"
          [disabled]="!thisForm.valid"
          autofocus>
    Comment
  </button>
</form>

<hr/>
<div *ngFor="let comment of comments"
     class="card border-dark mt-3">
  <div class="card-header">
    <b>Made on: {{comment.dateTimeInserted | date: 'dd MMM yyyy HH:mm'}}</b>
  </div>
  <div class="card-body">
    <blockquote class="blockquote mb-0">
      <pre>{{comment.comment}}</pre>
      <footer class="blockquote-footer">
        Made by
        <cite title="Source Title">{{comment.user}}</cite><br/>
      </footer>
    </blockquote>
  </div>
</div>