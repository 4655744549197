<section class="container content-area">
  <div class="row">
    <div class="col-sm-12">
      <button type="button" class="btn btn-lg btn-secondary mb-2"
              routerLink="/purchase-orders">
        Back
      </button>
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-9">
              <h5 class="card-title">Purchase Order</h5>
            </div>
          </div>
        </div>
        <div class="row card-body">
          <div class="col-md-6 vertical-line-separator">
            <div class="row">
              <div class="col-md-6"><h6>Number</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.number}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Reason</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.reason}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Supplier</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.supplier}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Supplier Telephone</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.supplierTelephone}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Supplier email</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.supplierEmail}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Supplier Quotation Number</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.supplierQuotationNumber}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Supplier Quotation Date</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.supplierQuotationDate | date : 'dd MMM YYYY'}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Created By</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.creator}}</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6"><h6>Date</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.date | date : 'dd MMM YYYY'}}</p>
              </div>
            </div>
            <div class="row" *ngIf="purchaseOrder.reason === 'Fuel'">
              <div class="col-md-6"><h6>Driver</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.driver}}</p>
              </div>
            </div>
            <div class="row" *ngIf="purchaseOrder.reason === 'Fuel'">
              <div class="col-md-6"><h6>Vehicle Registration Number</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.vehicleRegistrationNumber}}</p>
              </div>
            </div>
            <div class="row" *ngIf="purchaseOrder.reason === 'Fuel'">
              <div class="col-md-6"><h6>Purchase Reason</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.purchaseReason}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Amount</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.amount | currency: purchaseOrder.currencyCode : 'symbol-narrow'}}</p>
              </div>
            </div>
            <div class="row" *ngIf="purchaseOrder.actualAmount">
              <div class="col-md-6"><h6>Invoiced Amount</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.actualAmount | currency: purchaseOrder.currencyCode : 'symbol-narrow'}}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6"><h6>Customer</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.customer}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Customer Telephone</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.customerTelephone}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Customer email</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{purchaseOrder.customerEmail}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Status</h6></div>
              <div class="col-md-6">
                <p [class]="statusClass(purchaseOrder.status)">{{purchaseOrder.status}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Status Comment</h6></div>
              <div class="col-md-6">
                <p>{{purchaseOrder.statusComment}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-md-6">
                <button type="button" class="btn-sm  btn-info"
                        *ngIf="purchaseOrder.status === 'New' || purchaseOrder.status === 'In Progress' "
                        (click)="edit(purchaseOrder)">
                  Edit
                </button>
            </div>
            <div class="col-md-6 text-right">
                <div class="btn-group-sm"  role="group" >
                  <button type="button" class="btn btn-link" title="Download"
                          *ngIf="purchaseOrder.status === 'Approved' || purchaseOrder.status === 'Invoiced' "
                          (click)="download(purchaseOrder)">
                    <span class="fa fa-download" aria-hidden="true"></span>
                  </button>
                  <button type="button" class="btn btn-sm btn-success  ml-1" title="Submit for Approval"
                          *ngIf="purchaseOrder.status === 'New' || purchaseOrder.status === 'In Progress' "
                          (click)="submitForApproval(purchaseOrder)">
                    Submit for Approval
                  </button>
                  <button type="button" class="btn btn-sm btn-success  ml-1" title="Approve"
                          *ngIf="purchaseOrder.status === 'Awaiting Approval'
                           && authService.hasAuthority('Purchase Order Manager')"
                          (click)="approve(purchaseOrder)">
                    Approve
                  </button>
                  <button type="button" class="btn btn-sm btn-danger  ml-1" title="Decline"
                          *ngIf="purchaseOrder.status === 'Awaiting Approval'
                          && authService.hasAuthority('Purchase Order Manager')")
                          (click)="decline(purchaseOrder)">
                    Decline
                  </button>
                  <button type="button" class="btn btn-link" title="Receive Stock"
                          *ngIf="purchaseOrder.status === 'Approved'"
                          routerLink="/stock/receive/{{purchaseOrder.id}}">
                    <span class="fa fa-check-square" aria-hidden="true"></span>
                  </button>
                  <button type="button" class="btn btn-sm btn-success  ml-1" title="Confirm Invoice"
                          *ngIf="purchaseOrder.status === 'Invoiced'"
                          (click)="confirmInvoice(purchaseOrder)">
                    Confirm Invoice
                  </button>
                  <button type="button" class="btn btn-sm btn-success  ml-1" title="Confirm Pament"
                          *ngIf="purchaseOrder.status === 'Invoice Confirmed'"
                          (click)="confirmPayment(purchaseOrder)">
                    Confirm Invoice Payment
                  </button>
                <button type="button" class="btn btn-sm btn-danger ml-1" title="Cancel"
                        *ngIf="purchaseOrder.status === 'New' || purchaseOrder.status === 'In Progress' "
                        (click)="cancel(purchaseOrder)">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <tabset>
            <tab heading="Items">
              <button type="button" class="btn btn-sm btn-primary mb-1" title="Add Item"
                      *ngIf="purchaseOrder.status === 'New' || purchaseOrder.status === 'In Progress' "
                      (click)="add()">
                Add Item
              </button>

              <table class="table table-striped table-bordered" style="margin-top: 10px">
                <thead>
                <tr>
                  <th>Code</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th>Amount</th>
                  <th>Invoiced Amount</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody *ngIf="purchaseOrder.items">
                <tr *ngFor="let item of purchaseOrder.items">
                  <td>{{item.code}}</td>
                  <td>{{item.description}}</td>
                  <td>{{item.quantity}}</td>
                  <td>{{item.unitPrice  | currency: purchaseOrder.currencyCode : 'symbol-narrow'}}</td>
                  <td>{{(item.quantity * item.unitPrice) | currency: purchaseOrder.currencyCode : 'symbol-narrow'}}</td>
                  <td>{{(item.actualPrice) | currency: purchaseOrder.currencyCode : 'symbol-narrow'}}</td>
                  <td>
                    <button type="button" class="btn btn-link" title="Edit"
                            *ngIf="purchaseOrder.status === 'New' || purchaseOrder.status === 'In Progress'
                             || (purchaseOrder.status === 'Invoiced'
                             && authService.hasAuthority('Purchase Order Manager'))"
                            (click)="editItem(item)">
                      <span class="fa fa-edit" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link" title="Delete"
                            *ngIf="purchaseOrder.status === 'New' || purchaseOrder.status === 'In Progress' "
                            (click)="delete(item)">
                      <span class="fa fa-trash" aria-hidden="true"></span>
                    </button></td>
                </tr>
                </tbody>
              </table>
            </tab>
            <tab heading="Documents">
              <button type="button" class="btn btn-sm btn-primary mb-1" title="Upload Quotation"
                      *ngIf="purchaseOrder.reason === 'Customer Order'  && purchaseOrder.status !== 'Cancelled'"
                      (click)="upload('Customer Quotation')">
                Upload Customer Quotation
              </button>

              <button type="button" class="btn btn-sm btn-primary mb-1 ml-2" title="Upload Purchase Order"
                      *ngIf="purchaseOrder.reason === 'Customer Order' && purchaseOrder.status !== 'Cancelled'"
                      (click)="upload('Customer Purchase Order')">
                Upload Customer Purchase Order
              </button>

              <button type="button" class="btn btn-sm btn-primary mb-1 ml-2" title="Upload Supplier Quotation"
                      *ngIf="purchaseOrder.status !== 'Cancelled'"
                      (click)="upload('Supplier Quotation')">
                Upload Supplier Quotation
              </button>

              <button type="button" class="btn btn-sm btn-primary mb-1 ml-2" title="Upload Supplier Invoice"
                      *ngIf="purchaseOrder.status !== 'Cancelled'"
                      (click)="upload('Supplier Invoice')">
                Upload  Supplier Invoice
              </button>

              <button type="button" class="btn btn-sm btn-primary mb-1 ml-2" title="Upload Supplier Proof of Payment"
                      *ngIf="purchaseOrder.status !== 'Cancelled'"
                      (click)="upload('Supplier Proof of Payment')">
                Upload Supplier Proof of Payment
              </button>
              <div *ngIf="purchaseOrder.id">
                <app-documents #purchaseOrderDocuments  [requestPath]="'purchaseOrder/documents/' + purchaseOrder.id"></app-documents>
              </div>
            </tab>
            <tab heading="History">
              <div *ngIf="purchaseOrder.id">
                <app-audit #purchaseOrderAuditLogs  [requestPath]="'purchaseOrder/audit/' + purchaseOrder.id"></app-audit>
              </div>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</section>
