<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container-fluid">
      <div class="text-center mb-5">
        <img src="assets/images/logo.png" width="600"
             alt="Comnet Lesotho Logo" title="Comnet Lesotho "/>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-6 mx-auto my-auto">
          <div class="card-group">
            <div class="card py-5 d-md-down-none">
              <div class="card-body text-center">
                <div class="logo card-title fadeInDown text-center">
                  <h1 class="text-center font-weight-bold">Comnet Lesotho Office Operations</h1>
                  <p class="text-muted">Sign In to your account</p>
                </div>
              </div>
            </div>
            <div class="card py-5 p-4">
              <div class="card-body">
                <form #thisForm="ngForm" (submit)="login()" class="form-signin" autocomplete="off">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><fa-icon [icon]="iUser"></fa-icon></span>
                    </div>
                    <input class="form-control" id="email" name="emp-acc"
                           placeholder="employee@comnet.co.ls"
                           autocomplete="off"
                           type="email"
                           [(ngModel)]="credentials.email" required>
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><fa-icon [icon]="iLock"></fa-icon></span>
                    </div>
                    <input class="form-control"
                           id="emp-password"
                           name="emp-pwd"
                           type="password"
                           placeholder="Password"
                           autocomplete="off"
                           required
                           [(ngModel)]="credentials.password">
                  </div>
                  <div class="row">
                    <div class="col-6 text-center">
                      <button type="submit" class="btn btn-primary px-4">Login</button>
                    </div>
                    <div class="col-6 text-center">
                      <button type="button" class="small btn btn-link px-0" (click)="forgotPass()">Forgot password?</button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col text-center mt-2">
                      <p *ngIf="loginError" class="text-danger text-center">
                        {{ loginError }}
                      </p>
                      <p *ngIf="loginResult" class="text-success text-center">
                        {{ loginResult }}
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
