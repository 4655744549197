import {Component} from '@angular/core';
import {BackendService} from '../util/backend.service';
import {Summary} from './summary';

@Component({
  templateUrl: './home.component.html',
})
export class HomeComponent {
  tenderSummary!: Summary[];
  waybillSummary!: Summary[];
  poSummary!: Summary[];
  orderSummary!: Summary[];

  constructor(private backendService: BackendService) {
    this.load();
  }

  async load() {
    this.tenderSummary = await this.backendService.get('tenderSummary');
    this.waybillSummary = await this.backendService.get('waybillSummary');
    this.poSummary = await this.backendService.get('purchaseOrderSummary');
    this.orderSummary = await this.backendService.get('orderSummary');
  }

  statusClass(status: string) {
    switch (status) {
      case 'New':
        return 'badge badge-info';
      case 'In Progress':
      case 'Awaiting Approval':
        return 'badge badge-warning';
      case 'Won':
      case 'Complete':
      case 'Approved':
      case 'Delivered':
      case 'Invoiced':
      case 'Paid':
        return 'badge badge-success';
      case 'Lost':
      case 'Cancelled':
      case 'Declined':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }

  calloutStatus(status: string) {
    switch (status) {
      case 'New':
        return 'callout-info';
      case 'In Progress':
      case 'Awaiting Approval':
      case 'Awaiting Accounts Approval':
      case 'Awaiting Manager Approval':
        return 'callout-warning';
      case 'Won':
      case 'Completed':
      case 'Approved':
      case 'Delivered':
      case 'Invoiced':
      case 'Paid':
        return 'callout-success';
      case 'Lost':
      case 'Cancelled':
      case 'Declined':
        return 'callout-danger';
      default:
        return 'callout-info';
    }
  }
}
