<section class="container-fluid content-area">

  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fas fa-book"></i> Tenders
        </div>
        <div class="card-body">

          <div class="row">
            <div class="col-2 form-group my-auto">
              <label for="fromDate" class="bs-timepicker-label">From:</label>
              <div class="input-group">
                <input type="text" id="fromDate" class="input-group-text"
                       placeholder="From Date" #fromDateDp="bsDatepicker"
                       [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                       [(ngModel)]="fromDate"
                       [maxDate]="toDate"
                       bsDatepicker>

                <button class="btn btn-outline-primary input-group-append"
                        (click)="fromDateDp.toggle()" [attr.aria-expanded]="fromDateDp.isOpen">
                  <span class="fa fa-calendar" aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <div class="col-2 form-group my-auto">
              <label for="toDate" class="bs-timepicker-label">To:</label>
              <div class="input-group">
                <input type="text" id="toDate" class="input-group-text"
                       placeholder="To Date" #toDateDp="bsDatepicker"
                       [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                       [(ngModel)]="toDate"
                       [minDate]="fromDate"
                       bsDatepicker>

                <button class="btn btn-outline-primary input-group-append"
                        (click)="toDateDp.toggle()" [attr.aria-expanded]="toDateDp.isOpen">
                  <span class="fa fa-calendar" aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <div class="col-1 form-group my-auto">
              <label for="status">Status</label>
              <select class="form-control" name="status" id="status" [(ngModel)]="status">
                <option value="">All</option>
                <option value="New">New</option>
                <option value="In Progress">In Progress</option>
                <option value="Won">Won</option>
                <option value="Lost">Lost</option>
              </select>
            </div>
            <div class="col-2 my-auto btn-group">
              <button type="button" class="btn btn-primary btn-sm" (click)="search()">
                Search
              </button>
              <button type="button" class="btn btn-primary btn-sm" (click)="add()">
                Add
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">

              <table class="table table-striped table-bordered" style="margin-top: 10px">
                <thead>
                <tr>
                  <th>Number</th>
                  <th>Date Requested</th>
                  <th>Reporter</th>
                  <th>Customer</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let tender of tenders">
                  <td>{{tender.number}}</td>
                  <td>{{tender.dateRequested | date:'dd MMM yyyy'}}</td>
                  <td>{{tender.reporter}}</td>
                  <td>
                    <div class="clearfix">
                      <div class="float-left">
                        <strong>{{tender.customer}}</strong>
                      </div>
                      <div class="float-right">
                        <small class="text-muted">{{tender.customerTelephone}}</small>
                      </div>
                    </div>
                    <div class="text-sm-left">{{tender.customerAddress}}</div>
                  </td>
                  <td>{{tender.description}}</td>
                  <td>{{tender.amount | currency: 'M'}}</td>
                  <td>
                    <span [class]="statusClass(tender.status)">{{tender.status}}</span></td>
                  <td>
                    <button type="button" class="btn btn-link" title="Edit" (click)="edit(tender)">
                      <span class="fa fa-edit" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link" title="View" routerLink="/tender/view/{{tender.id}}">
                      <span class="fa fa-eye" aria-hidden="true"></span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
