import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Tender} from '../tender';

@Component({
  selector: 'app-tender-form',
  templateUrl: './tender-form.component.html',
  styleUrls: ['./tender-form.component.css']
})
export class TenderFormComponent implements OnInit {
  public tender!: Tender;
  tenderRequestDate = new Date();
  public title!: string;

  constructor(private bsModalRef: BsModalRef,
              private backendService: BackendService) {
  }

  ngOnInit(): void {
    if (this.tender.dateRequested) {
      this.tenderRequestDate = new Date(this.tender.dateRequested);
    }
  }

  save() {
    this.tender.dateRequested = this.tenderRequestDate.getTime();
    if (this.tender.id) {
      this.backendService.putSilently('tender', this.tender).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.backendService.postSilently('tender', this.tender).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  close(){
    this.bsModalRef.hide();
  }
}
