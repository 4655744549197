import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Supplier} from '../supplier';

@Component({
  selector: 'app-supplier-form',
  templateUrl: './supplier-form.component.html'
})
export class SupplierFormComponent implements OnInit {
  public supplier!: Supplier;
  public title!: string;

  constructor(private bsModalRef: BsModalRef,
              private backendService: BackendService) {
  }

  ngOnInit(): void {
  }

  save() {
    if (this.supplier.id) {
      this.backendService.putSilently('supplier', this.supplier).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.backendService.postSilently('supplier', this.supplier).then((res: any) => {
        if (res.id) {
          this.supplier = res;
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  close(){
    this.bsModalRef.hide();
  }
}
