import {Component, Input, OnInit} from '@angular/core';
import {BackendService} from '../util/backend.service';
import {EntityDocument} from './entityDocument';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html'
})
export class DocumentsComponent implements OnInit {

  @Input() requestPath!: string;
  documents!: EntityDocument[];

  constructor(private backendService: BackendService) {
  }

  async ngOnInit() {
    await this.loadDocuments();
  }

  async loadDocuments() {
    this.documents = await this.backendService.get(this.requestPath);
  }

  download(document: EntityDocument) {
    this.backendService.downloadAndSave('document/' + document.documentId, document.filename);
  }
}
