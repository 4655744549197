<section class="container content-area">
  <div class="row">
    <div class="col-sm-12">
      <button type="button" class="btn btn-secondary mb-2"
              routerLink="/quotations">
        Back
      </button>
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-9">
              <h3 class="card-title">{{title}}</h3>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="quotation">
          <div class="row">

            <div class="col-md-6 vertical-line-separator">
              <div class="row">
                <div class="col-md-6"><h6>Number</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{quotation.number}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Date</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{quotation.date | date : 'dd MMM yyyy'}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Company</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{quotation.company}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Company VAT Number</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{quotation.companyVatNumber}}</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6"><h6>Status</h6></div>
                <div class="col-md-6">
                  <p class="card-text {{statusClass(quotation.status)}}">{{quotation.status}}</p>
                  <p class="card-text">{{quotation.statusComment}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Expiry Date</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{quotation.expiryDate | date : 'dd MMM yyyy'}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Contact Person</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{quotation.contactPerson}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Telephone</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{quotation.contactNumber}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"><h6>Email</h6></div>
                <div class="col-md-6">
                  <p class="card-text">{{quotation.contactEmail}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <tabset>
                <tab heading="Items">
                  <div class="col" *ngIf="quotation.items">
                    <h4>Quotation Items</h4>
                    <table class="table table-striped table-bordered">
                      <caption>Items to quote</caption>
                      <thead>
                      <tr>
                        <th>Code</th>
                        <th>Supplier</th>
                        <th>Description</th>
                        <th>Unit Cost Price</th>
                        <th>Unit Price</th>
                        <th>Markup</th>
                        <th>Quantity</th>
                        <th>Price</th>
                      </tr>
                      </thead>
                      <tbody *ngIf="quotation.items">
                      <tr *ngFor="let item of quotation.items">
                        <td>{{item.code}}</td>
                        <td>{{item.supplier}}</td>
                        <td>{{item.description}}</td>
                        <td class="text-right">{{item.unitCostPrice | currency : 'LSL '  : 'symbol-narrow'}}</td>
                        <td class="text-right">{{item.unitPrice | currency : 'LSL '  : 'symbol-narrow'}}</td>
                        <td class="text-right {{belowThreshold(item.markup)}}">{{item.markup | percent : '1.0'}}</td>
                        <td class="text-right">{{item.quantity | number}}</td>
                        <td
                          class="text-right">{{(item.unitPrice * item.quantity) | currency : 'LSL '  : 'symbol-narrow'}}</td>
                      </tr>
                      </tbody>
                      <tfoot>
                      <tr>
                        <td colspan="7" class="text-right">Total excluding VAT</td>
                        <td
                          class="text-right">{{quotation.totalExcludingVat  | currency : 'LSL '  : 'symbol-narrow'}}</td>
                      </tr>
                      <tr>
                        <td colspan="7" class="text-right">VAT</td>
                        <td class="text-right">{{quotation.totalVat  | currency : 'LSL '  : 'symbol-narrow'}}</td>
                      </tr>
                      <tr>
                        <td colspan="7" class="text-right">Total Including VAT</td>
                        <td
                          class="text-right">{{quotation.totalIncludingVat  | currency : 'LSL '  : 'symbol-narrow'}}</td>
                      </tr>
                      </tfoot>
                    </table>
                  </div>
                </tab>
                <tab heading="Documents">
                  <div *ngIf="quotation.id">
                    <app-documents #quotationDocuments
                                   [requestPath]="'quotation/documents/' + quotation.id"></app-documents>
                  </div>
                </tab>
                <tab heading="History">
                  <div *ngIf="quotation.id">
                    <app-audit #quotationAuditLogs [requestPath]="'quotation/audit/' + quotation.id"></app-audit>
                  </div>
                </tab>
              </tabset>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-7">
            </div>
            <div class="col-5 m-auto text-right">
              <div class="btn-group-sm">
                <button type="button" class="btn-sm  btn-primary"
                        (click)="submitForApproval()"
                        *ngIf="quotation.status === 'Draft'"
                        [disabled]="!quotation.items || quotation.items.length <= 0 ||  quotation.status !== 'Draft'">
                  Submit for Approval
                </button>
                <button type="button" class="btn-sm  btn-primary"
                        (click)="approve()"
                        *ngIf="quotation.status === 'Awaiting Approval'"
                        [disabled]="!quotation.items || quotation.items.length <= 0 ||  quotation.status !== 'Awaiting Approval'">
                  Approve
                </button>
                <button type="button" class="btn-sm  btn-danger"
                        *ngIf="quotation.status === 'Awaiting Approval'"
                        (click)="decline()"
                        [disabled]="!quotation.items || quotation.items.length <= 0 ||  quotation.status !== 'Awaiting Approval'">
                  Decline
                </button>
                <button type="button" class="btn-sm  btn-success"
                        *ngIf="quotation.status === 'Approved'"
                        (click)="accept()"
                        [disabled]="!quotation.items || quotation.items.length <= 0 ||  quotation.status !== 'Approved'">
                  Accept
                </button>
                <button type="button" class="btn-sm  btn-danger"
                        (click)="reject()"
                        *ngIf="quotation.status === 'Approved'"
                        [disabled]="!quotation.items || quotation.items.length <= 0 ||  quotation.status !== 'Approved'">
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
