<c-header *ngIf="loggedOnUser" position="sticky">
  <c-container class="justify-content-normal" fluid>
    <c-header-brand [routerLink]>
      <img src="assets/images/logo.png" width="150" height="38" alt="Comnet Lesotho Logo">
    </c-header-brand>
    <c-header-nav class="nav navbar-nav d-md-down-none">
      <ng-container *ngFor="let menuItem of menuItems">
        <c-nav-item *ngIf="menuItem.show && !menuItem.items">
          <a class="nav-link" [routerLink]="menuItem.link">
            {{ menuItem.text }}
          </a>
        </c-nav-item>

        <c-dropdown *ngIf="menuItem.show && menuItem.items">

          <a cDropdownToggle cNavLink [caret]="false">
            {{ menuItem.text }}
          </a>
          <div id="dropdown-basic" cDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <ng-container *ngFor="let submenuItem of menuItem.items" role="menuitem">
              <div *ngIf="submenuItem.show && submenuItem.separator" class="dropdown-header text-center">
                <strong>{{submenuItem.text}}</strong>
              </div>
              <a
                *ngIf="submenuItem.show && !submenuItem.separator"
                class="dropdown-item"
                [routerLink]="submenuItem.link"
              >
                {{ submenuItem.text }}
              </a>
            </ng-container>
          </div>
        </c-dropdown>
      </ng-container>
    </c-header-nav>
    <c-header-nav class="right-align" >
      <c-dropdown class="nav-item dropdown" dropdown>
        <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
           cDropdownToggle (click)="false">
          <span *ngIf="!loggedOnUser.photoUrl">
              {{ loggedOnUser.name}}
            <span class="fas fa-user-circle fa-2x" aria-hidden="true"></span>
          </span>
          <img *ngIf="loggedOnUser.photoUrl" src="{{ loggedOnUser.photoUrl }}" title="{{loggedOnUser.name}}"
               alt="{{loggedOnUser.name}}" class="img-avatar" [alt]="loggedOnUser.name"
               width="30" height="30">
        </a>
        <div class="dropdown-menu dropdown-menu-right" cDropdownMenu aria-labelledby="simple-dropdown">
          <div class="dropdown-header text-center"><strong>Settings</strong></div>
          <a class="dropdown-item" (click)="editProfile()"><i class="fa fa-user"></i> Profile</a>
          <div class="divider"></div>
          <a class="dropdown-item" (click)="logout()"><i class="fa fa-lock"></i> Logout</a>
        </div>
      </c-dropdown>
    </c-header-nav>
  </c-container>
</c-header>

<c-container class="app-body vh-100">
  <main class="main">
    <div *ngIf="busy" class="overlay">
      <span>Please Wait</span>
    </div>

    <div class="container-fluid pt-2">
      <router-outlet></router-outlet>
    </div>
  </main>
</c-container>
<c-footer id="footer" position="fixed">

  <span><a href="https://www.comnet.co.ls">OfficeOps</a> &copy; 2021 Comnet Lesotho.</span>
  <span class="ml-auto">{{ VERSION.commit.substring(0, 7) }} | {{ VERSION.branch }} | {{ VERSION.date }}</span>
</c-footer>
