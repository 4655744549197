import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './auth/login/login.component';
import {IconModule, IconSetModule, IconSetService} from '@coreui/icons-angular';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {MessageModalComponent} from './util/message-modal/message-modal.component';
import {ConfirmModalComponent} from './util/confirm-modal/confirm-modal.component';
import {EmployeeListComponent} from './employee/employee-list/employee-list.component';
import {EmployeeFormComponent} from './employee/employee-form/employee-form.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TenderListComponent} from './tender/tender-list/tender-list.component';
import {TenderFormComponent} from './tender/tender-form/tender-form.component';
import {TenderViewComponent} from './tender/tender-view/tender-view.component';
import {DocumentUploadModalComponent} from './document/document-upload-modal.component';
import {WaybillViewComponent} from './waybill/waybill-view/waybill-view.component';
import {WaybillFormComponent} from './waybill/waybill-form/waybill-form.component';
import {WaybillListComponent} from './waybill/waybill-list/waybill-list.component';
import {DocumentsComponent} from './document/documents.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {CommentModalComponent} from './util/comment-modal/comment-modal.component';
import {TabsetConfig, TabsModule} from 'ngx-bootstrap/tabs';
import {AuditComponent} from './audit/audit.component';
import {CommentComponent} from './comment/comment.component';
import {SupplierListComponent} from './supplier/supplier-list/supplier-list.component';
import {SupplierFormComponent} from './supplier/supplier-form/supplier-form.component';
import {PurchaseOrderFormComponent} from './purchase-order/purchase-order-form/purchase-order-form.component';
import {PurchaseOrderListComponent} from './purchase-order/purchase-order-list/purchase-order-list.component';
import {PurchaseOrderViewComponent} from './purchase-order/purchase-order-view/purchase-order-view.component';
import {
  PurchaseOrderItemFormComponent
} from './purchase-order/purchase-order-item-form/purchase-order-item-form.component';
import {RoomListComponent} from './room/room-list/room-list.component';
import {RoomFormComponent} from './room/room-form/room-form.component';
import {StockListComponent} from './stock/stock-list/stock-list.component';
import {StockFormComponent} from './stock/stock-form/stock-form.component';
import {StockReceiveComponent} from './stock/stock-receive/stock-receive.component';
import {StockViewComponent} from "./stock/stock-view/stock-view.component";
import {NgxValidatorModule} from "ngx-validator";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {VehicleListComponent} from "./fleet/vehicle-list/vehicle-list.component";
import {VehicleFormComponent} from "./fleet/vehicle-form/vehicle-form.component";
import {DeliveryNoteListComponent} from "./delivery-note/delivery-note-list/delivery-note-list.component";
import {DeliveryNoteFormComponent} from "./delivery-note/delivery-note-form/delivery-note-form.component";
import {QuotationListComponent} from "./quotation/quotation-list/quotation-list.component";
import {QuotationFormComponent} from "./quotation/quotatin-form/quotation-form.component";
import {QuotationItemFormComponent} from "./quotation/quotation-item-form/quotation-item-form.component";
import {QuotationViewComponent} from "./quotation/quotatin-view/quotation-view.component";
import {ProjectListComponent} from "./project/project-list/project-list.component";
import {ProjectFormComponent} from "./project/project-form/project-form.component";
import {TimesheetViewComponent} from './timesheet/timesheet-view/timesheet-view.component';
import {DatePipe} from "@angular/common";
import {TimesheetDashboardComponent} from './timesheet/timesheet-dashboard/timesheet-dashboard.component';
import {NgChartsModule} from 'ng2-charts';
import {AgmCoreModule} from "@agm/core";
import {RouterModule} from "@angular/router";
import {DropdownModule, FooterModule, GridModule, HeaderModule, NavModule} from "@coreui/angular";
import {NgxPrinterModule} from "ngx-printer";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {OrderListComponent} from "./order/order-list/order-list.component";
import {OrderFormComponent} from "./order/order-form/order-form.component";
import {OrderViewComponent} from "./order/order-view/order-view.component";
import { TaskListComponent } from './timesheet/task-list/task-list.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MessageModalComponent,
    ConfirmModalComponent,
    HomeComponent,
    LoginComponent,
    EmployeeListComponent,
    EmployeeFormComponent,
    TenderListComponent,
    TenderFormComponent,
    TenderViewComponent,
    DocumentUploadModalComponent,
    DocumentsComponent,
    WaybillViewComponent,
    WaybillFormComponent,
    WaybillListComponent,
    AuditComponent,
    PrivacyComponent,
    CommentModalComponent,
    CommentComponent,
    SupplierListComponent,
    SupplierFormComponent,
    PurchaseOrderFormComponent,
    PurchaseOrderListComponent,
    PurchaseOrderViewComponent,
    PurchaseOrderItemFormComponent,
    RoomListComponent,
    RoomFormComponent,
    StockListComponent,
    StockFormComponent,
    StockReceiveComponent,
    StockViewComponent,
    VehicleListComponent,
    VehicleFormComponent,
    DeliveryNoteListComponent,
    DeliveryNoteFormComponent,
    QuotationListComponent,
    QuotationFormComponent,
    QuotationItemFormComponent,
    QuotationViewComponent,
    ProjectListComponent,
    ProjectFormComponent,
    TimesheetViewComponent,
    TimesheetDashboardComponent,
    OrderListComponent,
    OrderFormComponent,
    OrderViewComponent,
    TaskListComponent
  ],
  imports: [
    BrowserModule, RouterModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxValidatorModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    IconModule,
    TabsModule,
    NgChartsModule,
    IconSetModule.forRoot(),
    FontAwesomeModule,
    NgxExtendedPdfViewerModule,
    NgxPrinterModule.forRoot({printOpenWindow: true}),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }), AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDMDj5bwM5_Bjk8B6Dt29bXrqBQWzFL9NE',
      libraries: ['places', 'geometry']
    }), GridModule, FooterModule, HeaderModule, NavModule, DropdownModule
  ],
  providers: [DatePipe, IconSetService, {
    provide: LOCALE_ID,
    useValue: 'en-ZA'
  }, BsModalRef, TabsetConfig],
  bootstrap: [AppComponent],
  exports: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
