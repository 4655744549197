import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './auth/login/login.component';
import {TenderListComponent} from './tender/tender-list/tender-list.component';
import {TenderViewComponent} from './tender/tender-view/tender-view.component';
import {WaybillListComponent} from './waybill/waybill-list/waybill-list.component';
import {WaybillViewComponent} from './waybill/waybill-view/waybill-view.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {SupplierListComponent} from './supplier/supplier-list/supplier-list.component';
import {PurchaseOrderListComponent} from './purchase-order/purchase-order-list/purchase-order-list.component';
import {PurchaseOrderViewComponent} from './purchase-order/purchase-order-view/purchase-order-view.component';
import {RoomListComponent} from './room/room-list/room-list.component';
import {StockListComponent} from './stock/stock-list/stock-list.component';
import {StockReceiveComponent} from './stock/stock-receive/stock-receive.component';
import {EmployeeListComponent} from "./employee/employee-list/employee-list.component";
import {StockViewComponent} from "./stock/stock-view/stock-view.component";
import {VehicleListComponent} from "./fleet/vehicle-list/vehicle-list.component";
import {DeliveryNoteListComponent} from "./delivery-note/delivery-note-list/delivery-note-list.component";
import {DeliveryNoteFormComponent} from "./delivery-note/delivery-note-form/delivery-note-form.component";
import {QuotationListComponent} from "./quotation/quotation-list/quotation-list.component";
import {QuotationFormComponent} from "./quotation/quotatin-form/quotation-form.component";
import {QuotationViewComponent} from "./quotation/quotatin-view/quotation-view.component";
import {ProjectListComponent} from "./project/project-list/project-list.component";
import {TimesheetViewComponent} from "./timesheet/timesheet-view/timesheet-view.component";
import { TimesheetDashboardComponent } from './timesheet/timesheet-dashboard/timesheet-dashboard.component';
import {OrderListComponent} from "./order/order-list/order-list.component";
import {OrderViewComponent} from "./order/order-view/order-view.component";
import {OrderFormComponent} from "./order/order-form/order-form.component";
import {TaskListComponent} from "./timesheet/task-list/task-list.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'login', component: LoginComponent},
  {path: 'users', component: EmployeeListComponent},
  {path: 'tenders', component: TenderListComponent},
  {path: 'tenders/:status', component: TenderListComponent},
  {path: 'tender/view/:id', component: TenderViewComponent},
  {path: 'waybills', component: WaybillListComponent},
  {path: 'waybills/:status', component: WaybillListComponent},
  {path: 'waybill/view/:id', component: WaybillViewComponent},
  {path: 'purchase-orders', component: PurchaseOrderListComponent},
  {path: 'purchase-orders/:reason', component: PurchaseOrderListComponent},
  {path: 'purchase-orders/:reason/:status', component: PurchaseOrderListComponent},
  {path: 'purchase-order/view/:id', component: PurchaseOrderViewComponent},
  {path: 'suppliers', component: SupplierListComponent},
  {path: 'rooms', component: RoomListComponent},
  {path: 'stock', component: StockListComponent},
  {path: 'stock/receive', component: StockReceiveComponent},
  {path: 'stock/receive/:id', component: StockReceiveComponent},
  {path: 'stock/view/:id', component: StockViewComponent},
  {path: 'delivery-note', component: DeliveryNoteListComponent},
  {path: 'delivery-note/form', component: DeliveryNoteFormComponent},
  {path: 'delivery-note/form/:id', component: DeliveryNoteFormComponent},
  {path: 'vehicle', component: VehicleListComponent},
  {path: 'privacy', component: PrivacyComponent},
  {path: 'quotations', component: QuotationListComponent},
  {path: 'quotation/form', component: QuotationFormComponent},
  {path: 'quotation/form/:id', component: QuotationFormComponent},
  {path: 'quotation/view/:id', component: QuotationViewComponent},
  {path: 'projects', component: ProjectListComponent},
  {path: 'timesheet', component: TimesheetViewComponent},
  {path: 'timesheet/dashboard', component: TimesheetDashboardComponent},
  {path: 'timesheet/tasks', component: TaskListComponent},
  {
    path: 'service-desk',
    loadChildren: () => import('./service-desk/service-desk.module').then(m => m.ServiceDeskModule)
  },
  {
    path: 'cash',
    loadChildren: () => import('./cash/cash.module').then(m => m.CashModule)
  }, {
    path: 'orders',
    component: OrderListComponent
  },
  {
    path: 'orders/:id',
    component: OrderViewComponent
  },
  {
    path: 'orders/form',
    component: OrderFormComponent
  },
  {
    path: 'orders/form/:id',
    component: OrderFormComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
