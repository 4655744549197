<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body bg-light">

  <form #itemForm="ngForm" (ngSubmit)="save()" *ngIf="item">

    <div class="form-group row">
      <div class="col-4">
        <label>Supplier</label>
      </div>
      <div class="col-6">
        <div class="input-group">
          <select class="form-control" name="supplier" [(ngModel)]="item.supplierId" required>
            <option *ngFor="let supplier of suppliers" [value]="supplier.id">{{supplier.name}}</option>
          </select>
          <div class="input-group-append">
            <button type="button" class="btn btn-link" title="Add Supplier"
                    (click)="addSupplier()">
              <span class="fa fa-plus-circle" aria-hidden="true"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">
        <label >Code</label>
      </div>
      <div class="col-6">
        <input name="code"
               [disabled]="quotation.status === 'Invoiced'"
               [(ngModel)]="item.code" stretch="true">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">
        <label for="description" class="required">Description</label>
      </div>
      <div class="col-6">
        <input name="description" id="description"
               [disabled]="quotation.status === 'Invoiced'"
               [(ngModel)]="item.description" stretch="true" required>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">
        <label for="quantity" class="required">Quantity</label>
      </div>
      <div class="col-6">
        <input name="quantity" id="quantity"
               [disabled]="quotation.status === 'Invoiced'"
               [(ngModel)]="item.quantity" type="number" stretch="true" required>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">
        <label for="unitCostPrice">Unit Cost Price</label>
      </div>
      <div class="col-6">
        <input name="unitCostPrice" id="unitCostPrice"
               [disabled]="quotation.status === 'Invoiced'"
               [(ngModel)]="item.unitCostPrice" type="number" stretch="true">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">
        <label for="unitPrice" class="required">Unit Sell Price</label>
      </div>
      <div class="col-6">
        <input name="unitPrice" id="unitPrice"
               [disabled]="quotation.status === 'Invoiced'"
               [(ngModel)]="item.unitPrice" type="number" stretch="true" required>
      </div>
    </div>
    <div class="form-group row"
         *ngIf="quotation.status === 'Invoiced' && authService.hasAuthority('Purchase Order Manager')">
      <div class="col-4">
        <label>Invoiced Amount</label>
      </div>
      <div class="col-6">
        <input name="actualPrice"
               [(ngModel)]="item.unitCostPrice" type="number" stretch="true" required>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!itemForm.valid">
        Save
      </button>

      <button type="button" (click)="close()" class="btn btn-secondary left mr-1">
        Cancel
      </button>
    </div>
  </form>
</div>