<section class="container content-area">
  <div class="row">
    <div class="col-sm-12">
      <button type="button" class="btn btn-sm btn-secondary mb-2"
              routerLink="/stock">
        Back
      </button>
      <div class="card bg-light" *ngIf="stock">
        <div class="card-header">
          <div class="row ">
            <div class="col">
              <h5 class="card-title text-center">
                {{stock.quantity}} {{stock.productName}} available in {{stock.room}}
              </h5>
            </div>
          </div>
        </div>
        <div class="row card-body">
          <table class="table table-striped table-bordered" style="margin-top: 10px">
            <thead>
            <tr>
              <th>Date</th>
              <th>Controller</th>
              <th>Type</th>
              <th>Status</th>
              <th>Total Quantity</th>
            </tr>
            </thead>
            <tbody *ngIf="transactions">
            <tr *ngFor="let transaction of transactions">
              <td>{{transaction.date | date : 'dd MMM yyyy'}}</td>
              <td>{{transaction.controller}}</td>
              <td>{{transaction.type}}</td>
              <td>{{transaction.status}}</td>
              <td class="text-right">{{transaction.quantity | number}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>