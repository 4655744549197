import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {AuthService} from '../auth/auth.service';
import {ModalService} from './modal.service';
import {Observable} from 'rxjs';
import {saveAs} from 'file-saver';

export interface BackendOptions {
  headers?: { [header: string]: string | string[] };
  params?: { [param: string]: string | string[] };
}

@Injectable({ providedIn: 'root' })
export class BackendService {
  busyCount = 0;

  constructor(private http: HttpClient, private authService: AuthService, private modalService: ModalService) {}

  get busy() {
    return this.busyCount > 0;
  }


  private addToken(options?: BackendOptions) {
    if (!options) {
      options = {};
    }
    if (!options.headers) {
      options.headers = {
        Authorization: `Bearer ${this.authService.getToken()}`
      };
    }
    return options;
  }

  public async get<T>(
    path: string,
    parameters?: { [param: string]: string | string[] }
  ): Promise<T> {
    await Promise.resolve(); // prevent ExpressionChangedAfterItHasBeenCheckedError
    this.busyCount++;

    try {
      let options = {params: parameters} as BackendOptions;
      options = this.addToken(options);
      return await this.http
        .get<T>(environment.apiUrl + path, options)
        .toPromise();
    } catch (e) {
      this.handleError(e);
      throw e; // Rethrow to abort the caller
    } finally {
      this.busyCount--;
    }
  }

  public async downloadAndSave(
    path: string,
    filename,
    parameters?: { [param: string]: string | string[] }
  ){
    this.busyCount++;

    try {
      let options = {params: parameters,
        observe: 'response',
        responseType: 'blob' as 'json' } as BackendOptions;
      options = this.addToken(options);
      await this.http
        .get<HttpResponse<Blob>>(environment.apiUrl + path, options)
        .subscribe( (response: HttpResponse<Blob> ) => {
          const responseHeaders = response.headers;
          console.log(responseHeaders); // <--- Check log for content disposition
          const contentDisposition = responseHeaders.get('content-disposition');
          saveAs(response.body, filename);
        }, errorMsg => {
          console.error(errorMsg);
        });
    } catch (e) {
      this.handleError(e);
      throw e; // Rethrow to abort the caller
    } finally {
      this.busyCount--;
    }
  }

  public async post<T>(
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    return this.postInternal(true, path, body, options);
  }

  public async postSilently<T>(
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    return this.postInternal(false, path, body, options);
  }
  public async deleteSilently<T>(
    path: string,
    options?: BackendOptions
  ): Promise<T> {
    return this.deleteInternal(false, path, options);
  }

  private async deleteInternal<T>(
    displaySuccess: boolean,
    path: string,
    options?: BackendOptions
  ): Promise<T> {
    await Promise.resolve(); // prevent ExpressionChangedAfterItHasBeenCheckedError
    this.busyCount++;

    try {
      options = this.addToken(options);
      const response = await this.http
        .delete<T>(environment.apiUrl + path, options)
        .toPromise();

      if (displaySuccess) {
        this.displaySuccess('Successfully completed');
      }
      return response;
    } catch (e) {
      this.handleError(e);
      throw e; // Rethrow to abort the caller
    } finally {
      this.busyCount--;
    }
  }

  private async postInternal<T>(
    displaySuccess: boolean,
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    await Promise.resolve(); // prevent ExpressionChangedAfterItHasBeenCheckedError
    this.busyCount++;

    try {
      options = this.addToken(options);
      const response = await this.http
        .post<T>(environment.apiUrl + path, body, options)
        .toPromise();

      if (displaySuccess) {
        this.displaySuccess('Successfully completed');
      }
      return response;
    } catch (e) {
      this.handleError(e);
      throw e; // Rethrow to abort the caller
    } finally {
      this.busyCount--;
    }
  }

  public async put<T>(
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    return this.putInternal(true, path, body, options);
  }

  public async putSilently<T>(
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    return this.putInternal(false, path, body, options);
  }

  private async putInternal<T>(
    displaySuccess: boolean,
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    await Promise.resolve(); // prevent ExpressionChangedAfterItHasBeenCheckedError
    this.busyCount++;

    try {
      options = this.addToken(options);
      const response = await this.http
        .put<T>(environment.apiUrl + path, body, options)
        .toPromise();

      if (displaySuccess) {
        this.displaySuccess('Successfully completed');
      }
      return response;
    } catch (e) {
      this.handleError(e);
      throw e; // Rethrow to abort the caller
    } finally {
      this.busyCount--;
    }
  }

  public async patch<T>(
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    return this.patchInternal(true, path, body, options);
  }

  public async patchSilently<T>(
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    return this.patchInternal(false, path, body, options);
  }

  private async patchInternal<T>(
    displaySuccess: boolean,
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    await Promise.resolve(); // prevent ExpressionChangedAfterItHasBeenCheckedError
    this.busyCount++;

    try {
      const response = await this.http
        .patch<T>(environment.apiUrl + path, body, options)
        .toPromise();

      if (displaySuccess) {
        this.displaySuccess('Successfully completed');
      }
      return response;
    } catch (e) {
      this.handleError(e);
      throw e; // Rethrow to abort the caller
    } finally {
      this.busyCount--;
    }
  }

  private handleError(e: Error) {
    if (e instanceof HttpErrorResponse && e.status === 401) {
      // User has been logged out, refresh at login screen
      this.authService.logout();
      window.location.reload();
    } else {
      this.displayError(e);
    }
  }

  private displaySuccess(message: string) {
    this.modalService.info(message);
  }

  private displayError(e: Error) {
    this.modalService.error(e);
  }

  public async downloadFilePostExportWithFileName(path: string, data: any, filename: string, exportType: string) {
    await Promise.resolve(); // prevent ExpressionChangedAfterItHasBeenCheckedError
    this.busyCount++;

    try {
      let token = this.authService.getToken();
      const response = await this.http.post(
        `${environment.apiUrl}${path}`,
        data,
        {
          responseType: 'blob',
          headers: new HttpHeaders().append('Content-Type', 'application/json').append(
            'Authorization', `Bearer ${token}`)
        }
      ).toPromise();

      if (exportType.toLowerCase() == 'pdf') {
        this.openPdf(response);
      }
      else {
        this.openExcel(response, filename);
      }

    }
    catch (e) {
      this.handleError(e);
    } finally {
      this.busyCount--;
    }
  }
  private openPdf(response: Blob, fileName?: string) {
    const newBlob = new Blob([response], { type: "application/pdf" });

    var a = document.createElement("a");
    document.body.appendChild(a);
    var csvUrl = URL.createObjectURL(newBlob);
    a.href = csvUrl;
    a.target = "_blank";

    if (fileName !== null)
      a.download = fileName;

    a.click();
    URL.revokeObjectURL(a.href)
    a.remove();
  }


  private openExcel(response: Blob, fileName?: string) {
    const newBlob = new Blob([response], { type: "application/vnd.ms-excel" });

    var a = document.createElement("a");
    document.body.appendChild(a);
    var csvUrl = URL.createObjectURL(newBlob);
    a.href = csvUrl;
    a.target = "_blank";

    if (fileName !== null)
      a.download = fileName;

    a.click();
    URL.revokeObjectURL(a.href)
    a.remove();
  }


}
