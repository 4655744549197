<section class="container-fluid content-area">

  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fas fa-book"></i> {{reason}} Purchase Orders
        </div>
        <div class="card-body">

          <div class="row">
            
            <div class="col-3 form-group my-auto">
              <label for="fromDate" class="bs-timepicker-label">From:</label>
              <div class="input-group">
                <input type="text" id="fromDate" class="input-group-text"
                       placeholder="From Date" #fromDateDp="bsDatepicker"
                       [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                       [(ngModel)]="fromDate"
                       [maxDate]="toDate"
                       bsDatepicker>

                <button class="btn btn-outline-primary input-group-append" 
                        (click)="fromDateDp.toggle()" [attr.aria-expanded]="fromDateDp.isOpen">                  
                  <span class="fa fa-calendar" aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <div class="col-3 form-group my-auto">
              <label for="toDate" class="bs-timepicker-label">To:</label>
              <div class="input-group">
                <input type="text" id="toDate" class="input-group-text"
                       placeholder="To Date" #toDateDp="bsDatepicker"
                       [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                       [(ngModel)]="toDate"
                       [minDate]="fromDate"
                       bsDatepicker>

                <button class="btn btn-outline-primary input-group-append" 
                        (click)="toDateDp.toggle()" [attr.aria-expanded]="toDateDp.isOpen">                  
                  <span class="fa fa-calendar" aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <div class="col-1 form-group my-auto">
              <label for="status">Status</label>
              <select class="form-control" name="status" id="status" [(ngModel)]="status">
                <option value="">All</option>
                <option value="New">New</option>
                <option value="Awaiting Approval">Awaiting Approval</option>
                <option value="Approved">Approved</option>
                <option value="Declined">Declined</option>
                <option value="Invoice">Invoiced</option>
              </select>
            </div>
            <div class="col-4 my-auto btn-group">

              <button type="button" class="btn btn-primary btn-sm mr-2" (click)="search()">
                Search
              </button>
              <button type="button" class="btn btn-primary btn-sm mr-2" (click)="exportToExcel()">
                Export to CSV
              </button>
              <button type="button" class="btn btn-primary btn-sm" (click)="add()"
                      *ngIf="authService.hasAuthority('Purchase Order Administrator')">
                Add
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <table class="table table-striped table-bordered" style="margin-top: 10px">
                <thead>
                <tr>
                  <th>Number</th>
                  <th>Date</th>
                  <th>Reason</th>
                  <th>Amount</th>
                  <th>Invoice #</th>
                  <th>Invoice Date</th>
                  <th>Invoice Amount</th>
                  <th>Supplier</th>
                  <th *ngIf="reason === 'Customer Order'">Customer</th>
                  <th>Created By</th>
                  <th>Status By</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody *ngIf="purchaseOrders">
                <tr *ngFor="let purchaseOrder of purchaseOrders">
                  <td>{{purchaseOrder.number}}</td>
                  <td>{{purchaseOrder.date | date : 'dd MMM yyyy'}}</td>
                  <td>                    
                    <div class="clearfix">
                      <div class="float-left">
                        <strong>{{purchaseOrder.reason}}</strong>
                      </div>
                      <div class="float-right">
                        <small class="text-muted">{{purchaseOrder.department}}</small>
                      </div>
                    </div>
                    <div class="text-sm-left">{{purchaseOrder.purchaseReason}}</div>
                    <div class="clearfix">
                      <div class="float-left">
                        <strong>{{purchaseOrder.vehicleRegistrationNumber}}</strong>
                      </div>
                      <div class="float-right">
                        <small class="text-muted">{{purchaseOrder.driver}}</small>
                      </div>
                    </div>
                  </td>
                  <td>{{purchaseOrder.amount | currency: purchaseOrder.currencyCode : 'symbol-narrow'}}</td>
                  <td>{{purchaseOrder.invoiceNumber}}</td>
                  <td><span *ngIf="purchaseOrder.invoiceDate > 0">{{purchaseOrder.invoiceDate | date : 'dd MMM yyyy'}}</span></td>
                  <td>{{purchaseOrder.actualAmount | currency: purchaseOrder.currencyCode : 'symbol-narrow'}}</td>
                  <td>
                    <div class="clearfix">
                      <div class="float-left">
                        <strong>{{purchaseOrder.supplier}}</strong>
                      </div>
                      <div class="float-right">
                        <small class="text-muted">{{purchaseOrder.supplierTelephone}}</small>
                      </div>
                    </div>
                    <div class="text-sm-left">{{purchaseOrder.supplierEmail}}</div>
                    <div class="clearfix">
                      <div class="float-left">
                        <strong>{{purchaseOrder.supplierQuotationNumber}}</strong>
                      </div>
                      <div class="float-right">
                        <small class="text-muted">{{purchaseOrder.supplierQuotationDate | date: 'dd MMM yyyy' }}</small>
                      </div>
                    </div>
                  </td>
                  <td  *ngIf="reason === 'Customer Order'">
                    <div class="clearfix">
                      <div class="float-left">
                        <strong>{{purchaseOrder.customer}}</strong>
                      </div>
                      <div class="float-right">
                        <small class="text-muted">{{purchaseOrder.customerTelephone}}</small>
                      </div>
                    </div>
                    <div class="text-sm-left">{{purchaseOrder.customerEmail}}</div>
                  </td>
                  <td>{{purchaseOrder.creator}} on {{purchaseOrder.dateTimeInserted | date : 'dd MMM yyyy HH:mm'}}</td>
                  <td>{{purchaseOrder.statusBy}} on {{purchaseOrder.dateTimeStatus | date : 'dd MMM yyyy HH:mm'}}</td>
                  <td>
                    <span [class]="statusClass(purchaseOrder.status)">{{purchaseOrder.status}}</span>
                    <p>{{purchaseOrder.statusComment}}</p>
                  </td>
                  <td>
                    <button type="button" class="btn btn-link btn-icon" title="Edit"
                            *ngIf="purchaseOrder.status === 'New' || purchaseOrder.status === 'In Progress' "
                            (click)="edit(purchaseOrder)">
                      <span class="fa fa-edit" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link btn-icon" title="View"
                            routerLink="/purchase-order/view/{{purchaseOrder.id}}">
                      <span class="fa fa-eye" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link btn-icon" title="Download"
                            *ngIf="purchaseOrder.status === 'Approved' || purchaseOrder.status === 'Invoiced'"
                            (click)="download(purchaseOrder)">
                      <span class="fa fa-download" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link btn-icon" title="Receive Stock"
                            *ngIf="purchaseOrder.status === 'Approved'"
                            routerLink="/stock/receive/{{purchaseOrder.id}}">
                      <span class="fa fa-check-square" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link btn-icon" title="Move to in progress"
                            *ngIf="purchaseOrder.status === 'Approved'"
                            (click)="moveToInProgress(purchaseOrder)" >
                      <span class="fa fa-recycle" aria-hidden="true"></span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
