import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {ActivatedRoute, Router} from "@angular/router";
import {DeliveryNote} from "../delivery-note";
import {Product} from "../../stock/product";
import {StockTransactionItem} from "../../stock/stock-transaction-item";
import {Stock} from "../../stock/stock";
import {Employee} from "../../employee/employee";
import {StockTransaction} from "../../stock/stock-transaction";

@Component({
  selector: 'app-delivery-note-form',
  templateUrl: './delivery-note-form.component.html'
})
export class DeliveryNoteFormComponent implements OnInit {
  deliveryNote = { } as DeliveryNote;
  errorMessage!: string;
  stocks!: Stock[];
  technicians!: Employee[];
  noStockMessage = 'Search for stock to dispatch by product name.';
  searchProduct!: string;
  items!: StockTransactionItem[];
  deliveryDate = new Date();

  constructor(private backendService: BackendService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  async ngOnInit() {
    const deliveryNoteId = this.activatedRoute.snapshot.params.id;
    this.items = [];
    if (deliveryNoteId) {
      this.deliveryNote = await this.backendService.get(`deliveryNote/${deliveryNoteId}`);
      this.deliveryDate = new Date(this.deliveryNote.date);
      if(this.deliveryNote.stockTransaction && this.deliveryNote.stockTransaction.items) {
        this.items = this.deliveryNote.stockTransaction.items;
      }
    }
    this.technicians = await this.backendService.get('employee');
  }
  
  dispatch() {
    this.deliveryNote.date = this.deliveryDate.getTime();
    this.deliveryNote.stockTransaction = {
      type: 'Outgoing',
      items: this.items
    } as StockTransaction
    this.backendService.post('deliveryNote', this.deliveryNote) .then(successResult => {
      this.router.navigate(['delivery-note']);
    }, error => {
      this.errorMessage = error;
    });
  }

  statusClass(status: string) {
    switch (status) {
      case 'Dispatched':
        return 'badge badge-warning';
      case 'Delivered':
        return 'badge badge-success';
      case 'Accepted':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }

  async search() {
    if(this.searchProduct) {
      this.stocks = await this.backendService.postSilently<Stock[]>('stockBalance/search/', 
        {name: this.searchProduct});
    }
  }

  addForDispatch(stock: Stock) {
    let stockTransactionItem = this.items.find(s => s.stockBalanceId === stock.id);
    if(stockTransactionItem) {
      stockTransactionItem.quantity += stock.dispatchQuantity;
    } else {
      stockTransactionItem = DeliveryNoteFormComponent.buildItem(stock);
      this.items.push(stockTransactionItem);      
    }
    stock.quantity -= stock.dispatchQuantity;
    stock.dispatchQuantity = 0;
  }

  private static buildItem(stock: Stock) {
      let stockItem: StockTransactionItem;
      stockItem = {
        roomId: stock.roomId,
        room: stock.room,
        productId: stock.productId,
        productCode: stock.productCode,
        product: stock.productName,
        unitPrice: stock.unitPrice,
        stockBalanceId: stock.id,
        quantity: stock.dispatchQuantity
      } as StockTransactionItem;
      return stockItem;
    }

  remove(stock: StockTransactionItem) {
    this.items = this.items.filter(s => s !== stock);
    const stockBalance = this.stocks.find(s => s.id === stock.stockBalanceId);
    if (stockBalance) {
      stockBalance.quantity += stock.quantity;
    }
  }
}
