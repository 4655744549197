import {Component, OnInit} from '@angular/core';
import {Employee} from "../../employee/employee";
import {BackendService} from "../../util/backend.service";
import {AuthService} from "../../auth/auth.service";
import {Project} from "../../project/project";
import {ModalService} from "../../util/modal.service";
import {TaskDuration} from "../task.duration";
import {Task} from "../task";
import {Timesheet} from "../timesheet";

@Component({
  selector: 'app-timesheet-view',
  templateUrl: './timesheet-view.component.html',
  styleUrls: ['./timesheet-view.component.css']
})
export class TimesheetViewComponent implements OnInit {
  weekDate = new Date();
  timesheet!: Timesheet;
  employees!: Employee[];
  employeeId!: string;
  projects!: Project[];
  EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
  approvedProjects!: Project[];

  constructor(private backendService: BackendService,
              public authService: AuthService,
              private cooModalService: ModalService) {
  }

  async ngOnInit() {
    this.weekDate = new Date();
    this.timesheet = {} as Timesheet;
    this.employeeId = this.authService.loggedOnUser.id;
    this.employees = await this.backendService.get('employee');
    this.projects = await this.backendService.get('project');
    await this.search();
  }

  private async search() {
    await this.backendService.postSilently<Timesheet>(`timesheet/searchAndCreateIfNotFound`, {
      date: this.weekDate.getTime(),
      employeeId: this.employeeId
    }).then((timesheet: Timesheet) => {
      this.timesheet = timesheet;
      this.addEmptyTasks();
    });
  }

  private addEmptyTasks() {
    if(this.projects && this.timesheet) {
      this.approvedProjects = this.projects.filter(p => p.status === 'Approved'
        || this.timesheet.tasks.findIndex(t => t.projectId === p.id) > 0);
      let projectsWithoutTask = this.approvedProjects.filter(p => this.timesheet.tasks.findIndex(t => t.projectId === p.id) < 0);
      projectsWithoutTask.forEach(p => {
        this.add(p.id);
      });
      this.timesheet.tasks = this.timesheet.tasks.sort((ta, tb) => {
        if (ta && tb) {
          let project1 = this.projects.find(p => p.id === ta.projectId);
          let project2 = this.projects.find(p => p.id === tb.projectId);
          if (project1 && project2) {
            return project1.code.localeCompare(project2.code)
          }
        }
        return 0;
      });
    }
  }

  add(projectId: string = '') {
    if (!this.timesheet.tasks) {
      this.timesheet.tasks = [] as Task[];
    }
    let task = {projectId: projectId, durations: [] as TaskDuration[], totalDuration: {hours: 0, minutes: 0}} as Task;
    this.timesheet.dailyTotals.forEach((dayTotal: TaskDuration) => {
      let duration = {
        taskDate: dayTotal.taskDate,
        duration: {hours: 0, minutes: 0},
        durationStr: '00:00'
      } as TaskDuration;
      task.durations.push(duration);
    });
    this.timesheet.tasks.push(task);

  }

  async onDateChange(value: Date) {
    if (value) {
      this.weekDate = value;
      await this.search();
    }
  }

  async saveDuration(task: Task, duration: TaskDuration) {
    duration.duration = this.parseTime(duration.durationStr);
    let result;
    if (task.id) {
      duration.taskId = task.id;
      if (duration.id && duration.id !== this.EMPTY_GUID) {
        result = this.backendService.putSilently('timesheet/duration', duration);
      } else {
        result = this.backendService.postSilently('timesheet/duration', duration);
      }
    } else {
      task.timesheetId = this.timesheet.id;
      result = this.backendService.postSilently('timesheet/task', task);
    }
    result.then((timesheet: any) => {
      this.timesheet = timesheet;
      this.addEmptyTasks();
    })
  }

  calcTotals(task: Task, duration: TaskDuration) {
    const day = this.timesheet.dailyTotals.find(d => d.taskDate == duration.taskDate);
    duration.duration = this.parseTime(duration.durationStr);
    if (day) {
      day.duration.hours += duration.duration.hours;
      day.duration.minutes += duration.duration.minutes;
    }
    task.totalDuration.hours += duration.duration.hours;
    task.totalDuration.minutes += duration.duration.minutes;
  }

  private parseTime(duration: string) {
    const hourMinutes = duration.split(':');
    let hours = Number(hourMinutes[0]);
    let minutes = Number(hourMinutes[1]);
    return {hours: hours, minutes: minutes};
  }

  duration(taskDate: number, durations: TaskDuration[]) {
    let index = durations.findIndex(d => d.taskDate === taskDate);
    return durations[index];
  }

  async download() {
    let employee = this.employees.find(e => e.id = this.employeeId);
    if (employee) {
      let filename = `Timesheet-${employee.name}-${this.weekDate.getTime()}.pdf`;
      await this.backendService.downloadAndSave('timesheet/download/' + this.timesheet.id, filename);
    }
  }

  async onEmployeeChange(employeeId: string) {
    this.employeeId = employeeId;
    await this.search();
  }

  async editDuration(task: Task, taskDuration: TaskDuration) {
    let project = this.projects.find(p => p.id == task.projectId);
    if(project) {
      await this.cooModalService
        .comment('Describe Task', `Describe  ${project.name} task.`, true,
          (comment: string, confirmed: boolean) => {
            if (confirmed) {
              taskDuration.description = comment;
              this.saveDuration(task, taskDuration);
            }
          });
    }
  }

  project(projectId: string) {
    return this.projects.find(p => p.id == projectId);
  }

  inFuture(taskDate: number, durations: TaskDuration[]) {
    return new Date().getTime() < this.duration(taskDate, durations).taskDate;
  }

  isToday(taskDate: number, durations: TaskDuration[]) {
    if (new Date().toDateString() === new Date(this.duration(taskDate, durations).taskDate).toDateString()) {
      return 'table-success';
    }
    return '';
  }
}
