<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body  bg-light">

  <form #siteForm="ngForm" (ngSubmit)="save()">
    <div class="form-row">
      <div class="form-group col-12">
        <label for="number">Registration Number</label>
        <input name="number" id="number" class="form-control" 
               [(ngModel)]="vehicle.number" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12">
        <label for="make" class="col-form-label">Make</label>
        <input name="make" class="form-control" id="make"
               [(ngModel)]="vehicle.make" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12">
        <label for="model" class="col-form-label">Model</label>
        <input name="model" id="model" class="form-control" [(ngModel)]="vehicle.model" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12">
        <label for="currentMileage" class="col-form-label">Current Mileage</label>
        <input name="currentMileage"  id="currentMileage" class="form-control"
               type="number" [(ngModel)]="vehicle.currentMileage" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12">
        <label for="lastServiceMileage" class="col-form-label">Last Service Mileage</label>
        <input name="lastServiceMileage"  id="lastServiceMileage" class="form-control"
               type="number" [(ngModel)]="vehicle.lastServiceMileage" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12">
        <label for="serviceInterval" class="col-form-label">Service Interval</label>
        <input name="serviceInterval"  id="serviceInterval" class="form-control"
               type="number" [(ngModel)]="vehicle.serviceInterval" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12">
        <label for="lastServiceDatepicker" class="col-form-label">Last Service Date</label>
        <div class="input-group">
          <input class="form-control"
                 [(ngModel)]="vehicleLastServiceDate" bsDatepicker
                 type="text"
                 id="lastServiceDatepicker"
                 name="lastServiceDatepicker"
                 #lastServiceDatepicker="bsDatepicker"
                 [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"/>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12">
        <label for="vehicleDiscExpiryDate" class="col-form-label">License Disc Expiry Date</label>
        <div class="input-group">
          <input class="form-control" id="vehicleDiscExpiryDate"
                 [(ngModel)]="vehicleDiscExpiryDate" bsDatepicker
                 type="text"
                 name="bsDatepicker"
                 #dp="bsDatepicker"
                 [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"/>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!siteForm.valid" >
        Save
      </button>

      <button type="button" (click)="close()" class="btn btn-secondary left mr-1">
        Cancel
      </button>
    </div>
  </form>
</div>