import {Component, Input, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {take} from 'rxjs/operators';
import {AuthService} from '../../auth/auth.service';
import {ModalService} from '../../util/modal.service';
import {PurchaseOrder} from '../purchase-order';
import {PurchaseOrderFormComponent} from '../purchase-order-form/purchase-order-form.component';
import {Supplier} from '../../supplier/supplier';
import {Waybill} from '../../waybill/waybill';
import {currencies} from '../../../environments/currencies';
import {ActivatedRoute} from "@angular/router";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-purchase-order-list',
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.css']
})
export class PurchaseOrderListComponent implements OnInit {

  purchaseOrders!: PurchaseOrder[];
  toDate = new Date();
  fromDate = new Date();
  status = '';
  reason = '';

  constructor(private backendService: BackendService,
              private activatedRoute: ActivatedRoute,
              public authService: AuthService,
              private cooModalService: ModalService,
              private modalService: BsModalService) {
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async data => {
      console.log(data);
      if (data['status']) {
        this.status = data['status'];
      }
      if (data['reason']) {
        this.reason = data['reason'];
      }
      await this.load();
    });
  }

  private async load() {
    const today = new Date();
    const yearAgo = new Date();
    yearAgo.setDate(today.getDate() - 30);
    this.fromDate = yearAgo;
    this.toDate = today;
    await this.search();
  }

  async search() {
    this.purchaseOrders = await this.backendService.postSilently<PurchaseOrder[]>('purchaseOrder/search/',
      {
        startDate: this.fromDate.getTime(),
        endDate: this.toDate.getTime(),
        status: this.status,
        reason: this.reason
      });
  }

  download(purchaseOrder: PurchaseOrder) {
    this.backendService.downloadAndSave('purchaseOrder/download/' + purchaseOrder.id, `Purchaser-Order-${purchaseOrder.number}.pdf`);
  }

  async edit(purchaseOrder: PurchaseOrder) {
    const initialState = {title: 'Edit Purchase Order', purchaseOrder};
    const modalRef = this.modalService.show(PurchaseOrderFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.search();
      });
  }

  async add() {
    const initialState = {
      title: 'Add Purchase Order',
      purchaseOrder: {currencyCode: currencies[0].code, reason: this.reason} as PurchaseOrder
    };
    const modalRef = this.modalService.show(PurchaseOrderFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.search();
      });
  }


  statusClass(status: string) {
    switch (status) {
      case 'New':
        return 'badge badge-info';
      case 'In Progress':
        return 'badge badge-warning';
      case 'Approved':
      case 'Invoice Paid':
        return 'badge badge-success';
      case 'Cancelled':
      case 'Invoice Confirmed':
      case 'Invoiced':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }

  async exportToExcel() {
    let searchParams = {
      startDate: this.fromDate.getTime(),
      endDate: this.toDate.getTime(),
      status: this.status,
      reason: this.reason
    }
    let filename = 'purchaseOrders_' + formatDate(searchParams.startDate, 'dd_MM_yyyy', 'en') + '.csv';
    await this.backendService.downloadFilePostExportWithFileName('purchaseOrder/export', searchParams, filename,
      "excel");
  }

  async moveToInProgress(purchaseOrder: PurchaseOrder) {
    await this.cooModalService
      .comment('Move Purchase Order To In Progress',
        `Confirm that you are moving ${purchaseOrder.number} purchase order to in progress.`, false,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            purchaseOrder.statusComment = comment;
            purchaseOrder.status = 'In Progress';
            this.backendService.postSilently('purchaseOrder/status', purchaseOrder)
              .then((res: any) => {
                  this.load();
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
  }
}
