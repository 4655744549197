import {Component, OnInit} from '@angular/core';
import {Employee} from "../../employee/employee";
import {Project} from "../../project/project";
import {BackendService} from "../../util/backend.service";
import {AuthService} from "../../auth/auth.service";
import {ChartConfiguration, ChartDataset, ChartType, PointElement} from "chart.js";

interface BarChatDataSet {
  data?: Array<number | null | undefined | number[]> | PointElement[];
  label?: string;  
  stack?: string;
  backgroundColor?:string;
  hoverBackgroundColor?:string;
}

interface TimesheetDashboard {
  colors: string[];
  totalTime: string;
  topProject: string;
  topClient: string;
  barChartLabels: string[];
  barChartDataSets: Array<BarChatDataSet | ChartDataset>;
}

@Component({
  selector: 'app-timesheet-dashboard',
  templateUrl: './timesheet-dashboard.component.html',
  styleUrls: ['./timesheet-dashboard.component.css']
})
export class TimesheetDashboardComponent implements OnInit {
  fromDate = new Date();
  toDate = new Date();
  employees!: Employee[];
  employeeId!: string;
  projects!: Project[];
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  dashboardData!: TimesheetDashboard;

  constructor(private backendService: BackendService,
              public authService: AuthService) {
  }

  async ngOnInit() {
    this.fromDate = new Date();
    this.toDate = new Date();
    this.employeeId = this.authService.loggedOnUser.id;
    this.employees = await this.backendService.get('employee');
    this.projects = await this.backendService.get('project');
    await this.search();
  }

  private async search() {
    this.dashboardData = await this.backendService.postSilently('timesheet/dashboard', {
      startDate: this.fromDate.getTime(),
      endDate: this.toDate.getTime(),
      employeeId: this.employeeId
    });
    let lineDataSet: ChartDataset = {
      data: [8],
      label: 'Full Day',
      type: "line", 
      fill: false,
      borderColor:'red',
      hoverBackgroundColor:'red',
      borderDash: [1,3]
    };
    this.dashboardData.barChartLabels.forEach(label =>{
      lineDataSet.data?.push(8);
    });
    this.dashboardData.barChartDataSets.push(lineDataSet);
  }

  async onDateChange(date: Date) {
    await this.search();
  }

  async onEmployeeChange(employeeId: string) {
    this.employeeId = employeeId;
    await this.search();
  }
}
