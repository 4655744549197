<section class="container content-area">
  <div class="row">
    <div class="col-sm-12">
      <button type="button" class="btn btn-secondary mb-2"
              routerLink="/delivery-note">
        Back
      </button>
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-9">
              <h3 class="card-title">Dispatch Stock for {{deliveryNote.type}}</h3>
            </div>
          </div>
        </div>
        <form #deliveryNoteForm="ngForm">
          <div class="card-body" *ngIf="deliveryNote">
            <div class="row">
              <div class="form-group col-3">
                <label for="type">Dispatch Type</label>
                <select class="form-control" placeholder="Dispatch Type" name="type"
                        id="type" [disabled]="deliveryNote.id"
                        [(ngModel)]="deliveryNote.type" required #type="ngModel"
                        aria-label="Dispatch Type" aria-describedby="Dispatch Type">
                  <option value="Call">Call</option>
                  <option value="Delivery">Delivery</option>
                </select>
                <div *ngIf="type.invalid">
                  <div *ngIf="type.errors?.required" class="alert alert-danger">
                    Dispatch Type is required.
                  </div>
                </div>
              </div>
              <div class="form-group col-3">
                <label for="company">Company</label>
                <input type="text" class="form-control" placeholder="Company" name="company"
                       id="company" [disabled]="deliveryNote.id"
                       [(ngModel)]="deliveryNote.company" #company="ngModel"
                       aria-label="Invoice Number" aria-describedby="Invoice Number">
              </div>
              <div class="form-group col-3" *ngIf="deliveryNote.type === 'Delivery'">
                <label for="contactPerson">Contact Person</label>
                <input type="text" class="form-control" placeholder="Contact Person" name="contactPerson"
                       id="contactPerson" [disabled]="deliveryNote.id"
                       [(ngModel)]="deliveryNote.contactPerson" required #contactPerson="ngModel"
                       aria-label="Contact Person" aria-describedby="Contact Person">
                <div *ngIf="contactPerson.invalid">
                  <div *ngIf="contactPerson.errors?.required" class="alert alert-danger">
                    Contact person is required.
                  </div>
                </div>
              </div>
              <div class="form-group col-3"  *ngIf="deliveryNote.type === 'Delivery'" >
                <label for="contactNumber">Contact Number</label>
                <input type="text" class="form-control" placeholder="Contact Number" name="contactNumber"
                       id="contactNumber" [disabled]="deliveryNote.id"
                       [(ngModel)]="deliveryNote.contactNumber" required #contactNumber="ngModel"
                       aria-label="Contact Number" aria-describedby="Contact Person">
                <div *ngIf="contactNumber.invalid">
                  <div *ngIf="contactNumber.errors?.required" class="alert alert-danger">
                    Contact number is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">

              <div class="form-group col-3">
                <label for="deliveryDate" class="col-form-label">Delivery Date</label>
                <div class="input-group">
                  <input class="form-control" [disabled]="deliveryNote.id"
                         [(ngModel)]="deliveryDate" bsDatepicker
                         type="text"
                         id="deliveryDate"
                         name="deliveryDate"
                         #deliveryDateDp="bsDatepicker"
                         [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"/>
                </div>
              </div>
              <div class="form-group col-3">
                <label for="technician">Technician</label>
                <select class="form-control" [disabled]="deliveryNote.id" name="technician" id="technician"
                        #technician="ngModel"
                        [(ngModel)]="this.deliveryNote.technicianId" required>
                  <option *ngFor="let technician of technicians" [value]="technician.id">{{technician.name}}</option>
                </select>
                <div *ngIf="technician.invalid">
                  <div *ngIf="technician.errors?.required" class="alert alert-danger">
                    Technician is required.
                  </div>
                </div>
              </div>
              <div class="form-group col-3" *ngIf="deliveryNote.type === 'Delivery'">
                <label for="orderNumber">Order Number</label>
                <input type="text" class="form-control" placeholder="Order Number" name="orderNumber"
                       id="orderNumber" [disabled]="deliveryNote.id"
                       [(ngModel)]="deliveryNote.orderNumber" required #orderNumber="ngModel"
                       aria-label="Order Number" aria-describedby="Order Number">
                <div *ngIf="orderNumber.invalid">
                  <div *ngIf="orderNumber.errors?.required" class="alert alert-danger">
                    Order number is required.
                  </div>
                </div>
              </div>
              <div class="form-group col-3" *ngIf="deliveryNote.type === 'Delivery'">
                <label for="invoiceNumber">Invoice Number</label>
                <input type="text" class="form-control" placeholder="Invoice Number" name="invoiceNumber"
                       id="invoiceNumber" 
                       [(ngModel)]="deliveryNote.invoiceNumber" required #invoiceNumber="ngModel"
                       aria-label="Invoice Number" aria-describedby="Invoice Number">
                <div *ngIf="invoiceNumber.invalid">
                  <div *ngIf="invoiceNumber.errors?.required" class="alert alert-danger">
                    Invoice number is required.
                  </div>
                </div>
              </div>
              <div class="form-group col-3" *ngIf="deliveryNote.type === 'Call'">
                <label for="ticketNumber">Call WO Number</label>
                <input type="text" class="form-control" placeholder="Ticket Number" name="ticketNumber"
                       id="ticketNumber" 
                       [(ngModel)]="deliveryNote.callTicketNumber" #ticketNumber="ngModel"
                       aria-label="Call Number" aria-describedby="Ticket Number">
              </div>
              <div class="form-group col-3" *ngIf="deliveryNote.type === 'Call'">
                <label for="status">Status</label>
                <input type="text" class="form-control" name="status"
                       id="status" disabled
                       [(ngModel)]="deliveryNote.status" required #status="ngModel"
                       aria-label="Status" aria-describedby="Status">
              </div>
            </div>
            <div class="row">

              <div class="col" *ngIf="items">
                <h4>Stock to Dispatch</h4>
                <table class="table table-striped table-bordered">
                  <caption>Stock to Dispatch</caption>
                  <thead>
                  <tr>
                    <th>Product Code</th>
                    <th>Product Name</th>
                    <th>Unit Cost Price</th>
                    <th>Quantity To Dispatch</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody *ngIf="items">
                  <tr *ngFor="let stock of items">
                    <td>{{stock.productCode}}</td>
                    <td>{{stock.product}}</td>
                    <td class="text-right">{{stock.unitPrice | currency : 'LSL'  : 'symbol-narrow'}}</td>
                    <td class="text-right">{{stock.quantity | number}}</td>
                    <td>
                      <button type="button" class="btn btn-link" title="Remove" *ngIf="!deliveryNote.id"
                              (click)="remove(stock)">
                        <span class="fa fa-minus" aria-hidden="true"></span>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card-footer" *ngIf="deliveryNote  && !deliveryNote.id">
            <div class="row">
              <div class="col-9">
                <div *ngIf="errorMessage" class="alert alert-danger">
                  Error occured while saving delivery note.
                  <p>{{errorMessage}}</p>
                </div>
              </div>
              <div class="col-3 m-auto">
                <button type="button" class="btn-sm  btn-primary"
                        [disabled]="deliveryNoteForm.invalid || !items || items.length <= 0 "
                        (click)="dispatch()">
                  Dispatch Stock
                </button>
              </div>
            </div>
          </div>

          <div class="card-footer" *ngIf="deliveryNote && !deliveryNote.id">
            <div class="row mb-2">
              <div class="input-group col-6">
                <div class="input-group-prepend">
                  <span class="input-group-text">Product Name</span>
                </div>
                <input type="text" class="form-control" placeholder="Product name" name="product"
                       id="product" minlength="2"
                       [(ngModel)]="searchProduct" #productName="ngModel"
                       aria-label="Product" aria-describedby="Product">
                <div class="input-group-append">
                  <button type="button" class="btn btn-primary" title="Add Item" [disabled]="productName.invalid"
                          (click)="search()">
                    Search
                  </button>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="!stocks">
              <div class="col">
                {{noStockMessage}}
              </div>
            </div>
            <div class="row" *ngIf="stocks">
              <div class="col">
                <h4>Available Stock</h4>
                <table class="table table-striped table-bordered">
                  <caption>Available Stock</caption>
                  <thead>
                  <tr>
                    <th>Room</th>
                    <th>Product Code</th>
                    <th>Product Name</th>
                    <th>Total Cost Price</th>
                    <th>Unit Cost Price</th>
                    <th>Total Quantity Available</th>
                    <th>Quantity To Dispatch</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody *ngIf="stocks">
                  <tr *ngFor="let stock of stocks">
                    <td>{{stock.room}}</td>
                    <td>{{stock.productCode}}</td>
                    <td>{{stock.productName}}</td>
                    <td class="text-right">{{stock.price | currency : 'LSL'  : 'symbol-narrow'}}</td>
                    <td class="text-right">{{stock.unitPrice | currency : 'LSL'  : 'symbol-narrow'}}</td>
                    <td class="text-right">{{stock.quantity | number}}</td>
                    <td class="text-right">
                      <input type="number" class="form-control" placeholder="Quantity" name="quantity"
                             id="quantity" min="0" [max]="stock.quantity"
                             [(ngModel)]="stock.dispatchQuantity" #quantityModel="ngModel"
                             aria-label="Product" aria-describedby="Product">
                    </td>
                    <td>
                      <button type="button" class="btn btn-link" title="Add for dispatch"
                              *ngIf="stock.quantity > 0 && stock.dispatchQuantity > 0 && stock.quantity >= stock.dispatchQuantity"
                              (click)="addForDispatch(stock)">
                        <span class="fa fa-plus" aria-hidden="true"></span>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
