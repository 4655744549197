import {Component, OnInit} from "@angular/core";
import {BackendService} from "../../util/backend.service";
import {ActivatedRoute} from "@angular/router";
import {Stock} from "../stock";
import {StockTransactionItem} from "../stock-transaction-item";
import {AuthService} from "../../auth/auth.service";
import {ModalService} from "../../util/modal.service";
import {BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-stock-view',
  templateUrl: './stock-view.component.html'
})
export class StockViewComponent implements OnInit {
  
  stock!: Stock;
  transactions!: StockTransactionItem[];

  constructor(private backendService: BackendService,
              private activatedRoute: ActivatedRoute,
              public authService: AuthService,
              private cooModalService: ModalService,
              private modalService: BsModalService) {
  }

  async ngOnInit() {
    const stockId = this.activatedRoute.snapshot.params.id;
    this.stock = await this.backendService.get(`stockBalance/${stockId}`);
    await this.loadTransactions();
  }

  private async loadTransactions() {
    this.transactions = await this.backendService.get(`stockBalance/transactions/${this.stock.id}`)
  }
}