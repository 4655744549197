import {Component, OnInit} from '@angular/core';
import {Employee} from '../employee';
import {BackendService} from '../../util/backend.service';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.css']
})
export class EmployeeFormComponent implements OnInit {
  public employee!: Employee;
  employeeBithdate: any;
  public title!: string;
  defaultBirthdate = new Date();
  authorities = ['User Administrator',
    'Technician', 'Fleet Administrator',
    'Tender Administrator', 'Tender Manager',
    'Waybill Administrator', 'Waybill Manager',
    'Purchase Order Administrator', 'Purchase Order Manager',
    'Stock Administrator', 'Stock Manager',
    'Sales Person', 'Sales Manager', 
    'Supplier Administrator', 'Supplier Manager', 'Manager','Accountant',
    'Service Desk Manager', 'Service Desk Agent',
    'Cash Administrator', 'Cash Manager'
  ]

  constructor(private bsModalRef: BsModalRef,
              private backendService: BackendService) {
  }

  ngOnInit(): void {
    this.defaultBirthdate.setFullYear(this.defaultBirthdate.getFullYear() - 20);
    if (this.employee.birthdate) {
      this.employeeBithdate = new Date(this.employee.birthdate);
    } else {
      this.employeeBithdate = this.defaultBirthdate;
    }
  }

  save() {
    this.employee.birthdate = this.employeeBithdate.getTime();
    if (this.employee.id) {
      this.backendService.putSilently('employee', this.employee).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.backendService.postSilently('employee', this.employee).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  close(){
    this.bsModalRef.hide();
  }

  changeAuthority(e: any, authority: string) {
    if (!this.employee.authorities) {
      this.employee.authorities = [];
    }

    if (e.target.checked) {
      this.employee.authorities.push(authority);
    }
    else {
      this.employee.authorities = this.employee.authorities.filter(l => l !== authority);
    }
  }

  isA(authority: string) {
    return this.employee.authorities && this.employee.authorities.find(l => l === authority);
  }
}
