import {Component, Input, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {take} from 'rxjs/operators';
import {Supplier} from '../supplier';
import {SupplierFormComponent} from '../supplier-form/supplier-form.component';
import {AuthService} from '../../auth/auth.service';
import {ModalService} from '../../util/modal.service';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html'
})
export class SupplierListComponent implements OnInit {

  suppliers!: Supplier[];
  constructor(private backendService: BackendService,
              public authService: AuthService,
              private cooModalService: ModalService,
              private modalService: BsModalService) { }

  async ngOnInit() {
    await this.load();
  }

  private async load() {
    this.suppliers = await this.backendService.get<Supplier[]>('supplier/');
  }

  async approve(supplier: Supplier){
    await this.cooModalService
      .comment('Approval Supplier', `Confirm that you are approving ${supplier.name} as a supplier.`, false,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            supplier.statusComment = comment
            supplier.status = 'Approved';
            this.backendService.postSilently('supplier/status', supplier)
              .then((res: any) => {
                this.load();
                },
                (err) => {
                  this.cooModalService.error( err.error);
                });
          }
        });
  }

  async decline(supplier: Supplier){
    await this.cooModalService
      .comment('Decline Supplier', `Confirm that you are declining ${supplier.name} as a supplier and add reason.`, true,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            supplier.statusComment = comment
            supplier.status = 'Declined';
            this.backendService.postSilently('supplier/status', supplier)
              .then((res: any) => {
                  this.load();
                },
                (err) => {
                  this.cooModalService.error( err.error);
                });
          }
        });

  }

  async edit(supplier: Supplier){
    const initialState = { title: 'Edit Supplier', supplier};
    const modalRef = this.modalService.show(SupplierFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load();
      });
  }

  async add(){
    const initialState = { title: 'Add Supplier', supplier: { } as Supplier};
    const modalRef = this.modalService.show(SupplierFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load();
      });
  }

  statusClass(status: string) {
    switch (status) {
      case 'New':
        return 'badge badge-info';
      case 'Awaiting Approval':
        return 'badge badge-warning';
      case 'Approved':
        return 'badge badge-success';
      case 'Declined':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }
}
