<section class="container content-area">
  <div class="row">
    <div class="col-sm-12">
      <button type="button" class="btn btn-lg btn-secondary mb-2"
              routerLink="/waybills">
        Back
      </button>
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-9">
              <h5 class="card-title">Waybill</h5>
            </div>
          </div>
        </div>
        <div class="row card-body">
          <div class="col-md-6 vertical-line-separator">
            <div class="row">
              <div class="col-md-6"><h6>Number</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{waybill.number}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Customer</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{waybill.customer}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Type</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{waybill.type}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Sender</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{waybill.sender}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Sender email</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{waybill.senderEmail}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Sender telephone</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{waybill.senderTelephone}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Sender address</h6></div>
              <div class="col-md-6">
                <p>{{waybill.senderAddress}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Reporter</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{waybill.reporter}}</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6"><h6>Courier</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{waybill.courier}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Pieces</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{waybill.pieces}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Amount</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{waybill.amount | currency: 'M'}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Receiver</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{waybill.receiver}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Receiver email</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{waybill.receiverEmail}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Receiver telephone</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{waybill.receiverTelephone}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Receiver address</h6></div>
              <div class="col-md-6">
                <p>{{waybill.receiverAddress}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Status</h6></div>
              <div class="col-md-6">
                <p [class]="statusClass(waybill.status)">{{waybill.status}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Status Comment</h6></div>
              <div class="col-md-6">
                <p>{{waybill.statusComment}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-md-6">
                <button type="button" class="btn-sm  btn-info"
                        (click)="edit(waybill)">
                  Edit
                </button>
            </div>
            <div class="col-md-6 text-right">
                <div class="btn-group-sm"  role="group" >
                <button type="button" class="btn btn-sm btn-success" title="Deliver"
                        [disabled]="noDocumentsUploaded()" *ngIf="waybill.type !== 'Inbound' && waybill.type !== 'Outbound'"
                        (click)="deliveredToSupplier()">
                  Deliver to supplier
                </button>
                  <button type="button" class="btn btn-sm btn-success" title="Deliver"
                          [disabled]="noDocumentsUploaded()" *ngIf="waybill.type !== 'Inbound'"
                          (click)="delivered()">
                    Deliver  to supplier
                  </button>
                  <button type="button" class="btn btn-sm btn-success" title="Deliver"
                          [disabled]="noDocumentsUploaded()" *ngIf="waybill.type === 'Inbound'
                          || waybill.type === 'Exchange' || waybill.type === 'Repair' "
                          (click)="delivered()">
                    Collect from supplier
                  </button>
                <button type="button" class="btn btn-sm btn-danger" title="Cancel"
                        [disabled]="noDocumentsUploaded()"
                        (click)="cancelled()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <tabset>
            <tab heading="Documents">
              <button type="button" class="btn btn-sm btn-warning mb-1" title="Upload"
                      (click)="uploadWaybill()">
                Upload Waybill
              </button>
              <div *ngIf="waybill.id">
                <app-documents #waybillDocuments  [requestPath]="'waybill/documents/' + waybill.id"></app-documents>
              </div>
            </tab>
            <tab heading="History">
              <div *ngIf="waybill.id">
                <app-audit #waybillAuditLogs  [requestPath]="'waybill/audit/' + waybill.id"></app-audit>
              </div>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</section>
