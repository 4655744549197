import {Component, Input, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {take} from 'rxjs/operators';
import {Waybill} from '../waybill';
import {WaybillFormComponent} from '../waybill-form/waybill-form.component';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-waybill-list',
  templateUrl: './waybill-list.component.html',
  styleUrls: ['./waybill-list.component.css']
})
export class WaybillListComponent implements OnInit {

  @Input() farmId!: string;
  waybills!: Waybill[];
  toDate = new Date();
  fromDate = new Date();
  status = '';

  constructor(private backendService: BackendService,
              private activatedRoute: ActivatedRoute,
              private modalService: BsModalService) {
  }

  async ngOnInit() {
    if(this.activatedRoute.snapshot.params.status) {
      this.status = this.activatedRoute.snapshot.params.status;
    }
    const today = new Date();
    const yearAgo = new Date();
    yearAgo.setDate(today.getDate() - 365);
    this.fromDate = yearAgo;
    this.toDate = today;
    await this.search();
  }

  async search() {
    this.waybills = await this.backendService.postSilently<Waybill[]>('waybill/search/',
      {
        startDate: this.fromDate.getTime(),
        endDate: this.toDate.getTime(),
        status: this.status
      });
  }

  async edit(waybill: Waybill) {
    const initialState = {title: 'Edit Waybill', waybill};
    const modalRef = this.modalService.show(WaybillFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.search();
      });
  }

  async add() {
    const initialState = {title: 'Add Waybill', waybill: {} as Waybill};
    const modalRef = this.modalService.show(WaybillFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.search();
      });
  }

  statusClass(status: string) {
    switch (status) {
      case 'New':
        return 'badge badge-info';
      case 'In Progress':
        return 'badge badge-warning';
      case 'Complete':
        return 'badge badge-success';
      case 'Cancelled':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }
}
