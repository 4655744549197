<section class="container-fluid content-area">

  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fas fa-users"></i> Vehicles
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">

              <button type="button" class="btn btn-primary btn-sm mb-1" (click)="add()">
                Add
              </button>

              <table class="table table-striped table-bordered" style="margin-top: 10px">
                <thead>
                <tr>
                  <th>Number</th>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Licence Disc Expiry Date</th>
                  <th>Current Milage (KM)</th>
                  <th>Current Milage Date</th>
                  <th>Last Service (KM)</th>
                  <th>Last Service Date</th>
                  <th>Service Interval (KM)</th>
                  <th>Next Service (KM)</th>
                  <th>Next Service Date</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody *ngIf="vehicles">
                <tr *ngFor="let vehicle of vehicles">
                  <td>{{vehicle.number}}</td>
                  <td>{{vehicle.make}}</td>
                  <td>{{vehicle.model}}</td>
                  <td>{{vehicle.discExpiryDate | date:'dd MMM yyyy'}}</td>
                  <td>{{vehicle.currentMileage}}</td>
                  <td>{{vehicle.currentMileageDate | date:'dd MMM yyyy'}}</td>
                  <td>{{vehicle.lastServiceMileage}}</td>
                    <td>{{vehicle.lastServiceDate  | date:'dd MMM yyyy'}}</td>
                  <td>{{vehicle.serviceInterval}}</td>
                  <td>{{vehicle.nextServiceMileage}}</td>
                  <td>{{vehicle.nextServiceDate  | date:'dd MMM yyyy'}}</td>
                  <td>
                    <button type="button" class="btn btn-link" title="End User Session" (click)="edit(vehicle)">
                      <span class="fa fa-edit" aria-hidden="true"></span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
