import {Component, Input, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {Vehicle} from '../vehicle';
import {BsModalService} from 'ngx-bootstrap/modal';
import {VehicleFormComponent} from '../vehicle-form/vehicle-form.component';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html'
})
export class VehicleListComponent implements OnInit {

  vehicles!: Vehicle[];
  constructor(private backendService: BackendService,
              private modalService: BsModalService) { }

  async ngOnInit() {
    await this.load();
  }

  private async load() {
    this.vehicles = await this.backendService.get('vehicle/');
  }

  async edit(vehicle: Vehicle){
    const initialState = { title: 'Edit Vehicle', vehicle};
    const modalRef = this.modalService.show(VehicleFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load();
      });
  }

  async add(){
    const initialState = { title: 'Add Vehicle', vehicle: {} as Vehicle};
    const modalRef = this.modalService.show(VehicleFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load();
      });
  }


}
