import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {version} from '../../../environments/version';
import {CredentialsDto} from '../credentialsDto';
import {freeSet} from '@coreui/icons';
import {IconSetService} from '@coreui/icons-angular';
import {faLock, faUser} from '@fortawesome/free-solid-svg-icons';
import {ModalService} from '../../util/modal.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginError = '';
  credentials = {email: '', password: ''} as CredentialsDto;
  loginResult = '';
  iUser = faUser;
  iLock = faLock;

  constructor(private  authService: AuthService,
              private  router: Router,
              private modalService: ModalService,
              public iconSet: IconSetService) {
    // iconSet singleton
    iconSet.icons = {...freeSet};
  }

  ngOnInit() {
    this.credentials = {} as CredentialsDto;
  }

  login() {
    this.authService.login(this.credentials).subscribe((res) => {
      this.router.navigateByUrl('home');
    }, (err) => {
      this.loginError = err.error;
    });
  }

  async forgotPass() {
    if (this.credentials.email) {
      await this.modalService
        .confirm('You are about to reset your password. Are you sure you want to reset your pin?',
          resetPassword => {
            if (resetPassword) {
              this.authService.resetPin(this.credentials).subscribe((res: any) => {
                  this.loginResult = res.payload;
                },
                (err) => {
                  this.loginError = err.error;
                });
            }
          });
    } else {
      await this.modalService.errorMessage('Please enter a valid email to reset your password.');
    }
  }
}
