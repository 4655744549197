<div class="modal-header">
  <h4 class="modal-title text-success" *ngIf="successMessage" translate>Success</h4>
  <h4 class="modal-title text-info" *ngIf="infoMessage" translate>Information</h4>
  <h4 class="modal-title text-danger" *ngIf="errorMessages" translate>Error</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="successMessage" class="alert alert-success" role="alert">
    {{ successMessage }}
  </div>

  <div *ngIf="infoMessage" class="alert alert-info" role="alert">
    {{ infoMessage }}
  </div>

  <div
    *ngFor="let errorMessage of errorMessages"
    class="alert alert-danger"
    role="alert"
  >
    {{ errorMessage }}
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-default"
    (click)="close()"
    ngbAutofocus
  >
    Close
  </button>
</div>