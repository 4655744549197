<section class="container-fluid content-area">

  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fas fa-book"></i> Waybills
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-2 form-group my-auto">
              <label for="fromDate" class="bs-timepicker-label">From:</label>
              <div class="input-group">
                <input type="text" id="fromDate" class="input-group-text"
                       placeholder="From Date" #fromDateDp="bsDatepicker"
                       [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                       [(ngModel)]="fromDate"
                       [maxDate]="toDate"
                       bsDatepicker>

                <button class="btn btn-outline-primary input-group-append"
                        (click)="fromDateDp.toggle()" [attr.aria-expanded]="fromDateDp.isOpen">
                  <span class="fa fa-calendar" aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <div class="col-2 form-group my-auto">
              <label for="toDate" class="bs-timepicker-label">To:</label>
              <div class="input-group">
                <input type="text" id="toDate" class="input-group-text"
                       placeholder="To Date" #toDateDp="bsDatepicker"
                       [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                       [(ngModel)]="toDate"
                       [minDate]="fromDate"
                       bsDatepicker>

                <button class="btn btn-outline-primary input-group-append"
                        (click)="toDateDp.toggle()" [attr.aria-expanded]="toDateDp.isOpen">
                  <span class="fa fa-calendar" aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <div class="col-1 form-group my-auto">
              <label for="status">Status</label>
              <select class="form-control" name="status" id="status" [(ngModel)]="status">
                <option value="">All</option>
                <option value="New">New</option>
                <option value="In Progress">In Progress</option>
                <option value="Completed">Completed</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </div>
            <div class="col-2 my-auto btn-group">
              <button type="button" class="btn btn-primary btn-sm" (click)="search()">
                Search
              </button>
              <button type="button" class="btn btn-primary btn-sm" (click)="add()">
                Add
              </button>
            </div>
          </div>
          <div class="row">

            <table class="table table-striped table-bordered" style="margin-top: 10px">
              <thead>
              <tr>
                <th>Number</th>
                <th>Dispatch</th>
                <th>Delivered</th>
                <th>Customer</th>
                <th>Courier</th>
                <th>Type</th>
                <th>From</th>
                <th>To</th>
                <th>Pieces</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody *ngIf="!waybills || waybills.length <= 0">
                <td colspan="11">No waybills found</td>
              </tbody>
              <tbody *ngIf="waybills && waybills.length > 0">
              <tr *ngFor="let waybill of waybills">
                <td>{{waybill.number}}</td>
                <td>{{waybill.plannedDispatchDate | date:'dd MMM yyyy'}}</td>
                <td>{{waybill.plannedDeliveryDate | date:'dd MMM yyyy'}}</td>
                <td>{{waybill.customer}}</td>
                <td>{{waybill.courier}}</td>
                <td>{{waybill.type}}</td>
                <td>
                  <div class="clearfix">
                    <div class="float-left">
                      <strong>{{waybill.sender}}</strong>
                    </div>
                    <div class="float-right">
                      <small class="text-muted">{{waybill.senderEmail}}</small>
                      |
                      <small class="text-muted">{{waybill.senderTelephone}}</small>
                    </div>
                  </div>
                  <div class="text-sm-left">{{waybill.senderAddress}}</div>
                </td>
                <td>
                  <div class="clearfix">
                    <div class="float-left">
                      <strong>{{waybill.receiver}}</strong>
                    </div>
                    <div class="float-right">
                      <small class="text-muted">{{waybill.receiverEmail}}</small>
                      |
                      <small class="text-muted">{{waybill.receiverTelephone}}</small>
                    </div>
                  </div>
                  <div class="text-sm-left">{{waybill.receiverAddress}}</div>
                </td>
                <td>{{waybill.pieces}}</td>
                <td>
                  <span [class]="statusClass(waybill.status)">{{waybill.status}}</span></td>
                <td>
                  <button type="button" class="btn btn-link" title="Edit" (click)="edit(waybill)">
                    <span class="fa fa-edit" aria-hidden="true"></span>
                  </button>
                  <button type="button" class="btn btn-link" title="View" routerLink="/waybill/view/{{waybill.id}}">
                    <span class="fa fa-eye" aria-hidden="true"></span>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
