import { Component } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './comment-modal.component.html',
})
export class CommentModalComponent {
  public title!: string;
  public promtMessage!: string;
  public comment!: string;
  public commented!: boolean;
  public commentRequired!: boolean;

  constructor(private bsModalRef: BsModalRef) {}

  confirmAndComment() {
    this.commented = true;
    this.close();
  }

  close() {
    this.bsModalRef.hide();
  }
}
