import { Component } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
  public message!: string;
  public confirmed!: boolean;

  constructor(private bsModalRef: BsModalRef) {}

  confirm() {
    this.confirmed = true;
    this.close();
  }

  close() {
    this.bsModalRef.hide();
  }
}
