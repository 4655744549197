import {Component, Input, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {Employee} from '../employee';
import {BsModalService} from 'ngx-bootstrap/modal';
import {EmployeeFormComponent} from '../employee-form/employee-form.component';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {

  @Input() farmId!: string;
  employees!: Employee[];
  constructor(private backendService: BackendService,
              private modalService: BsModalService) { }

  async ngOnInit() {
    await this.load();
  }

  private async load() {
    this.employees = await this.backendService.get('employee/');
  }

  async edit(employee: Employee){
    const initialState = { title: 'Edit User', employee};
    const modalRef = this.modalService.show(EmployeeFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load();
      });
  }

  async add(){
    const initialState = { title: 'Add User', employee: { employerId: this.farmId} as Employee};
    const modalRef = this.modalService.show(EmployeeFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load();
      });
  }


}
