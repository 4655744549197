<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body bg-light">

  <form #orderForm="ngForm" (ngSubmit)="save()" *ngIf="purchaseOrder">
    <div class="form-group row">
      <div class="col-6">
        <label>Reason</label>
      </div>
      <div class="col-6">
        <select class="form-control" name="reason" [(ngModel)]="purchaseOrder.reason" required>
          <option value="Customer Order">Customer Order</option>
          <option value="Inventory">Inventory</option>
          <option value="Fuel">Fuel</option>
        </select>
      </div>
    </div>
    <div class="form-group row" *ngIf="purchaseOrder.reason == 'Fuel'">
      <div class="col-6">
        <label>Driver</label>
      </div>
      <div class="col-6">
        <select class="form-control" name="driver" [(ngModel)]="purchaseOrder.driverId" required>
          <option *ngFor="let driver of drivers" [value]="driver.id">{{driver.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row" *ngIf="purchaseOrder.reason == 'Fuel'">
      <div class="col-6">
        <label>Vehicle</label>
      </div>
      <div class="col-6">

        <select class="form-control" name="vehicleRegistrationNumber"
                [(ngModel)]="purchaseOrder.vehicleRegistrationNumber" required>
          <option *ngFor="let vehicle of vehicles"
                  [value]="vehicle.number">{{vehicle.number}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row" *ngIf="purchaseOrder.reason == 'Fuel'">
      <div class="col-6">
        <label>Department</label>
      </div>
      <div class="col-6">

        <select class="form-control" name="vehicleRegistrationNumber" [(ngModel)]="purchaseOrder.department" required>
          <option *ngFor="let department of departments"
                  [value]="department">{{department}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row" *ngIf="purchaseOrder.reason == 'Fuel'">
      <div class="col-6">
        <label>Fuel Reason</label>
      </div>
      <div class="col-6"><div class="form-check form-check-inline">
        <input class="form-check-input" type="radio"  id="Local" value="Local"
               name="purchaseReason" [(ngModel)]="purchaseOrder.purchaseReason" >
        <label class="form-check-label" for="Local">Local</label>
      </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio"  id="District" value="District"
                 name="purchaseReason" [(ngModel)]="purchaseOrder.purchaseReason" >
          <label class="form-check-label" for="District">District</label>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-6">
        <label>Supplier</label>
      </div>
      <div class="col-6">
        <div class="input-group">
          <select class="form-control" name="supplier" [(ngModel)]="purchaseOrder.supplierId" required>
            <option *ngFor="let supplier of suppliers" [value]="supplier.id">{{supplier.name}}</option>
          </select>
          <div class="input-group-append" *ngIf="authService.hasAuthority('Supplier Administrator')">
            <button type="button" class="btn btn-link" title="Add Supplier"
                    (click)="addSupplier()">
              <span class="fa fa-plus-circle" aria-hidden="true"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row" *ngIf="purchaseOrder.reason !== 'Fuel'">
      <div class="col-sm-6">
        <label>Supplier Quotation Date</label>
      </div>
      <div class=" col-sm-6">
        <div class="input-group">
          <input class="form-control"
                 [(ngModel)]="supplierQuotationDate" bsDatepicker
                 type="text"
                 name="bsSupplierQuotationDatepicker"
                 #dpSupplierQuotationDatepicker="bsDatepicker"
                 [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"/>
        </div>
      </div>
    </div>
    <div class="form-group row" *ngIf="purchaseOrder.reason !== 'Fuel'">
      <div class="col-6">
        <label>Supplier Quotation Number</label>
      </div>
      <div class="col-6">
        <input name="supplierQuotationNumber" [(ngModel)]="purchaseOrder.supplierQuotationNumber" stretch="true">
      </div>
    </div>
    <div *ngIf="purchaseOrder.reason === 'Customer Order'">
      <div class="form-group row">
        <div class="col-6">
          <label>Customer</label>
        </div>
        <div class="col-6">
          <input name="customer" [(ngModel)]="purchaseOrder.customer" stretch="true">
        </div>
      </div>
      <div class="form-group row">
        <div class="col-6">
          <label>Customer Email</label>
        </div>
        <div class="col-6">
          <input name="customerEmail" type="email" [(ngModel)]="purchaseOrder.customerEmail" stretch="true">
        </div>
      </div>
      <div class="form-group row">
        <div class="col-6">
          <label>Customer Telephone</label>
        </div>
        <div class="col-6">
          <input name="customerTelephone" type="tel" [(ngModel)]="purchaseOrder.customerTelephone" stretch="true">
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-6">
        <label>Currency</label>
      </div>
      <div class="col-6">
        <select class="form-control" name="currency" [(ngModel)]="purchaseOrder.currencyCode" required>
          <option *ngFor="let currency of availableCurrencies"
                  [value]="currency.code">{{ currency.symbol + ' - ' + currency.code}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-6">
        <label>Date</label>
      </div>
      <div class=" col-sm-6">
        <div class="input-group">
          <input class="form-control"
                 [(ngModel)]="orderDate" bsDatepicker
                 type="text"
                 name="bsDatepicker"
                 #dp="bsDatepicker"
                 [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"/>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!orderForm.valid">
        Save
      </button>

      <button type="button" (click)="close()" class="btn btn-secondary left mr-1">
        Cancel
      </button>
    </div>
  </form>
</div>