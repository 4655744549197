<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<form #commentForm="ngForm">
  <div class="modal-body">
    <div class="alert alert-info" role="alert">
      {{ promtMessage }}
    </div>
    <div class="form-group col-12">
    <textarea class="form-control" name="comment" [(ngModel)]="comment" rows="2"></textarea>
    </div>
  </div>

  <div class="modal-footer">
    <button [disabled]="!comment && commentRequired"
            class="btn btn-lg btn-primary"
            (click)="confirmAndComment()"
    >
      Yes
    </button>
    <button
      type="button"
      class="btn btn-lg btn-secondary"
      (click)="close()"
      ngbAutofocus
    >
      No
    </button>
  </div>
</form>