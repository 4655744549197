import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {take} from 'rxjs/operators';
import {ProjectFormComponent} from '../project-form/project-form.component';
import {AuthService} from '../../auth/auth.service';
import {ModalService} from '../../util/modal.service';
import {Project} from "../project";

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html'
})
export class ProjectListComponent implements OnInit {

  projects!: Project[];
  constructor(private backendService: BackendService,
              public authService: AuthService,
              private cooModalService: ModalService,
              private modalService: BsModalService) { }

  async ngOnInit() {
    await this.load();
  }

  private async load() {
    this.projects = await this.backendService.get<Project[]>('project/');
  }

  async approve(project: Project){
    await this.cooModalService
      .comment('Approve Type of work', `Confirm that you are approving ${project.name} as a type of work.`, false,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            project.statusComment = comment
            project.status = 'Approved';
            this.backendService.postSilently('project/status', project)
              .then((res: any) => {
                this.load();
                },
                (err) => {
                  this.cooModalService.error( err.error);
                });
          }
        });
  }

  async decline(project: Project){
    await this.cooModalService
      .comment('Decline Type of work', `Confirm that you are declining ${project.name} as a type of work and add reason.`, true,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            project.statusComment = comment
            project.status = 'Declined';
            this.backendService.postSilently('project/status', project)
              .then((res: any) => {
                  this.load();
                },
                (err) => {
                  this.cooModalService.error( err.error);
                });
          }
        });

  }

  async edit(project: Project){
    const initialState = { title: 'Edit Type of work', project};
    const modalRef = this.modalService.show(ProjectFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load();
      });
  }

  async add(){
    const initialState = { title: 'Add Type of work', project: { } as Project};
    const modalRef = this.modalService.show(ProjectFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load();
      });
  }

  statusClass(status: string) {
    switch (status) {
      case 'New':
        return 'badge badge-info';
      case 'Awaiting Approval':
        return 'badge badge-warning';
      case 'Approved':
        return 'badge badge-success';
      case 'Declined':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }
}
