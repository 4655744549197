<section class="container content-area">
  <div class="row">
    <div class="col-sm-12">
      <button type="button" class="btn btn-lg btn-secondary mb-2"
              routerLink="/tenders">
        Back
      </button>
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-9">
              <h5 class="card-title">Tender</h5>
            </div>
          </div>
        </div>
        <div class="row card-body">
          <div class="col-md-6 vertical-line-separator">
            <div class="row">
              <div class="col-md-6"><h6>Customer</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{tender.customer}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Email</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{tender.customerEmail}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Telephone number</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{tender.customerTelephone}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Customer address</h6></div>
              <div class="col-md-6">
                <pre>{{tender.customerAddress}}</pre>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6"><h6>Description</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{tender.description}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Date Requested</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{tender.dateRequested | date: 'dd MMM yyyy'}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Reporter</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{tender.reporter}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Amount</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{tender.amount | currency: 'M'}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Status</h6></div>
              <div class="col-md-6">
                <p [class]="statusClass(tender.status)">{{tender.status}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn-sm  btn-info"
                      (click)="edit(tender)">
                Edit
              </button>
            </div>
            <div class="col-md-6 text-right">
              <div class="btn-group-sm" role="group">
                <button type="button" class="btn btn-sm btn-success" title="Won"
                        (click)="won()">
                  Won
                </button>
                <button type="button" class="btn btn-sm btn-danger" title="Lost"
                        (click)="lost()">
                  Lost
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">

          <tabset>
            <tab heading="Comments">
              <div *ngIf="tender.id">
                <app-comment #tenderComments
                             [entityId]="tender.id"></app-comment>
              </div>
            </tab>
            <tab heading="Documents">
              <button type="button" class="btn btn-sm btn-warning mb-2" title="Upload" *ngIf="!tender.quotationId"
                                              (click)="uploadQuote()">
              Upload Document
            </button>
              <div *ngIf="tender.id">
                <app-documents #tenderDocuments  [requestPath]="'tender/documents/' + tender.id"></app-documents>
              </div>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</section>
