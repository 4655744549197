import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {Room} from '../../room/room';
import {StockTransaction} from '../stock-transaction';
import {PurchaseOrder} from "../../purchase-order/purchase-order";
import {Stock} from "../stock";
import {PurchaseOrderItem} from "../../purchase-order/purchase-order-item";
import {StockTransactionItem} from "../stock-transaction-item";
import {FormBuilder} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-stock-receive',
  templateUrl: './stock-receive.component.html'
})
export class StockReceiveComponent implements OnInit {
  purchaseOrder = {} as PurchaseOrder;
  stockTransaction = {} as StockTransaction;
  rooms!: Room[];
  errorMessage!: string;

  constructor(private backendService: BackendService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  async ngOnInit() {
    const purchaseOrderId = this.activatedRoute.snapshot.params.id;
    if (purchaseOrderId) {
      this.purchaseOrder = await this.backendService.get(`purchaseOrder/${purchaseOrderId}`);
      this.buildStockTransaction();
    }
    this.rooms = await this.backendService.get('room');
  }

  async findPO() {
    this.purchaseOrder = await this.backendService.get(`purchaseOrder/Find/${this.purchaseOrder.number}`);
    this.buildStockTransaction();

  }

  private buildStockTransaction() {
    this.stockTransaction = {
      type: 'Incoming',
      purchaseOrderNumber: this.purchaseOrder.number,
      purchaseOrderId: this.purchaseOrder.id,
      currencyCode: this.purchaseOrder.currencyCode,
      items: this.toStockTransactionItems(this.purchaseOrder.items)
    } as StockTransaction;
  }

  private toStockTransactionItems(items: PurchaseOrderItem[]) {
    let stockItems: StockTransactionItem[] = [];
    items.forEach(item => {
      let stockItem = {
        productCode: item.code,
        product: item.description,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        purchaseOrderItemId: item.id
      } as StockTransactionItem;
      stockItems.push(stockItem);
    });
    return stockItems;
  }

  add() {
  }

  save() {
    this.backendService.post('stockTransaction', this.stockTransaction) .then(successResult => {
      this.router.navigate(['stock']);
    }, error => {
      this.errorMessage = error;
    });
  }

  statusClass(status: string) {
    switch (status) {
      case 'New':
        return 'badge badge-info';
      case 'In Progress':
        return 'badge badge-warning';
      case 'Approved':
      case 'Invoice Paid':
        return 'badge badge-success';
      case 'Cancelled':
      case 'Invoice Confirmed':
      case 'Invoiced':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }
}
