import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {take} from 'rxjs/operators';
import {DeliveryNote} from '../delivery-note';
import {PurchaseOrder} from "../../purchase-order/purchase-order";

@Component({
  selector: 'app-delivery-note-list',
  templateUrl: './delivery-note-list.component.html'
})
export class DeliveryNoteListComponent implements OnInit {

  deliveryNotes!: DeliveryNote[];
  toDate = new Date();
  fromDate = new Date();
  status = '';
  constructor(private backendService: BackendService,
              private modalService: BsModalService) { }

  async ngOnInit() {
    const today = new Date();
    const yearAgo = new Date();
    yearAgo.setDate(today.getDate() - 365);
    this.fromDate = yearAgo;
    this.toDate = today;
    await this.load();
  }

  async load() {
    this.deliveryNotes = await this.backendService.postSilently<DeliveryNote[]>('deliveryNote/search/',
      {
        startDate: this.fromDate.getTime(),
        endDate: this.toDate.getTime(),
        status: this.status
      });
  }

  statusClass(status: string) {
    switch (status) {
      case 'Accepted':
        return 'badge badge-warning';
      case 'Delivered':
        return 'badge badge-success';
      case 'Dispatched':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }

  async generate(id: string) {
    await this.backendService.get(`deliveryNote/generate/${id}`);
    await this.load();
  }

  async download(deliveryNote: DeliveryNote) {
    await this.backendService.downloadAndSave(`document/${deliveryNote.reportId}`, 
      `Delivery_Note_${deliveryNote.number}.pdf` );
  }
}
