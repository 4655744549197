<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body bg-light">

  <form #siteForm="ngForm" (ngSubmit)="save()">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Number</label>
      </div>
      <div class="form-group col-sm-6">
        <input name="number" [(ngModel)]="waybill.number" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Courier</label>
      </div>
      <div class="form-group col-sm-8">
        <input name="courier" [(ngModel)]="waybill.courier" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Customer</label>
      </div>
      <div class="form-group col-sm-6">
        <input name="customer" [(ngModel)]="waybill.customer">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Type</label>
      </div>
      <div class="form-group col-sm-6">
        <input name="type" type="radio" [(ngModel)]="waybill.type" value="Inbound" required>Inbound
        <input name="type" type="radio" [(ngModel)]="waybill.type" value="Outbound" required>Outbound
        <input name="type" type="radio" [(ngModel)]="waybill.type" value="Return" required>Return
        <input name="type" type="radio" [(ngModel)]="waybill.type" value="Repair" required>Repair
        <input name="type" type="radio" [(ngModel)]="waybill.type" value="Exchange" required>Exchange
      </div>
    </div>
    <div *ngIf="waybill.type === 'Inbound'">
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Sender</label>
        </div>
        <div class="form-group col-sm-6">
          <input name="sender" [(ngModel)]="waybill.sender">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Sender Telephone</label>
        </div>
        <div class="form-group col-sm-6">
          <input name="senderTelephone" [(ngModel)]="waybill.senderTelephone" type="tel">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Sender Address</label>
        </div>
        <div class="form-group col-sm-8">
          <input name="senderAddress" [(ngModel)]="waybill.senderAddress">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Sender Email</label>
        </div>
        <div class="form-group col-sm-6">
          <input name="senderEmail" type="email" [(ngModel)]="waybill.senderEmail">
        </div>
      </div>
    </div>
    <div *ngIf="waybill.type !== 'Inbound'">
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Receiver</label>
        </div>
        <div class="form-group col-sm-6">
          <input name="receiver" [(ngModel)]="waybill.receiver">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Receiver Telephone</label>
        </div>
        <div class="form-group col-sm-6">
          <input name="receiverTelephone" [(ngModel)]="waybill.receiverTelephone" type="tel">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Receiver Address</label>
        </div>
        <div class="form-group col-sm-8">
          <input name="receiverAddress" [(ngModel)]="waybill.receiverAddress">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Receiver Email</label>
        </div>
        <div class="form-group col-sm-6">
          <input name="receiverEmail" type="email" [(ngModel)]="waybill.receiverEmail">
        </div>
      </div>
      <div class="form-row" *ngIf="waybill.type === 'Exchange' || waybill.type === 'Repair'">
        <div class="form-group col-sm-4">
          <label>Date to collect from supplier</label>
        </div>
        <div class="form-group col-sm-6">
          <div class="input-group">
            <input class="form-control"
                   [(ngModel)]="supplierCollectionDate" bsDatepicker
                   type="text"
                   name="bsCollectionDatepicker"
                   #dpDelivery="bsDatepicker"
                   [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"/>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Dispatch Date</label>
      </div>
      <div class="form-group col-sm-6">
        <div class="input-group">
          <input class="form-control"
                 [(ngModel)]="plannedDispatchDate" bsDatepicker
                 type="text"
                 name="bsDatepicker"
                 #dp="bsDatepicker"
                 [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"/>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Delivery Date</label>
      </div>
      <div class="form-group col-sm-6">
        <div class="input-group">
          <input class="form-control"
                 [(ngModel)]="plannedDeliveryDate" bsDatepicker
                 type="text"
                 name="bsDeliveryDatepicker"
                 #dpDelivery="bsDatepicker"
                 [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"/>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Pieces</label>
      </div>
      <div class="form-group col-sm-6">
        <input name="pieces" type="number" [(ngModel)]="waybill.pieces" min="1" required>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!siteForm.valid">
        Save
      </button>

      <button type="button" (click)="close()" class="btn btn-secondary left mr-1">
        Cancel
      </button>
    </div>
  </form>
</div>