<section class="container-fluid content-area">

  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fas fa-users"></i> Employee Time
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-3 form-group my-auto">
              <label for="fromDate" class="bs-timepicker-label">Employee:</label>
                <select class="form-control" name="employee" id="employee" 
                        #employee="ngModel" [disabled]="!authService.hasAuthority('Manager')"
                        [(ngModel)]="employeeId" required>
                  <option [value]="EMPTY_GUID">All</option>
                  <option *ngFor="let employee of employees" [value]="employee.id">{{employee.name}}</option>
                </select>
            </div>
            <div class="col-3 form-group my-auto">
              <label for="fromDate" class="bs-timepicker-label">From:</label>
              <div class="input-group">
                <input type="text" id="fromDate" class="input-group-text"
                       placeholder="From Date" #fromDateDp="bsDatepicker"
                       [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                       [(ngModel)]="fromDate"
                       [maxDate]="toDate"
                       bsDatepicker>

                <button class="btn btn-outline-primary input-group-append"
                        (click)="fromDateDp.toggle()" [attr.aria-expanded]="fromDateDp.isOpen">
                  <span class="fa fa-calendar" aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <div class="col-3 form-group my-auto">
              <label for="toDate" class="bs-timepicker-label">To:</label>
              <div class="input-group">
                <input type="text" id="toDate" class="input-group-text"
                       placeholder="To Date" #toDateDp="bsDatepicker"
                       [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                       [(ngModel)]="toDate"
                       [minDate]="fromDate"
                       bsDatepicker>

                <button class="btn btn-outline-primary input-group-append"
                        (click)="toDateDp.toggle()" [attr.aria-expanded]="toDateDp.isOpen">
                  <span class="fa fa-calendar" aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <div class="col-3 my-auto btn-group">

              <button type="button" class="btn btn-primary btn-sm mr-2" (click)="search()">
                Search
              </button>
              <button type="button" class="btn btn-primary btn-sm mr-2" (click)="exportToExcel()">
                Export to Excel
              </button>
            </div>
          </div>
          <div class="row">
            <col class="col-sm-12">
            <table class="table table-striped table-bordered" style="margin-top: 10px">
              <thead>
              <tr>
                <th>Date Posted</th>
                <th>Date</th>
                <th>Employee</th>
                <th>Project</th>
                <th>Description</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Duration</th>
                <th>Start Adderss</th>
                <th>End Address</th>
              </tr>
              </thead>
              <tbody *ngIf="tasks">
              <tr *ngFor="let tran of tasks">
                <td>{{tran.dateTimeInserted| date : 'dd MMM yyyy'}}</td>
                <td>{{tran.taskDate| date : 'dd MMM yyyy'}}</td>
                <td>{{tran.employee}}</td>
                <td>{{tran.project}}</td>
                <td>{{tran.description}}</td>
                <td class="text-center">{{tran.startTime | date :'HH:mm'}}</td>
                <td class="text-center">{{tran.endTime | date :'HH:mm'}}</td>
                <td class="text-center">{{tran.durationStr}}</td>
                <td class="text-center">{{tran.startAddress}}</td>
                <td class="text-center">{{tran.endAddress}}</td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>