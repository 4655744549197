import { Component } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UploadService} from '../util/upload-service';

@Component({
  templateUrl: './document-upload-modal.component.html'
})
export class DocumentUploadModalComponent {

  readonly MAX_BYTES = 10 * 1024 * 1024;
  readonly PDF_EXTENSION = '.pdf';
  readonly PNG_EXTENSION = '.png';
  readonly JPG_EXTENSION = '.jpg';
  readonly XLS_EXTENSION = '.xls';
  readonly XLSX_EXTENSION = '.xlsx';
  readonly DOC_EXTENSION = '.doc';
  readonly DOCX_EXTENSION = '.docx';
  readonly ZIP_EXTENSION = '.zip';

  path!: string;
  ownerId!: string;
  type!: string;
  comment!: string;
  fileToUpload!: File;
  number: string;

  constructor(private uploadService: UploadService,
              private bsModalRef: BsModalRef) {
  }

  onPicked(input: HTMLInputElement) {
    this.fileToUpload = input?.files[0];
  }

  async upload() {
    if (this.fileToUpload.size > 0) {
      await this.uploadDocument(
        this.fileToUpload, this.ownerId, this.path);
      this.bsModalRef.hide();
    }
  }

  async uploadDocument(
    file: File, documentOwnerId: string, uploadPath: string) {
    await this.uploadService.upload(
      uploadPath, file, documentOwnerId, this.type, this.comment, this.number);
  }

  cancel() {
    this.bsModalRef.hide();
  }

  fileIsTooLarge() {
    return this.fileToUpload && this.fileToUpload.size > this.MAX_BYTES;
  }

  fileIsWrongFormat() {
    return this.fileToUpload
      && !(
        this.fileToUpload.name.endsWith(this.PDF_EXTENSION)
        || this.fileToUpload.name.endsWith(this.PNG_EXTENSION)
        || this.fileToUpload.name.endsWith(this.JPG_EXTENSION)
        || this.fileToUpload.name.endsWith(this.XLS_EXTENSION)
        || this.fileToUpload.name.endsWith(this.XLSX_EXTENSION)
        || this.fileToUpload.name.endsWith(this.DOC_EXTENSION)
        || this.fileToUpload.name.endsWith(this.DOCX_EXTENSION)
        || this.fileToUpload.name.endsWith(this.ZIP_EXTENSION)
      );
  }

}
