<section class="container-fluid content-area">

  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fas fa-book"></i> Customer Orders
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-3 form-group my-auto">
              <label for="fromDate" class="bs-timepicker-label">From:</label>
              <div class="input-group">
                <input type="text" id="fromDate" class="input-group-text"
                       placeholder="From Date" #fromDateDp="bsDatepicker"
                       [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                       [(ngModel)]="fromDate"
                       [maxDate]="toDate"
                       bsDatepicker>

                <button class="btn btn-outline-primary input-group-append"
                        (click)="fromDateDp.toggle()" [attr.aria-expanded]="fromDateDp.isOpen">
                  <span class="fa fa-calendar" aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <div class="col-3 form-group my-auto">
              <label for="toDate" class="bs-timepicker-label">To:</label>
              <div class="input-group">
                <input type="text" id="toDate" class="input-group-text"
                       placeholder="To Date" #toDateDp="bsDatepicker"
                       [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                       [(ngModel)]="toDate"
                       [minDate]="fromDate"
                       bsDatepicker>

                <button class="btn btn-outline-primary input-group-append"
                        (click)="toDateDp.toggle()" [attr.aria-expanded]="toDateDp.isOpen">
                  <span class="fa fa-calendar" aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <div class="col-1 form-group my-auto">
              <label for="status">Status</label>
              <select class="form-control" name="status" id="status" [(ngModel)]="status">
                <option value="">All</option>
                <option value="New">New</option>
                <option value="Awaiting Approval">Awaiting Approval</option>
                <option value="Awaiting Accounts Approval">Awaiting Accounts Approval</option>
                <option value="Awaiting Manager Approval">Awaiting Manager Approval</option>
                <option value="Approved">Approved</option>
                <option value="Declined">Declined</option>
                <option value="Invoice">Invoiced</option>
                <option value="Paid">Paid</option>
              </select>
            </div>
            <div class="col-4 my-auto btn-group">

              <button type="button" class="btn btn-primary btn-sm mr-2" (click)="search()">
                Search
              </button>
              <button type="button" class="btn btn-primary btn-sm mr-2" (click)="exportToExcel()">
                Export to Excel
              </button>
              <button type="button" class="btn btn-primary btn-sm" (click)="add()"
                      *ngIf="authService.hasAuthority('Sales Person')">
                Add
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <table class="table table-striped table-bordered" style="margin-top: 10px">
                <thead>
                <tr>
                  <th>PO Number</th>
                  <th>Date PO Issued</th>
                  <th>Date PO Received</th>
                  <th>Customer</th>
                  <th>Amount</th>
                  <th>Invoice</th>
                  <th>Paid</th>
                  <th>Agent</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody *ngIf="orders">
                <tr *ngFor="let order of orders">
                  <td>{{order.poNumber}}</td>
                  <td>{{order.dateIssued | date : 'dd MMM yyyy'}}</td>
                  <td>{{order.dateReceived | date : 'dd MMM yyyy'}}</td>
                  <td>
                    <div class="clearfix">
                      <div class="float-left">
                        <strong>{{order.customer}}</strong>
                      </div>
                      <div class="float-right">
                        <small class="text-muted">{{order.customerContactPerson}}</small>
                      </div>
                    </div>
                    <div class="clearfix">
                      <div class="float-left">
                        <div class="text-sm-left">{{order.customerEmail}}</div>
                      </div>
                      <div class="float-right">
                        <small class="text-sm-end">{{order.customerTelephone}}</small>
                      </div>
                    </div>
                  </td>
                  <td>{{order.amount | currency: order.currencyCode : 'symbol-narrow'}}</td>
                  <td>{{order.invoiceNumber}}</td>
                  <td><input type="checkbox" [checked]="order.paid" disabled/></td>
                  <td>{{order.agent}}</td>
                  <td>
                    <span [class]="statusClass(order.status)">{{order.status}}</span>
                    <p>{{order.statusComment}}</p>
                  </td>
                  <td>
                    <button type="button" class="btn btn-link btn-icon" title="Edit"
                            *ngIf="order.status === 'New' || order.status === 'In Progress' "
                            (click)="edit(order)">
                      <span class="fa fa-edit" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-link btn-icon" title="View"
                            routerLink="/orders/{{order.id}}">
                      <span class="fa fa-eye" aria-hidden="true"></span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
