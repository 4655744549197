import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BackendService} from '../../util/backend.service';
import {PurchaseOrder} from '../purchase-order';
import {take} from 'rxjs/operators';
import {PurchaseOrderFormComponent} from '../purchase-order-form/purchase-order-form.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {DocumentUploadModalComponent} from '../../document/document-upload-modal.component';
import {DocumentsComponent} from '../../document/documents.component';
import {ModalService} from '../../util/modal.service';
import {AuditComponent} from '../../audit/audit.component';
import {PurchaseOrderItem} from '../purchase-order-item';
import {PurchaseOrderItemFormComponent} from '../purchase-order-item-form/purchase-order-item-form.component';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-purchase-order-view',
  templateUrl: './purchase-order-view.component.html'
})
export class PurchaseOrderViewComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
              private modalService: BsModalService,
              private cooModalService: ModalService,
              public authService: AuthService,
              private backendService: BackendService) {
  }

  purchaseOrder = {} as PurchaseOrder;
  @ViewChild('purchaseOrderDocuments') purchaseOrderDocuments!: DocumentsComponent;
  @ViewChild('purchaseOrderAuditLogs') purchaseOrderAuditLogs!: AuditComponent;

  async ngOnInit() {
    const purchaseOrderId = this.activatedRoute.snapshot.params.id;
    await this.load(purchaseOrderId);
  }

  private async load(purchaseOrderId: string) {
    this.purchaseOrder = await this.backendService.get('purchaseOrder/' + purchaseOrderId);
    await this.purchaseOrderAuditLogs.loadAudits();
    await this.purchaseOrderDocuments.loadDocuments();
  }

  async edit(purchaseOrder: PurchaseOrder) {
    const initialState = {title: 'Edit PurchaseOrder', purchaseOrder};
    const modalRef = this.modalService.show(PurchaseOrderFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load(purchaseOrder.id);
        this.purchaseOrderAuditLogs.loadAudits();
      });
  }

  upload(documentType: string) {
    const initialState = {
      path: 'purchaseOrder/upload', ownerId: this.purchaseOrder.id, type: documentType
    };
    const modalRef = this.modalService.show(DocumentUploadModalComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load(this.purchaseOrder.id);
        this.purchaseOrderDocuments.loadDocuments();
        this.purchaseOrderAuditLogs.loadAudits();
      });
  }


  download(purchaseOrder: PurchaseOrder) {
    this.backendService.downloadAndSave('purchaseOrder/download/' + purchaseOrder.id, `Purchaser-Order-${purchaseOrder.number}.pdf`);
  }

  statusClass(status: string) {
    switch (status) {
      case 'New':
        return 'badge badge-info';
      case 'In Progress':
        return 'badge badge-warning';
      case 'Approved':
      case 'Invoice Paid':
        return 'badge badge-success';
      case 'Cancelled':
      case 'Invoice Confirmed':
      case 'Invoiced':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }

  async submitForApproval(purchaseOrder: PurchaseOrder) {
    if (!purchaseOrder.items || this.purchaseOrder.items.length < 1) {
      this.cooModalService.errorMessage('Cannot submit purchase order for approval without items. Please add purchase order items.');
      return;
    }
    await this.cooModalService
      .comment('Submit Purchase Order For Approval',
        `Confirm that you are submitting ${purchaseOrder.number} purchase order for approval.`, false,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            purchaseOrder.statusComment = comment;
            purchaseOrder.status = 'Awaiting Approval';
            this.backendService.postSilently('purchaseOrder/status', purchaseOrder)
              .then((res: any) => {
                  this.load(this.purchaseOrder.id);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
  }

  async approve(purchaseOrder: PurchaseOrder) {
    await this.cooModalService
      .comment('Approval Purchase Order', `Confirm that you are approving ${purchaseOrder.number} purchase order.`, false,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            purchaseOrder.statusComment = comment;
            purchaseOrder.status = 'Approved';
            this.backendService.postSilently('purchaseOrder/status', purchaseOrder)
              .then((res: any) => {
                  this.load(this.purchaseOrder.id);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
  }

  async decline(purchaseOrder: PurchaseOrder) {
    await this.cooModalService
      .comment('Decline Purchase Order', `Confirm that you are declining ${purchaseOrder.number} purchase order and add reason.`, true,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            purchaseOrder.statusComment = comment;
            purchaseOrder.status = 'Declined';
            this.backendService.postSilently('purchaseOrder/status', purchaseOrder)
              .then((res: any) => {
                  this.load(this.purchaseOrder.id);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
  }

  async cancel(purchaseOrder: PurchaseOrder) {
    await this.cooModalService
      .comment('Cancel Purchase Order', `Confirm that you are cancelling ${purchaseOrder.number} purchase order and add reason.`, true,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            purchaseOrder.statusComment = comment;
            purchaseOrder.status = 'Cancelled';
            this.backendService.postSilently('purchaseOrder/status', purchaseOrder)
              .then((res: any) => {
                  this.load(this.purchaseOrder.id);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
  }

  async confirmInvoice(purchaseOrder: PurchaseOrder) {
    await this.cooModalService
      .comment('Confirm Purchase Order Invoice', `Confirm that ${purchaseOrder.number} purchase order invoice is correct.`, false,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            purchaseOrder.statusComment = comment;
            purchaseOrder.status = 'Invoice Confirmed';
            this.backendService.postSilently('purchaseOrder/status', purchaseOrder)
              .then((res: any) => {
                  this.load(this.purchaseOrder.id);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
  }

  async confirmPayment(purchaseOrder: PurchaseOrder) {
    await this.cooModalService
      .comment('Confirm Purchase Order Invoice Payment', `Confirm that ${purchaseOrder.number} purchase order invoice is paid.`, false,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            purchaseOrder.statusComment = comment;
            purchaseOrder.status = 'Invoice Paid';
            this.backendService.postSilently('purchaseOrder/status', purchaseOrder)
              .then((res: any) => {
                  this.load(this.purchaseOrder.id);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });
  }

  async delete(item: PurchaseOrderItem) {
    await this.cooModalService
      .comment('Confirm Item Deletion', `Confirm that you want to delete ${item.description}.`, false,
        (comment: string, confirmed: boolean) => {
          if (confirmed) {
            this.backendService.deleteSilently(`purchaseOrder/item/${item.id}`)
              .then((res: any) => {
                  this.load(this.purchaseOrder.id);
                },
                (err) => {
                  this.cooModalService.error(err.error);
                });
          }
        });

  }

  async editItem(item: PurchaseOrderItem) {
    const initialState = {title: 'Edit Item', purchaseOrderItem: item, purchaseOrder: this.purchaseOrder};
    const modalRef = this.modalService.show(PurchaseOrderItemFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load(item.purchaseOrderId);
      });
  }

  async add() {
    const initialState = {
      title: 'Add Item',
      purchaseOrderItem: {purchaseOrderId: this.purchaseOrder.id} as PurchaseOrderItem,
      purchaseOrder: this.purchaseOrder
    };
    const modalRef = this.modalService.show(PurchaseOrderItemFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load(this.purchaseOrder.id);
      });
  }

}
