import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {BackendService} from '../../util/backend.service';
import {Waybill} from '../waybill';
import {take} from 'rxjs/operators';
import {WaybillFormComponent} from '../waybill-form/waybill-form.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {DocumentUploadModalComponent} from '../../document/document-upload-modal.component';
import { saveAs } from 'file-saver';
import {DocumentsComponent} from '../../document/documents.component';
import {ModalService} from '../../util/modal.service';
import {AuditComponent} from '../../audit/audit.component';

@Component({
  selector: 'app-waybill-view',
  templateUrl: './waybill-view.component.html'
})
export class WaybillViewComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
              private modalService: BsModalService,
              private cooModalService: ModalService,
              private backendService: BackendService) {
  }

  waybill = {} as Waybill;
  @ViewChild('waybillDocuments') waybillDocuments!: DocumentsComponent;
  @ViewChild('waybillAuditLogs') waybillAuditLogs!: AuditComponent;

  async ngOnInit() {
    const waybillId = this.activatedRoute.snapshot.params.id;
    await this.load(waybillId);
  }

  private async load(waybillId: string) {
    this.waybill = await this.backendService.get('waybill/' + waybillId);
  }

  async edit(waybill: Waybill){
    const initialState = { title: 'Edit Waybill', waybill};
    const modalRef = this.modalService.show(WaybillFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load(waybill.id);
        this.waybillAuditLogs.loadAudits();
      });
  }

  uploadWaybill() {
    const initialState = {
      path: 'waybill/upload',    ownerId: this.waybill.id, type: 'Waybill'};
    const modalRef = this.modalService.show(DocumentUploadModalComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load(this.waybill.id);
        this.waybillDocuments.loadDocuments();
        this.waybillAuditLogs.loadAudits();
      });
  }

  statusClass(status: string) {
    switch (status) {
      case 'New':
        return 'badge badge-info';
      case 'In Progress':
        return 'badge badge-warning';
      case 'Delivered':
      case 'Completed':
        return 'badge badge-success';
      case 'Cancelled':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }

  async deliveredToSupplier() {
    await this.cooModalService
      .comment('Delivery Comment', 'Confirm that package(s) delivered and optionally add a comment', false,
        (comment: string, commented: boolean) => {
          if (commented) {
            this.waybill.statusComment = comment
            this.waybill.status = 'At Supplier';
            this.backendService.postSilently('waybill/status', this.waybill)
              .then((res: any) => {
                  this.waybill = res;
                  this.waybillAuditLogs.loadAudits();
                },
                (err) => {
                  this.cooModalService.error( err.error);
                });
          }
        });
  }

  async delivered() {
    await this.cooModalService
      .comment('Delivery Comment', 'Confirm that package(s) delivered and optionally add a comment', false,
        (comment: string, commented: boolean) => {
          if (commented) {
            this.waybill.statusComment = comment
            this.waybill.status = 'Completed';
            this.backendService.postSilently('waybill/status', this.waybill)
              .then((res: any) => {
                  this.waybill = res;
                  this.waybillAuditLogs.loadAudits();
              },
              (err) => {
                this.cooModalService.error( err.error);
              });
          }
        });
  }

  async cancelled() {
    await this.cooModalService
      .comment('Cancellation Comment', 'Confirm that package(s) delivery was cancelled and provide a comment', true,
        (comment: string, commented: boolean) => {
          if (commented) {
            this.waybill.statusComment = comment
            this.waybill.status = 'Cancelled';
            this.backendService.postSilently('waybill/status', this.waybill)
              .then((res: any) => {
                  this.waybill = res;
                  this.waybillAuditLogs.loadAudits();
                },
                (err) => {
                  this.cooModalService.error( err.error);
                });
          }
        });
  }

  noDocumentsUploaded() {
    return !(this.waybillDocuments && this.waybillDocuments.documents && this.waybillDocuments.documents.length > 0);
  }
}
