<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body bg-light">

  <form #roomForm="ngForm" (ngSubmit)="save()">
    <div class="form-group row">
      <div class="col-3">
        <label>Code</label>
      </div>
      <div class="col-6">
        <input class="form-control" name="code"
               [(ngModel)]="room.code"
               stretch="true"
               pattern="[a-zA-Z0-9]*"
               minlength="2"
               maxlength="4"
               required>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-3">
        <label>Name</label>
      </div>
      <div class="col-6">
        <input  class="form-control" name="name" [(ngModel)]="room.name" type="tel" stretch="true" required>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-3">
        <label>Description</label>
      </div>
      <div class="col-9">
        <textarea  class="form-control" name="description" [(ngModel)]="room.description"></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!roomForm.valid" >
        Save
      </button>

      <button type="button" (click)="close()" class="btn btn-secondary left mr-1">
        Cancel
      </button>
    </div>
  </form>
</div>