<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body bg-light">

  <form #siteForm="ngForm" (ngSubmit)="save()">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Customer</label>
      </div>
      <div class="form-group col-sm-6">
        <input name="customer" [(ngModel)]="tender.customer" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Customer Telephone</label>
      </div>
      <div class="form-group col-sm-6">
        <input name="customerAddress" [(ngModel)]="tender.customerTelephone" type="tel" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Customer Address</label>
      </div>
      <div class="form-group col-sm-8">
        <input name="customerAddress" [(ngModel)]="tender.customerAddress" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Customer Email</label>
      </div>
      <div class="form-group col-sm-6">
        <input name="customerEmail" type="email" [(ngModel)]="tender.customerEmail" stretch="true" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Description</label>
      </div>
      <div class="form-group col-sm-8">
        <textarea name="description" [(ngModel)]="tender.description" stretch="true" required></textarea>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Date Requested</label>
      </div>
      <div class="form-group col-sm-6">
        <div class="input-group">
          <input class="form-control"
                 [(ngModel)]="tenderRequestDate" bsDatepicker
                 type="text"
                 name="bsDatepicker"
                 #dp="bsDatepicker"
                 [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"/>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Amount</label>
      </div>
      <div class="form-group col-sm-6">
        <input name="amount" type="number" [(ngModel)]="tender.amount" stretch="true" required>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!siteForm.valid" >
        Save
      </button>

      <button type="button" (click)="close()" class="btn btn-secondary left mr-1">
        Cancel
      </button>
    </div>
  </form>
</div>