<table class="table table-responsive-md table-striped table-bordered m-t-10">
  <thead>
  <tr>
    <th>Number</th>
    <th>Type</th>
    <th>Date uploaded</th>
    <th>Comment</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let document of documents">
    <td>{{document.number}}</td>
    <td>{{document.type}}</td>
    <td>{{document.dateTimeInserted | date:"dd-MM-yyyy HH:mm:ss"}}</td>
    <td>{{document.comment}}</td>
    <td>
      <button type="button" class="btn btn-link" title="Download"
              (click)="download(document)">
        <span class="fas fa-file-download" aria-hidden="true"></span>
      </button>
    </td>
  </tr>
  </tbody>
</table>