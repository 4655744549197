<section class="container content-area">
  <div class="row">
    <div class="col-sm-12">
      <button type="button" class="btn btn-lg btn-secondary mb-2"
              routerLink="/orders">
        Back
      </button>
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-9">
              <h5 class="card-title">Order</h5>
            </div>
          </div>
        </div>
        <div class="row card-body">
          <div class="col-md-6 vertical-line-separator">
            <div class="row">
              <div class="col-md-6"><h6>Number</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{order.poNumber}}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6"><h6>Customer</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{order.customer}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Customer Contact</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{order.customerContactPerson}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Customer Telephone</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{order.customerTelephone}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Customer email</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{order.customerEmail}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Amount</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{order.amount | currency: order.currencyCode : 'symbol-narrow'}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Invoice Number</h6></div>
              <div class="col-md-6">
                <p>{{order.invoiceNumber}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Paid</h6></div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  [checked]="order.paid" disabled/>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6"><h6>Date Issued</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{order.dateIssued | date : 'dd MMM YYYY'}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Date Received</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{order.dateReceived | date : 'dd MMM YYYY'}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Department</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{order.department}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Agent</h6></div>
              <div class="col-md-6">
                <p class="card-text">{{order.agent}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Status</h6></div>
              <div class="col-md-6">
                <span [class]="statusClass(order.status)">{{order.status}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><h6>Status Comment</h6></div>
              <div class="col-md-6">
                <p>{{order.statusComment}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn-sm  btn-info"
                      *ngIf="order.status === 'New' || order.status === 'In Progress' "
                      (click)="edit(order)">
                Edit
              </button>
            </div>
            <div class="col-md-6 text-right">
                <button type="button" class="btn btn-sm btn-success  ml-1" title="Submit for Approval"
                        *ngIf="order.status === 'New' || order.status === 'In Progress' "
                        (click)="submitForApproval(order)">
                  Submit for Approval
                </button>
                <button type="button" class="btn btn-sm btn-success  ml-1" title="Approve"
                        *ngIf="canBeApprovedByManager(order)
                           && authService.hasAuthority('Manager')"
                        (click)="approve(order, 'Approved By Manager')">
                  Manager Approval
                </button>
                <button type="button" class="btn btn-sm btn-success  ml-1" title="Approve"
                        *ngIf="canBeApprovedByAccounts(order)
                           && authService.hasAuthority('Accountant')"
                        (click)="approve(order, 'Approved By Accounts')">
                  Accounts Approval
                </button>
                <button type="button" class="btn btn-sm btn-danger  ml-1" title="Decline"
                        *ngIf="order.status.indexOf('Approved') < 0  && order.status !== 'Complete'
                          && isAccountantOrManager()"
                        (click)="decline(order)">
                  Decline
                </button>
                <button type="button" class="btn btn-sm btn-success" title="Complete"
                        *ngIf="order.status === 'Approved'"
                        (click)="confirmComplete(order)">
                  Complete
                </button>
                <button type="button" class="btn btn-sm btn-success  ml-1" title="Confirm Payment"
                        *ngIf="isAccountantOrManager() && !order.paid"
                        (click)="confirmPayment(order)">
                  Confirm Payment
                </button>
                <button type="button" class="btn btn-sm btn-danger ml-1" title="Cancel"
                        *ngIf="isAccountantOrManager() && order.status !== 'Complete'"
                        (click)="cancel(order)">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <tabset>
            <tab heading="Documents">
              <button type="button" class="btn btn-sm btn-primary mb-1" title="Upload Quotation"
                      (click)="upload('Customer Quotation')">
                Upload Customer Quotation
              </button>

              <button type="button" class="btn btn-sm btn-primary mb-1 ml-2" title="Upload Order"
                      (click)="upload('Customer Purchase Order')">
                Upload Customer Purchase Order
              </button>
              <button type="button" class="btn btn-sm btn-primary mb-1 ml-2" title="Upload Invoice"
                      *ngIf="order.status !== 'Cancelled'"
                      (click)="upload('Invoice')">
                Upload Invoice
              </button>

              <button type="button" class="btn btn-sm btn-primary mb-1 ml-2" title="Upload Supplier Proof of Payment"
                      *ngIf="order.status !== 'Cancelled'"
                      (click)="upload('Supplier Proof of Payment')">
                Upload  Proof of Payment
              </button>
              <div *ngIf="order.id">
                <app-documents #orderDocuments  [requestPath]="'order/documents/' + order.id"></app-documents>
              </div>
            </tab>
            <tab heading="History">
              <div *ngIf="order.id">
                <app-audit #orderAuditLogs  [requestPath]="'order/audit/' + order.id"></app-audit>
              </div>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
</section>
