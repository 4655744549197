import {Component, Input, OnInit} from '@angular/core';
import {BackendService} from '../util/backend.service';
import {AuditLog} from './audit.log';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html'
})
export class AuditComponent implements OnInit {

  @Input() requestPath!: string;
  auditLogs!: AuditLog[];

  constructor(private backendService: BackendService) {
  }

  async ngOnInit() {
    await this.loadAudits();
  }
  
  

  async loadAudits() {
    this.auditLogs = await this.backendService.get(this.requestPath);
  }
}
