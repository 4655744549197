<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body bg-light">

  <form #orderForm="ngForm" (ngSubmit)="save()" *ngIf="order">
    <div class="form-group row">
      <div class="col-sm-6">
        <label>Date Issued</label>
      </div>
      <div class=" col-sm-6">
        <div class="input-group">
          <input class="form-control"
                 [(ngModel)]="dateIssued" bsDatepicker
                 type="text"
                 name="dpDateIssuedDicker"
                 #dpDateIssuedDicker="bsDatepicker"
                 [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"/>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-6">
        <label>Date Received</label>
      </div>
      <div class=" col-sm-6">
        <div class="input-group">
          <input class="form-control"
                 [(ngModel)]="dateReceived" bsDatepicker
                 type="text"
                 name="dpDateReceivedDicker"
                 #dpDateReceivedDicker="bsDatepicker"
                 [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"/>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-6">
        <label>PO Number</label>
      </div>
      <div class="col-6">
        <input name="customerPONumber" [(ngModel)]="order.poNumber" stretch="true">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-6">
        <label>PO Amount</label>
      </div>
      <div class="col-6">
        <input name="customerPOAmount" type="number" [(ngModel)]="order.amount" stretch="true">
      </div>
    </div>
    <div>
      <div class="form-group row">
        <div class="col-6">
          <label>Customer</label>
        </div>
        <div class="col-6">
          <input name="customer" [(ngModel)]="order.customer" stretch="true">
        </div>
      </div>
      <div class="form-group row">
        <div class="col-6">
          <label>Customer Contact Person</label>
        </div>
        <div class="col-6">
          <input name="customerContactPerson" [(ngModel)]="order.customerContactPerson" stretch="true">
        </div>
      </div>
      <div class="form-group row">
        <div class="col-6">
          <label>Customer Email</label>
        </div>
        <div class="col-6">
          <input name="customerEmail" type="email" [(ngModel)]="order.customerEmail" stretch="true">
        </div>
      </div>
      <div class="form-group row">
        <div class="col-6">
          <label>Customer Telephone</label>
        </div>
        <div class="col-6">
          <input name="customerTelephone" type="tel" [(ngModel)]="order.customerTelephone" stretch="true">
        </div>
      </div>
      <div class="form-group row">
        <div class="col-6">
          <label>Department</label>
        </div>
        <div class="col-6">

          <select class="form-control" name="vehicleRegistrationNumber"
                  [(ngModel)]="order.department" required>
            <option *ngFor="let department of departments"
                    [value]="department">{{department}}</option>
          </select>
        </div>
      </div>
      <div class="form-group row" >
        <div class="col-6">
          <label>Sales Agent</label>
        </div>
        <div class="col-6">
          <select class="form-control" name="driver" [(ngModel)]="order.agentId" required>
            <option *ngFor="let employee of employees" [value]="employee.id">{{employee.name}}</option>
          </select>
        </div>
      </div>
    </div>
    
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!orderForm.valid">
        Save
      </button>

      <button type="button" (click)="close()" class="btn btn-secondary left mr-1">
        Cancel
      </button>
    </div>
  </form>
</div>