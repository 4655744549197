<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>
    Document Upload - {{type}}
  </h4>
</div>

<div class="modal-body">
  <div class="alert alert-info pl-0" role="alert">
    <ul class="m-0">
      <li>Maximum file size is 2 MB</li>
      <li>Format must be one of PDF, JPG, PNG, DOC, XLS, XLSB, ZIP</li>
    </ul>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-2">
      <h6>Number</h6>
    </div>
    <div class="form-group col-sm-6">
      <input name="number" [(ngModel)]="number" stretch="true" required>
    </div>
  </div>
  <h6>
    Comment
  </h6>
  <div class="form-group col-12">
    <textarea class="form-control" name="comment" [(ngModel)]="comment" rows="2"></textarea>
  </div>
  <!--<p>{{documentType.name}}</p>-->
  <input type="file" #fileInput (change)="onPicked(fileInput)" required/>
  <p *ngIf="fileIsTooLarge()" class="mb-0" style="color: red">
    Selected file is too large
  </p>
  <p *ngIf="fileIsWrongFormat()" class="mb-0" style="color: red">
    Selected file is not a PDF, JPG, PNG, DOC, XLS, XLSB, ZIP
  </p>
  <p *ngIf="!fileToUpload || fileToUpload.size <= 0" class="mb-0" class="text-danger">
    Please select file to upload.
  </p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-lg btn-primary"
          [disabled]="fileIsTooLarge() || fileIsWrongFormat() || !fileToUpload || fileToUpload.size <= 0"
          (click)="upload()">
    Upload
  </button>
  <button type="button" class="btn btn-lg btn-secondary"
          (click)="cancel()"
          ngbAutofocus>
    Cancel
  </button>
</div>
