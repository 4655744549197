import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {PurchaseOrderItem} from '../purchase-order-item';
import {AuthService} from '../../auth/auth.service';
import {PurchaseOrder} from '../purchase-order';

@Component({
  selector: 'app-purchase-order-item-form',
  templateUrl: './purchase-order-item-form.component.html'
})
export class PurchaseOrderItemFormComponent implements OnInit {
  public purchaseOrderItem!: PurchaseOrderItem;
  public purchaseOrder!: PurchaseOrder;
  public title!: string;

  constructor(private bsModalRef: BsModalRef,
              public authService: AuthService,
              private backendService: BackendService) {
  }

  async ngOnInit() {
  }

  save() {
    if (this.purchaseOrderItem.id) {
      this.backendService.putSilently('purchaseOrder/item', this.purchaseOrderItem).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.backendService.postSilently('purchaseOrder/item', this.purchaseOrderItem).then((res: any) => {
        if (res.id) {
          this.bsModalRef.hide();
        }
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  close(){
    this.bsModalRef.hide();
  }
}
