<section class="container-fluid content-area">

  <div class="row">
    <div class="col-lg-12">
      <div class="card  bg-light">
        <div class="card-header">
          <i class="fas fa-clock"></i> Timesheet Dashboard
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-3 form-group my-auto">
              <div class="input-group">
                <input type="text" id="fromDate" class="input-group-text"
                       placeholder="From Date" #fromDateDp="bsDatepicker"
                       [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                       [(ngModel)]="fromDate"
                       [maxDate]="toDate" (bsValueChange)="onDateChange($event)"
                       bsDatepicker>

                <button class="btn btn-outline-primary input-group-append"
                        (click)="fromDateDp.toggle()" [attr.aria-expanded]="fromDateDp.isOpen">
                  <span class="fa fa-calendar" aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <div class="col-3 form-group my-auto">
              <div class="input-group">
                <input type="text" id="toDate" class="input-group-text"
                       placeholder="To Date" #toDateDp="bsDatepicker"
                       [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showTodayButton: true, todayPosition: 'center' }"
                       [(ngModel)]="toDate"
                       [minDate]="fromDate" (bsValueChange)="onDateChange($event)"
                       bsDatepicker>

                <button class="btn btn-outline-primary input-group-append"
                        (click)="toDateDp.toggle()" [attr.aria-expanded]="toDateDp.isOpen">
                  <span class="fa fa-calendar" aria-hidden="true"></span>
                </button>
              </div>
            </div>

            <div class="form-group col-3 my-auto">
              <select class="form-control" name="employee" id="employee" (ngModelChange)="onEmployeeChange($event)"
                      #employee="ngModel" [disabled]="!authService.hasAuthority('Manager')"
                      [(ngModel)]="employeeId" required>
                <option *ngFor="let employee of employees" [value]="employee.id">{{employee.name}}</option>
              </select>
            </div>
          </div>
          <div class="row card-header py-0 mt-2 border" *ngIf="dashboardData">
            <div class="col-6 my-auto py-3 border-right text-center">
              <div class="text-muted small text-uppercase font-weight-bold">Total time</div>
              <div class="h2 py-3">{{dashboardData.totalTime}}</div>
            </div>
            <div class="col-6 my-auto py-3 text-center">
              <div class="text-muted small text-uppercase font-weight-bold">Top Work Type</div>
              <div class="h2 py-3">{{dashboardData.topProject}}</div>
            </div>
          </div>
          <div class="row card-body border bg-white">
            <canvas baseChart height="100" *ngIf="dashboardData"
                    [data]="dashboardData.barChartDataSets"
                    [labels]="dashboardData.barChartLabels"
                    [options]="barChartOptions"
                    [legend]="barChartLegend"
                    [type]="barChartType">
            </canvas>
          </div>
          <div class="row card-body border bg-white">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>