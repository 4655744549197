import {Component, Input, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {take} from 'rxjs/operators';
import {Stock} from '../stock';
import {AuthService} from '../../auth/auth.service';
import {ModalService} from '../../util/modal.service';
import {StockFormComponent} from '../stock-form/stock-form.component';

@Component({
  selector: 'app-stock-list',
  templateUrl: './stock-list.component.html'
})
export class StockListComponent implements OnInit {

  stocks!: Stock[];
  constructor(private backendService: BackendService,
              public authService: AuthService,
              private cooModalService: ModalService,
              private modalService: BsModalService) { }

  async ngOnInit() {
    await this.load();
  }

  private async load() {
    this.stocks = await this.backendService.get<Stock[]>('stockBalance/');
  }
  async add(){
    const initialState = { title: 'Add Stock', stock: { } as Stock};
    const modalRef = this.modalService.show(StockFormComponent, {initialState});
    this.modalService.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.load();
      });
  }

  statusClass(status: string) {
    switch (status) {
      case 'New':
        return 'badge badge-info';
      case 'Awaiting Approval':
        return 'badge badge-warning';
      case 'Approved':
        return 'badge badge-success';
      case 'Declined':
        return 'badge badge-danger';
      default:
        return 'badge badge-info';
    }
  }
}
